import { Box, Card, Container, Stack, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { getUser, updateUser } from "../api/users";
import { getSpace, updateSpace, follow, checkFollow, getNumFollows } from "../api/spaces";
import { isLoggedIn } from "../helpers/authHelper";

import ErrorAlert from "./ErrorAlert";
import FindUsers from "./FindUsers";
import Footer from "./Footer";
import Loading from "./Loading";
import MobileProfile from "./MobileProfile";
import SpacePostBrowser from "./SpacePostBrowser";
import Space from "./Space";
import SpaceTabs from "./SpaceTabs";
import MainGridLayout from './MainGridLayout';


const SpaceMainBox = ({ space, editing, handleSubmit, handleEditing, handleFollow, validate, tab, setTab, tabs, error }) => {
  return (
    <>
      <MobileProfile
        profile={space}
        editing={editing}
        handleSubmit={handleSubmit}
        handleEditing={handleEditing}
        handleFollow={handleFollow}
        validate={validate}
      />
      <Stack spacing={2}>
        {space ? (
          <>
            <SpaceTabs tab={tab} setTab={setTab} />

            {tabs[tab]}
          </>
        ) : (
          <Loading />
        )}
        {error && <ErrorAlert error={error} />}
      </Stack>
    </>
  );
}

const SpaceSmallBox = ({ space, followingData, editing, handleSubmit, handleEditing, handleFollow, validate }) => {
  return (
    <Stack spacing={2}>
      <Space
        space={space}
        followingData={followingData}
        editing={editing}
        handleSubmit={handleSubmit}
        handleEditing={handleEditing}
        handleFollow={handleFollow}
        validate={validate}
      />
      <FindUsers />
      <Footer />
    </Stack>
  );
}


const SpaceContainer = () => {
  const [loading, setLoading] = useState(true);
  const [space, setSpace] = useState(null);
  const [editing, setEditing] = useState(false);
  const [tab, setTab] = useState("posts");
  const user = isLoggedIn();
  const [error, setError] = useState("");
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [followingData, setFollowingData] = useState(null);
  const [reloadValue, setReloadValue] = useState(0);

  const reload = () =>{
    setReloadValue((current) => (current + 1));
  };

  const fetchSpace = async () => {
    setLoading(true);
    const data = await getSpace(params);
    setLoading(false);
    if (data.error) {
      setError(data.error);
    } else {
      setSpace(data);
      checkFollowing(data);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const content = e.target.content.value;

    await updateSpace(user, { description: content });

    setSpace({ ...space, name: { ...space.name, description: content } });
    setEditing(false);
  };

  const handleEditing = () => {
    setEditing(!editing);
  };

  const checkFollowing = async(data) => {
    // navigate("/messenger", { state: { name: space.name } });
    
    const res_data = await checkFollow(data.space._id, user);
    // console.log(res_data.data);
    setFollowingData(res_data);
    
    
  };

  const handleFollow = async() => {
    // navigate("/messenger", { state: { name: space.name } });
    const data = await follow(space.space._id, user);
    reload();

  };

  useEffect(() => {
    fetchSpace();
  }, [location, reloadValue]);

  // useEffect(() => {
  //   checkFollowing();
  // }, [location]);

  const validate = (content) => {
    let error = "";

    if (content.length > 250) {
      error = "Description cannot be longer than 250 characters";
    }

    return error;
  };

  let tabs;
  if (space) {
    tabs = {
      posts: (
        <SpacePostBrowser
          spaceId={space.space._id}
          creatPost
          contentType="posts"
          key="posts"
          isHome={false}
        />
      ),
      // liked: (
      //   <SpacePostBrowser
      //     spaceId={space._id}
      //     contentType="liked"
      //     key="liked"
      //   />
      // ),
      // comments: <CommentBrowser profileUser={space.name} />,
    };
  }

  return (
    <MainGridLayout
      left={<SpaceMainBox space={space} editing={editing} handleSubmit={handleSubmit} handleEditing={handleEditing} handleFollow={handleFollow} validate={validate} tab={tab} setTab={setTab} tabs={tabs} error={error} />}
      right={<SpaceSmallBox space={space} followingData={followingData} editing={editing} handleSubmit={handleSubmit} handleEditing={handleEditing} handleFollow={handleFollow} validate={validate} />}
    />
  );
};

export default SpaceContainer;
