import Footer from "./Footer";
import Profile from "./Profile";
import Loading from "./Loading";
import FindUsers from "./FindUsers";
import UserTwinlies from "./UserTwinlies";
import ErrorAlert from "./ErrorAlert";
import PostBrowser from "./PostBrowser";
import ProfileTabs from "./ProfileTabs";
import { Box, Stack } from "@mui/material";
import MobileProfile from "./MobileProfile";
import CommentBrowser from "./CommentBrowser";
import MainGridLayout from './MainGridLayout';
import React, { useEffect, useState } from "react";
import { isLoggedIn } from "../helpers/authHelper";
import { getUser, updateUser } from "../api/users";
import { getBotPersonality } from "../api/bot_behavior";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useGlobalContext } from "../context/context";
import { uploadUsageMetric } from "../api/users";

const ProfileContainer = () => {
  const { mode } = useGlobalContext();
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState(null);
  const [editing, setEditing] = useState(false);
  const [tab, setTab] = useState("posts");
  const user = isLoggedIn();
  const [error, setError] = useState("");
  const { userId } = useParams();
  const navigate = useNavigate();
  // const location = useLocation();

  const fetchUser = async () => {
    setLoading(true);
    console.log(userId);
    const data = await getUser(userId);
    setLoading(false);
    if (data.error) {
      setError(data.error);
    } else {
      setProfile(data);
      if (data && data.user){
        const profileid = data.user._id;
        uploadUsageMetric(user.userId, "In profile page, looking at profile ID: " + profileid);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const content = e.target.content.value;

    await updateUser(user, { biography: content });

    setProfile({ ...profile, user: { ...profile.user, biography: content } });
    setEditing(false);
  };

  const handleEditing = () => {
    setEditing(!editing);
  };

  const handleEditBot = async() => {
    const bot_personality = await getBotPersonality(profile.user._id);
    navigate("/create/questions", { state: {edit:true, existingPersonalityDetails: bot_personality, type: bot_personality.qAndA.type} });
  };

  const handleMessage = () => {
    navigate("/messenger", { state: { user: profile.user } });
  };


  useEffect(() => {
    fetchUser();
  }, [userId]);

  const validate = (content) => {
    let error = "";

    if (content.length > 250) {
      error = "Bio cannot be longer than 250 characters";
    }

    return error;
  };

  let tabs;
  if (profile) {
    tabs = {
      posts: (
        <PostBrowser
          profileUser={profile.user}
          contentType="posts"
          key="posts"
        />
      ),
      likes: (
        <PostBrowser
          profileUser={profile.user}
          contentType="liked"
          key="liked"
        />
      ),
      comments: <CommentBrowser profileUser={profile.user} />,
    };
  }

  return (
    <Box component="section" sx={{ pl: 0, pt: 1, pr: 0}}>
      <MainGridLayout
        left={
          <>
            <MobileProfile
              profile={profile}
              editing={editing}
              handleSubmit={handleSubmit}
              handleEditBot={handleEditBot}
              handleEditing={handleEditing}
              handleMessage={handleMessage}
              validate={validate}
            />
            <Stack spacing={2}>
              {profile && profile.user.type == "human" ? (
                <>
                  {tabs[tab]}
                </>

              ): profile && profile.user.isBot == true ? (
                <CommentBrowser profileUser={profile.user} />
              ): (
                <Loading />
              )}
              {error && <ErrorAlert error={error} />}
            </Stack>
          </>
        }
        right={
          <Stack spacing={2}>
            <Profile
              profile={profile}
              editing={editing}
              handleSubmit={handleSubmit}
              handleEditBot={handleEditBot}
              handleEditing={handleEditing}
              handleMessage={handleMessage}
              validate={validate}
            />

            {/* <FindUsers /> disabling this for a16z submission */}
            <UserTwinlies profile={profile} />
            {/* <Footer /> */}
          </Stack>
        }
      />
    </Box>
  );
};

export default ProfileContainer;
