import { BASE_URL } from "../config";

const getUserFollowedSpaces = async (likerId, token, query) => {
  try {
    const res = await fetch(
      BASE_URL +
        "api/posts/liked/" +
        likerId +
        "?" +
        new URLSearchParams(query),
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    return await res.json();
  } catch (err) {
    console.log(err);
  }
};

const getSpaces = async (token, query) => {
  try {
    const res = await fetch(
      BASE_URL + "api/spaces?" + new URLSearchParams(query),
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    return await res.json();
  } catch (err) {
    console.log(err);
  }
};


const getSpace = async (params) => {
  try {
    const res = await fetch(BASE_URL + "api/spaces/" + params.id);
    return res.json();
  } catch (err) {
    console.log(err);
  }
};

const getUserLikes = async (postId, anchor) => {
  try {
    const res = await fetch(
      BASE_URL +
        "api/posts/like/" +
        postId +
        "/users?" +
        new URLSearchParams({
          anchor,
        })
    );

    return await res.json();
  } catch (err) {
    console.log(err);
  }
};

const follow = async (space_id, user) => {
  try {
    const res = await fetch(BASE_URL + "api/spaces/follow/" + space_id, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": user.token,
      },
    });
    return await res.json();
  } catch (err) {
    console.log(err);
  }
};

const getNumFollows = async (space_id, user) => {
  try {
    const res = await fetch(BASE_URL + "api/spaces/follow/num/" + space_id, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": user.token,
      },
    });
    return await res.json();
  } catch (err) {
    console.log(err);
  }
};

const checkFollow = async (space_id, user) => {
  try {
    const res = await fetch(BASE_URL + "api/spaces/follow/check/" + space_id, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": user.token,
      },
    });
    return await res.json();
  } catch (err) {
    console.log(err);
  }
};

const createSpace = async (space, user) => {
  try {
    const res = await fetch(BASE_URL + "api/spaces", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": user.token,
      },
      body: JSON.stringify(space),
    });
    return await res.json();
  } catch (err) {
    console.log(err);
  }
};

const updateSpace = async (postId, user, data) => {
  try {
    const res = await fetch(BASE_URL + "api/posts/" + postId, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": user.token,
      },
      body: JSON.stringify(data),
    });
    return res.json();
  } catch (err) {
    console.log(err);
  }
};

const deleteSpace = async (postId, user) => {
  try {
    const res = await fetch(BASE_URL + "api/posts/" + postId, {
      method: "DELETE",
      headers: {
        "x-access-token": user.token,
      },
    });
    return res.json();
  } catch (err) {
    console.log(err);
  }
};

export {
  getUserFollowedSpaces,
  getSpaces,
  follow,
  getNumFollows,
  checkFollow,
  getSpace,
  createSpace,
  updateSpace,
  deleteSpace,
  getUserLikes,
};
