import { BASE_URL } from "../config";

const signup = async (user) => {
  const res = await fetch(BASE_URL + "api/users/register", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  });

  const data = await res.json();

  if (!res.ok || data.error) {
    throw new Error(data.error || 'An unknown error occurred');
  }

  return data;
};

const inviteFriends = async (email, name) => {
  const res = await fetch(BASE_URL + "api/users/invite", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
      name: name
    }),
  });

  const data = await res.json();

  if (!res.ok || data.error) {
    throw new Error(data.error || 'An unknown error occurred');
  }

  return data;
};

const verifyEmail = async (email, verifyCode) => {
  try {
    const res = await fetch(BASE_URL + "api/users/verify", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({"email": email, "verifyCode": verifyCode}),
    });
    const data = await res.json();
    if (!res.ok || data.error) {
      throw new Error(data.error || 'An unknown error occurred');
    }
    return data
  } catch (err) {
    throw new Error(err);
  }
};

const forgotPassword = async (email) => {
  try {
    const res = await fetch(BASE_URL + "api/users/forgotpassword", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({"email": email}),
    });
    return await res.json();
  } catch (err) {
    console.log(err);
  }
};

const resetPassword = async (email, password, resetCode) => {
  try {
    const res = await fetch(BASE_URL + "api/users/resetpassword", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({"email": email, "password": password, "resetCode": resetCode}),
    });
    return await res.json();
  } catch (err) {
    console.log(err);
  }
};

const addToWaitlist = async (email) => {
  try {
    const res = await fetch(BASE_URL + "api/users/waitlist", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({"email": email}),
    });
    return await res.json();
  } catch (err) {
    console.log(err);
  }
};

const login = async (user) => {
  try {
    const res = await fetch(BASE_URL + "api/users/login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    });
    return await res.json();
  } catch (err) {
    console.log(err);
  }
};

const getUser = async (userId) => {
  try {
    const res = await fetch(BASE_URL + "api/users/" + userId);
    return res.json();
  } catch (err) {
    console.log(err);
  }
};

const getRandomUsers = async (query) => {
  try {
    const res = await fetch(
      BASE_URL + "api/users/random?" + new URLSearchParams(query)
    );
    return res.json();
  } catch (err) {
    console.log(err);
  }
};

const getUsers = async (token, query) => {
  try {
    console.log("query is ");
    console.log(query);
    const res = await fetch(
      BASE_URL + "api/users/users?" + new URLSearchParams(query)
    );
    return await res.json();
  } catch (err) {
    console.log(err);
  }
};

const getTwinlies = async (token, query) => {
  try {
    console.log("query is ");
    console.log(query);
    const res = await fetch(
      BASE_URL + "api/users/twinlies?" + new URLSearchParams(query)
    );
    return await res.json();
  } catch (err) {
    console.log(err);
  }
};

const getUserCreatedBotHumans = async (query) => {
  try {
    const res = await fetch(
      BASE_URL + "api/users/bothumans?" + new URLSearchParams(query)
    );
    return res.json();
  } catch (err) {
    console.log(err);
  }
};

const getAllCreatedTwinlies = async (query) => {
  try {
    const res = await fetch(
      BASE_URL + "api/users/createdtwinlies?" + new URLSearchParams(query)
    );
    return res.json();
  } catch (err) {
    console.log(err);
  }
};


const getRandomBots = async (query) => {
  try {
    const res = await fetch(
      BASE_URL + "api/users/randombots?" + new URLSearchParams(query)
    );
    return res.json();
  } catch (err) {
    console.log(err);
  }
};

const updateUser = async (user, data) => {
  try {
    const res = await fetch(BASE_URL + "api/users/" + user._id, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": user.token,
      },
      body: JSON.stringify(data),
    });
    return res.json();
  } catch (err) {
    console.log(err);
  }
};

const updateBot = async (user, data) => {
  try {
    const res = await fetch(BASE_URL + "api/users/updatebot/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": user.token,
      },
      body: JSON.stringify(data),
    });
    return res.json();
  } catch (err) {
    console.log(err);
  }
};

const uploadProfilePic = async (user, data) => {
  try {
    const res = await fetch(BASE_URL + "api/users/upload", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": user.token,
      },
      body: JSON.stringify(data),
    });
    return res.json();
  } catch (err) {
    console.log(err);
  }
}

const uploadTwinlyPic = async (user, data) => {
  try {
    const res = await fetch(BASE_URL + "api/users/uploadtwinlyfile", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": user.token,
      },
      body: JSON.stringify(data),
    });
    return res.json();
  } catch (err) {
    console.log(err);
  }
}


// Update User Details
const updateUserDetails = async (user, formData) => {
  try {
    const res = await fetch(BASE_URL + "api/users/updatepersonalitydetails", {
      method: "POST",
      headers: {
        "Access-Control-Allow-Headers" : "Content-Type",
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "user": user,
        "formData": formData,
      })
    });

    if (!res.ok) throw new Error("Failed to update user details");

    const updatedPersonalityDetails = await res.json();
    console.log(JSON.stringify(updatedPersonalityDetails, null, 2));
    return updatedPersonalityDetails;

  } catch (err) {
    console.log(err);
    throw new Error(err.message);
  }
};

const uploadUsageMetric = async (userId, message) => {
  try {
    const res = await fetch(BASE_URL + "api/users/usagemetric", {
      method: "POST",
      headers: {
        "Access-Control-Allow-Headers" : "Content-Type",
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "userId": userId,
        "message": message,
      })
    });


    return res.json();

  } catch (err) {
    console.log(err);
    throw new Error(err.message);
  }
};



export { signup, inviteFriends, updateBot, addToWaitlist, login, getUser, getUsers, getTwinlies, getRandomUsers, getRandomBots, getUserCreatedBotHumans, getAllCreatedTwinlies, updateUser, uploadProfilePic, uploadTwinlyPic, updateUserDetails, verifyEmail, uploadUsageMetric, forgotPassword,  resetPassword, };
