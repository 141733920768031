import Notification from "../Notification"
import React, { useState, useEffect } from "react"
import { Stack, Typography, IconButton} from "@mui/material";
import { isLoggedIn } from "../../helpers/authHelper";
import HorizontalStack from "../util/HorizontalStack";
import {getNotifications, setSeen} from "../../api/notifications";
import { useNavigate } from "react-router-dom";
import { uploadUsageMetric } from "../../api/users";
import {MdCancel} from "react-icons/md";



const NotificationsView = ( { onClickedNotification, onClickedClose } ) => {
    const user = isLoggedIn();
    const navigate = useNavigate();
    const dummyNotifications = [
        {"_id":"xyz", "recipient": user._id, "createdAt": Date.now(), "type": "Like", "title": "Vish liked your post", "message": "Vish liked your post 'There is no real meaning to anything''" },
        {"_id":"abc", "recipient": user._id, "createdAt": Date.now(), "type": "Activity", "title": "Trumpbot was recently active", "message": "Trumpbot posted in space 'NBA' & several others." }
    ]
    const [notifications, setNotifications] = useState([])

    const loadMoreNotifications = async() => {
        const response_data = await getNotifications(user);
        const fetched_notifications = response_data.data;
        console.log("fetched_notifications are");
        console.log(fetched_notifications);

        for (let i = 0; i< fetched_notifications.length; i++){
            if (!fetched_notifications[i].seen){
                const notificationId = fetched_notifications[i]._id;
                setSeen(user, notificationId);
            }
        }

        setNotifications(fetched_notifications)

    }

    const notificationClicked = (notification) =>{
        let notification_state = null;
        if (notification && notification.state){
            notification_state = notification.state;
        }
        navigate("/messenger", { state: notification_state })
    };

    useEffect(() => {
        uploadUsageMetric(user.userId, "Notifications bell icon clicked");
        loadMoreNotifications();
    }, []);

    return (
        <Stack
            sx = {{margin: "10px", maxWidth: "400px", bgcolor: 'background.paper'}} 
            alignItems={"start"}
        >
            <HorizontalStack sx={{ width: "100%"}} alignItems={"center"} justifyContent={"space-between"}>
                <Typography variant="h6">Notifications</Typography>
                <IconButton onClick={onClickedClose}>
                    <MdCancel />
                </IconButton>
            </HorizontalStack>
            <Stack>
                {notifications.length === 0 && <Typography variant="body1">No notifications</Typography>}
                {notifications.map((notification, i) => (
                    <Notification
                        key={notification._id}
                        notification={notification}
                        onClick={() => {
                                notificationClicked(notification);
                            }
                        }
                    />
                ))}
            </Stack>
      </Stack>
    )
}

export default NotificationsView;