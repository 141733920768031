import { useTheme } from "@emotion/react";
import {
  Avatar,
  Button,
  Card,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { AiFillEdit } from "react-icons/ai";
import { MdCancel } from "react-icons/md";
import { isLoggedIn } from "../helpers/authHelper";
import ContentUpdateEditor from "./ContentUpdateEditor";
import UserAvatar from "./UserAvatar";
import HorizontalStack from "./util/HorizontalStack";
import { modeCol } from "../functions/colors";
import { useGlobalContext } from "../context/context";
import CircularCrop from "./CircularCrop";
import { GCLOUD_PUBLIC_BUCKET_URL } from "../config";
import { uploadProfilePic } from "../api/users";

const MobileProfile = (props) => {
  const { mode } = useGlobalContext();
  const [user, setUser] = useState(null);
  const [isEditingImage, setEditingImage] = useState(false);
  const currentUser = isLoggedIn();
  const theme = useTheme();
  const iconColor = theme.palette.primary.main;

  const clickedAvatar = () => {
    if (currentUser.userId === user._id) {
      setEditingImage(true);
    }
  }
  const imageEditingCancelled = () => {
    setEditingImage(false);
  };
  
  const imageEditingCompleted = async (imageData) => {
    const imgData = await imageData
    setEditingImage(false);

    let jsonData = { 
      image: imgData, 
      userId: user._id
    };

    try {
      const {profilePicUrl} = await uploadProfilePic(currentUser, jsonData)
      setUser({...user, profilePicUrl})
      // update user data in session 
      currentUser.profilePicUrl = profilePicUrl
      localStorage.setItem("user", JSON.stringify(currentUser));

    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (props.profile) {
      setUser(props.profile.user);
    }
  }, [props.profile]);

  return (
    <Card sx={{ display: { sm: "block", md: "none" }, mb: 2, bgcolor: modeCol(mode).cardColor }}>
      {user ? (
        <Stack spacing={2}>
          <HorizontalStack spacing={2} justifyContent="space-between">
            <HorizontalStack>
              <Box onClick={clickedAvatar}>
                <UserAvatar width={50} height={50} username={user.username} imgUrl={user.profilePicUrl} />
              </Box>
              <Typography color={modeCol(mode).textColor} variant="h6" textOverflow="ellipses">
                {user.username}
              </Typography>
            </HorizontalStack>

            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              <HorizontalStack spacing={3}>
                <Stack alignItems="center">
                  <Typography color={modeCol(mode).textColor}>Likes</Typography>
                  <Typography color={modeCol(mode).textColor}>
                    <b>{props.profile.posts.likeCount}</b>
                  </Typography>
                </Stack>
                <Stack alignItems="center">
                  <Typography color={modeCol(mode).textColor}>Posts</Typography>
                  <Typography color={modeCol(mode).textColor}>
                    <b>{props.profile.posts.count}</b>
                  </Typography>
                </Stack>
              </HorizontalStack>
            </Box>
          </HorizontalStack>
          <Divider />
          <Box>
            {currentUser && user._id === currentUser.userId && (
              <IconButton onClick={props.handleEditing} sx={{ mr: 1 }}>
                {props.editing ? (
                  <MdCancel color={iconColor} />
                ) : (
                  <AiFillEdit color={iconColor} />
                )}
              </IconButton>
            )}
            {currentUser && user.creator === currentUser.userId && (
              <IconButton onClick={props.handleEditBot} sx={{ mr: 1 }}>
                <AiFillEdit color={iconColor} />
              </IconButton>
            )}
            {user.biography ? (
              <>
                <Typography color={modeCol(mode).textColor} textAlign="center" variant="p">
                  <b>Bio: </b>
                  {user.biography}
                </Typography>
              </>
            ) : (
              <Typography color={modeCol(mode).textColor} variant="p">
                <i>
                  No bio yet{" "}
                  {currentUser && user._id === currentUser.userId && (
                    <span>- Tap on the edit icon to add your bio</span>
                  )}
                </i>
              </Typography>
            )}
            {currentUser && user._id !== currentUser.userId && (
              <Box sx={{ mt: 2 }}>
                <Button variant="outlined" onClick={props.handleMessage}>
                  Message
                </Button>
              </Box>
            )}

            { isEditingImage && 
              <Box 
                sx={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  zIndex: 9999,
                }}
              >
                <CircularCrop 
                  defaultImage={user.profilePicUrl ? GCLOUD_PUBLIC_BUCKET_URL + user.profilePicUrl : null}
                  cropSize={{width: 200, height: 200}}
                  onCropCancelled={imageEditingCancelled}
                  onCropComplete={imageEditingCompleted}
                />
              </Box>
            }

            {props.editing && (
              <Box>
                <ContentUpdateEditor
                  handleSubmit={props.handleSubmit}
                  originalContent={user.biography}
                  validate={props.validate}
                />
              </Box>
            )}
          </Box>
        </Stack>
      ) : (
        <Typography color={modeCol(mode).textColor}>Loading...</Typography>
      )}
    </Card>
  );
};

export default MobileProfile;
