import { mainCols as col } from './ColorsStorage';

export const modeCol = (mode) => {

  const grayText = col.grayText;
    const brandColor = col.brandColor;
    const cardTextColor = col.cardContentColor;
    const textColor = mode ? col.lightText : col.darkText;
    const cardColor = mode ? col.darkCard : col.lightCard;
    const switchBall = mode ? col.ballDark : col.ballLight;
    const borders = mode ? col.darkBorder : col.lightBorder;
    const hoverColor = mode ? col.darkHover : col.lightHover;
    const exploreCard = mode ? col.grayText : col.darkerText;
    const switchBgColor = mode ? col.switchDarkBg : col.switchLightBg;
    const topBarBgColor = mode ? col.mainDarkColor : col.mainLightColor;
    const containerColor = mode ? col.darkContainer : col.lightContainer;
    const sidebarBgColor = mode ? col.mainDarkColor : col.mainLightColor;
    const switchInnerShadow = mode ? col.switchInnerShadowDark : col.switchInnerShadowLight;

    return {
      borders,
      grayText,
      textColor,
      cardColor,
      hoverColor,
      brandColor,
      switchBall,
      exploreCard,
      cardTextColor,
      switchBgColor,
      topBarBgColor,
      containerColor,
      sidebarBgColor,
      switchInnerShadow,
    };
};