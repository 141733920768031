import React from 'react';
import { BiSad } from 'react-icons/bi';
import { userEntriesStackOne } from './Styles';
import { modeCol } from '../../functions/colors';
import { Stack, Typography } from '@mui/material';
import { useGlobalContext } from '../../context/context';

const EmptyUserEntry = () => {

    // Global
    const { mode } = useGlobalContext();
    
    return (
      <Stack
        sx={userEntriesStackOne(mode)}
        justifyContent="center"
        alignItems="center"
        spacing={2}
        textAlign="center"
      >
        <BiSad color={modeCol(mode).textColor} size={60} />
        <Typography color={modeCol(mode).textColor} variant="h5">No Conversations</Typography>
        <Typography color={modeCol(mode).grayText} sx={{ maxWidth: "70%" }}>
          Click 'Message' on another user's profile to start a conversation
        </Typography>
      </Stack>
    )
}

export default EmptyUserEntry