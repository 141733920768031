import Loading from '../Loading';
import { cutText } from '../../functions/cutText';
import CheckIcon from '@mui/icons-material/Check';
import React, { useEffect, useState } from 'react';
import { GCLOUD_PUBLIC_BUCKET_URL } from "../../config";
import { useGlobalContext } from '../../context/context';
import { getRandomBots, getTwinlies } from '../../api/users';
import { Box, TextField, List, Avatar, Divider, Typography } from '@mui/material';
import { BoxContainer, InputLabelPropsStyle, InputPropsStyle, InputStyles, ListBox, ListLoadingStyle, ListStyles, TypoStyle } from './Styles';

const SuggestionBots = ({ setShowBots, currentBot, setCurrentBot }) => {

  // Global
  const { mode, user } = useGlobalContext();

  // Local
  const [bots, setBots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchBot, setSearchBot] = useState("");

  // Fetching BOTS
  const fetchBots = async () => {
    try {
      setLoading(true);
      const data = await getRandomBots({ size: 10 });
      setBots(data);
      setLoading(false);
    } catch (error) {
      console.error(`Error: ${error}`);
      setLoading(false);
    }
  };

  // Searching BOTS
  const getRecommendedSearchBots = async(value) => {
    try {
      setLoading(true);
      let query = { search: value };
      const data = await getTwinlies(user && user.token, query);
      setBots(data);
      setLoading(false);
    } catch (error) {
      console.error(`Error: ${error}`);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchBots();
  }, []);

  // AutomatedSearch
  const handleInputChange = (event) => {
    const value = event.target.value
    setSearchBot(value);
    getRecommendedSearchBots(value)
  };

  // ChangeCurrentBot
  const changeCurrentBot = (bot) => {
    setCurrentBot(bot)
  }

  return (
    <>
      <Box onClick={() => setShowBots(false)} sx={{ position: 'absolute', bgcolor: 'transparent', width: { xs: '100%', md: '70%' }, height: '100%', top: 0, right: 0 }}></Box>
      <Box sx={BoxContainer(mode)}>

        <TextField 
          fullWidth
          size="small"
          value={searchBot}
          sx={InputStyles(mode)}
          onChange={handleInputChange}
          placeholder='Search for bots'
          InputProps={InputPropsStyle(mode)}
          InputLabelProps={InputLabelPropsStyle(mode)}
        />

        <List sx={ListStyles(mode)}>

          {loading && (
            <Box sx={ListLoadingStyle()}>
              <Loading />
            </Box>
          )}

          {bots.length === 0 && !loading && (
            <Typography sx={TypoStyle(mode)}>No bots found</Typography>
          )}

          {/* CURRENT BOT */}
          <React.Fragment>
            <Box onClick={() => changeCurrentBot(currentBot)} sx={ListBox()}>
              <Avatar 
                alt={currentBot.username}
                src={currentBot.profilePicUrl ? GCLOUD_PUBLIC_BUCKET_URL + currentBot.profilePicUrl : `https://robohash.org/${currentBot.username}`} 
              />
              <Typography sx={TypoStyle(mode)}>{cutText(currentBot.username, 18)}</Typography>
              <Box><CheckIcon sx={{ color: 'green' }} /></Box>
            </Box>
            <Divider />
          </React.Fragment>

          {(!loading && bots) && bots.map((bot) => (
            <React.Fragment key={bot.id}>
              <Box onClick={() => {changeCurrentBot(bot); setBots(bots.filter((cBot) => cBot.username !== bot.username))}} sx={ListBox()}>
                <Avatar 
                  alt={bot.username}
                  src={bot.profilePicUrl ? GCLOUD_PUBLIC_BUCKET_URL + bot.profilePicUrl : `https://robohash.org/${bot.username}`} 
                />
                <Typography sx={TypoStyle(mode)}>{cutText(bot.username, 18)}</Typography>
              </Box>
              <Divider />
            </React.Fragment>
          ))}

        </List>

      </Box>
    </>
  );
};

export default SuggestionBots;
