
// FUNTION FOR FORMATING TIME
// FORMAT: year-month-day hour:minute (PM || AM)
// Example: "2024-04-12 11:48 PM"

export const formatTime = (createdAt) => {
  const date = new Date(createdAt);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = date.getHours();
  const minutes = date.getMinutes();
  
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const period = hours < 12 ? 'AM' : 'PM';
  const formattedHours = hours % 12 || 12;

  return `${year}-${month}-${day} ${formattedHours}:${formattedMinutes} ${period}`;
};
