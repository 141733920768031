import React, { useState } from "react";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { createPost } from "../api/posts";
import { isLoggedIn } from "../helpers/authHelper";
import HorizontalStack from "./util/HorizontalStack";
import UserAvatar from "./UserAvatar";
import { AiOutlineFileImage, AiOutlineSmile } from "react-icons/ai";
import { modeCol } from "../functions/colors";
import { useGlobalContext } from "../context/context";

const ConversationStarter = () => {
  const { mode } = useGlobalContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ title: "null", content: "" });
  const [serverError, setServerError] = useState("");
  const [errors, setErrors] = useState({});
  const user = isLoggedIn();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    const errors = validate();
    setErrors(errors);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const data = await createPost({title: "sampleTitle", content: formData["content"]}, isLoggedIn());
    setLoading(false);
    if (data && data.error) {
      setServerError(data.error);
    } else {
      navigate("/posts/" + data._id);
      setFormData({...formData, content: ''})
    }
    console.log(formData)
  };

  const validate = () => {
    return {};
  };

  return (
    <Box sx={{ padding: '10px' }}>
      {user && (
        <HorizontalStack>
          <TextField
            sx={{ bgcolor: modeCol(mode).cardColor, borderRadius: '15px' }}
            fullWidth
            label={
              <HorizontalStack>
                <UserAvatar width={35} height={35} username={user.username} imgUrl={user.profilePicUrl} />
                <Typography color={modeCol(mode).textColor}>What's on your mind, {user.username}?</Typography>
              </HorizontalStack>
            }
            InputProps={{ style: { color: modeCol(mode).textColor, borderRadius: '15px' } }}
            multiline
            rows={2}
            name="content"
            margin="normal"
            error={errors.content !== undefined}
            helperText={errors.content}
            onChange={handleChange}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && formData.content.length > 0) {
                handleSubmit();
              }
            }}
          />
          <Stack> 
            {/* TODO: Uncomment the stuff below and work on adding those functionalities*/}
            {/* <HorizontalStack>
              <Button onClick={() => navigate("/posts/create")}><AiOutlineSmile size="25px" /></Button>
              <Button onClick={() => navigate("/posts/create")}><AiOutlineFileImage size="25px" /></Button>
            </HorizontalStack> */}
            <Button onClick={handleSubmit} variant="outlined">
              Submit
            </Button>
          </Stack>
        </HorizontalStack>
      )}
    </Box>
  );
};

export default ConversationStarter;
