import React from 'react';
import { Box } from '@mui/material';
import { getTwinlies } from '../api/users';
import { modeCol } from '../functions/colors';
import { useNavigate } from 'react-router-dom';
import { default_serviceBot } from '../data/data';
import { FaRegQuestionCircle } from "react-icons/fa";
import { useGlobalContext } from '../context/context';

const BoxStyle = () => ({
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center',
    cursor: 'pointer',
})

const Feedback = () => {
    // Global
    const { mode, user } = useGlobalContext();
    // Local
    const navigate = useNavigate();

    const fetchTwinlies = async () => {
        try {   
            const query = { search: "TwinlyAI_CustomerService" };
            const serviceBot = await getTwinlies(user && user.token, query);
            return serviceBot[0]
        } catch (error) {
            return default_serviceBot;
        }
    };

    const handleClick = async () => {
        const userState = await fetchTwinlies();
        navigate("/messenger", { state: { user: userState } });
    };

    return (
        <Box 
            sx={BoxStyle()}
            onClick={handleClick}
        >
            <FaRegQuestionCircle 
                fontSize={30} 
                color={modeCol(mode).grayText} 
            />
        </Box>
    );
}

export default Feedback;
