import React from 'react'
import NewSpaceCard from './NewSpaceCard';
import { Box, Button, Card, Typography } from '@mui/material';
import { useGlobalContext } from '../context/context';
import { modeCol } from '../functions/colors';

import gta from './images/discover-section-images/GTA_Unofficial.jpeg';
import cod from './images/discover-section-images/cod.jpeg';
import dmc from './images/discover-section-images/dmc5.jpeg';
import batman from './images/discover-section-images/batman.jpeg';
import mario from './images/discover-section-images/mario.jpeg';
import tod from './images/discover-section-images/tod.webp';
import ac from './images/discover-section-images/ac.jpeg';
import pop from './images/discover-section-images/pop.webp';


const DiscoverSpaces = () => {

  const { mode } = useGlobalContext();

  const getMoreSpaces = async (e) => {
    console.log("Get more spaces");
  };

  const discoverSpacesContainer = [
    { id: 0, name: 't/GTA_Unofficial' },
    { id: 1, name: 't/CallOfDuty-ModernWarfare' },
    { id: 2, name: 't/DevilMayCry5' },
    { id: 3, name: 't/Batman-ArkhamAsylum' },
    { id: 4, name: 't/Mario' },
    { id: 5, name: 't/TotalOverdose2' },
    { id: 6, name: 't/AssassinsCreed-TheBlackSamurai' },
    { id: 7, name: 't/PrinceOfPersia-TwoThrones' },
  ]

  return (
    <Card sx={{ padding: '25px', bgcolor: modeCol(mode).cardColor }}>
      <Box m={2} pb={2}>
        <Typography color={modeCol(mode).textColor} variant="h5" component="h6" sx={{textAlign:'center'}}>
          Recommended Games For You
        </Typography>
      </Box>
      
      <Box sx={{ display: 'grid', gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)' }, gap: '15px' }}>
      {/* <NewSpaceCard image={gta} key="t/GTA_Unofficial" id="Gt/GTA_Unofficial" name="t/GTA_Unofficial" /> */}
      <NewSpaceCard image={cod} key="t/CallOfDuty-ModernWarfare" id="t/CallOfDuty-ModernWarfare" name="t/CallOfDuty-ModernWarfare" />
      <NewSpaceCard image={dmc} key="something" id="something" name="t/DevilMayCry5" />
      <NewSpaceCard image={batman} key="something" id="something" name="t/Batman-ArkhamAsylum" />
      <NewSpaceCard image={mario} key="something" id="something" name="t/Mario" />
      <NewSpaceCard image={tod} key="something" id="something" name="t/TotalOverdose2" />
      <NewSpaceCard image={ac} key="something" id="something" name="t/AssassinsCreed-TheBlackSamurai" />
      <NewSpaceCard image={pop} key="something" id="something" name="t/PrinceOfPersia-TwoThrones" />
      </Box>

      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
        <Button sx={{alignItems: 'center'}} onClick={getMoreSpaces}>See more</Button>
      </div>

    </Card>
  );
};

export default DiscoverSpaces