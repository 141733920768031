import "react-icons/bi";
import Loading from "../Loading";
import BottomNavbar from "./BottomNavbar";
import { List, Stack } from "@mui/material";
import EmptyUserEntry from "./EmptyUserEntry";
import UserEntriesTop from "./UserEntriesTop";
import React, { useEffect, useState } from "react";
import UserMessengerEntry from "./UserMessengerEntry";
import { useGlobalContext } from "../../context/context";
import { userEntriesContainer, userEntriesList, userEntriesLoading } from "./Styles";

import { getNumUnreadMessages } from "../../api/messages";


const UserMessengerEntries = ({ loading, setLoading, conversations, conservant, setConservant, setShowInviteModal }) => {

  // Global
  const { user, mode } = useGlobalContext();
  const [searchConversation, setSearchConversation] = useState("");
  const [filteredConversations, setFilteredConversations] = useState(conversations);

  const setConvoUnreadMsgsToZero = (conversation) => {
    const newfilteredConversations = filteredConversations.map(convo=> {
      if (convo === conversation) {
        convo.unreadmsgs = 0;
        conversation.unreadmsgs = 0;
        return convo;
      }
      return convo;
    });

    setFilteredConversations(newfilteredConversations);
  };

  // Call Search 
  useEffect(() => {
    handleSearchConversations(searchConversation);
  }, [searchConversation, conversations]);

  // Search Function
  const handleSearchConversations = async(searchValue) => {
    const value = searchValue.trim();
    if (!value) {
      for (let i = 0; i< conversations.length; i++){
        try{
          const responseData = await getNumUnreadMessages(user, conversations[i]);
          conversations[i].unreadmsgs = responseData.numUnreadMessages;

        }
        catch (err){
          console.log("error in userEntriesList:");
          console.log(err);
          conversations[i].unreadmsgs = 0;

        }
      }
      
      setFilteredConversations(conversations);
      return;
    }
    const searchByValue = conversations.filter((conversation) =>
      conversation.recipient && conversation.recipient.username.toLowerCase().includes(searchValue.toLowerCase())
    );

    for (let i = 0; i< searchByValue.length; i++){
      try{
        const responseData = await getNumUnreadMessages(user, searchByValue[i]);
        searchByValue[i].unreadmsgs = responseData.numUnreadMessages;
      }
      catch (err){
        console.log("error in userEntriesList:");
        console.log(err);
        searchByValue[i].unreadmsgs = 0;
      }
    }

    setFilteredConversations(searchByValue); 
  };

  // Target OnChange Value
  const handleFindConversation = (event) => {
    const value = event.target.value;
    setSearchConversation(value);
  }

  return !loading ? (
    <>
      {filteredConversations.length > 0 ? (
        <Stack sx={userEntriesContainer()}>

          <UserEntriesTop 
            searchConversation={searchConversation} 
            handleFindConversation={handleFindConversation} 
            setShowInviteModal={setShowInviteModal}
          />

          {/* Top Scrolling Twinlies */}

          {/* Should be uncommented soon! Do not Delete it! */}
          
          {/* <HorizontalStack sx={userEntriesHorizontalTwo()}>
            <TopTwinlies />
          </HorizontalStack> */}
          
          <List sx={userEntriesList(mode)}>
            
            {filteredConversations.map(function(conversation, i) {
              if (conversation.recipient) {

                return (
                  <UserMessengerEntry
                    conservant={conservant}
                    conversation={conversation}
                    key={conversation.recipient.username}
                    setConservant={setConservant}
                    setConvoUnreadMsgsToZero={setConvoUnreadMsgsToZero}
                  />
                )
              }
            })}

          </List>


        </Stack>
      ) : (
        conversations.length > 0 ? (
          <Stack sx={userEntriesLoading()}>
            <Loading />
          </Stack>
        ) : (
          <EmptyUserEntry />
        )
      )}
      <BottomNavbar />
    </>
  ) : (
    <Stack sx={userEntriesLoading()}>
      <Loading />
    </Stack>
  );
};

export default UserMessengerEntries;
