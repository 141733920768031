import { Box } from "@mui/system";
import React, { useState } from "react";
import MainGridLayout from './MainGridLayout'
import { signup, getUser } from "../api/users";
import { useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import { isLoggedIn } from "../helpers/authHelper";
import { Button, Card, Typography } from "@mui/material";
import { createBotPersonality, updateBotPersonality } from "../api/bot_behavior";
import { modeCol } from "../functions/colors";
import { useGlobalContext } from "../context/context";



const indicesToQuestions = {
  0: ["Where did you go for school?", "What's your major?"],
  1: ["What are your favorite hobbies?", "What are your favorite sports?", "What are your favorite books?", "What are your favorite movies?", "What are your favorite video games?"],
  2: ["What is your biggest fear?", "What are your pet peeves?", "What makes you tick?"],
  3: ["Tell us something about your friends- who they are, how you met them, and what you like/dislike about them"],
  4: ["Freestyle- Please write anything you'd like your Twinly to know about you"]
};


const CreateTab = () =>{
  const { mode } = useGlobalContext();
  const navigate = useNavigate();

  const user = isLoggedIn();

  const [qIndex, setQIndex] = useState(0);
  const [formData, setFormData] = useState({});

  const handlePrev = () => {
    setQIndex(qIndex-1);
  };
  
  const handleNext = () => {
    setQIndex(qIndex+1);
    console.log(formData);
  };

  const handleSubmit = async() => {
    try{
      const botName = user.username + "Twinly";
      const existingUser = await getUser({id: botName});

      if (existingUser.error){
        const botData = {
          username: botName,
          email: botName + "@bot.mytwinly.ai",
          password: botName + "msQ3NL0u",
          biography: user.username + "'s Twinly",
          isBot: true,
        };
        const new_bot_data = await signup(botData);
        console.log(new_bot_data);
        const botPersonality = await createBotPersonality(new_bot_data.userId, formData);
        console.log(botPersonality);
        navigate("/create/success", { state: { user: new_bot_data } });

      }else{
        console.log(existingUser);
        const botPersonality = await updateBotPersonality(existingUser.user._id, formData);
        console.log(botPersonality);
        navigate("/create/success", { state: { user: existingUser.user } });
      }
    } catch(err){
      navigate("/create/error")
    }
  };

  const getValue = (question) => {
    if(question in formData){
      return formData[question];
    }
    else{
      return "";
    }
  };

  return (
    <Card style={{minHeight: '100vh', padding:30, backgroundColor: modeCol(mode).cardColor}}>
      <Typography color={modeCol(mode).textColor} style={{padding:30}} variant="h5" textAlign="center"><b>Create Your Digital Twin!</b></Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding:5 }}>
        {indicesToQuestions[qIndex].map(function(question, i){
          return (
          <TextField
            id={question}
            label={question}
            value={getValue(question)}
            variant="outlined"
            onChange={(e) => setFormData({ ...formData, [e.target.id]: e.target.value })}
            InputLabelProps={{
              style: { color: modeCol(mode).textColor },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: modeCol(mode).borders },
                '& .MuiInputLabel-root': { color: modeCol(mode).textColor },
                color: modeCol(mode).textColor,
              },
            }}
          />
        )})}
      </Box>

      <div style={{ display: "flex", border:2 }}>
        {qIndex > 0 && (
          <Button style={{ marginRight: "auto"}} variant="contained" color="primary" onClick={handlePrev}>Prev</Button>
          )
        }

        {qIndex != 4 && (
          <Button style={{ marginLeft: "auto"}} variant="contained" color="primary" onClick={handleNext}>Next</Button>
          )
        }

        {qIndex == 4 && (
          <Button style={{ marginLeft: "auto"}} variant="contained" color="primary" onClick={handleSubmit}>Submit</Button>
          )
        }
        
        
      </div>

      

    </Card>
  );
};


const TwinlyBasicContainer = () => {
  return (
      <MainGridLayout
        left={<CreateTab />}
      />
  );
};

export default TwinlyBasicContainer;
