import React, { useState } from "react";
import { Stack, Button, Box } from "@mui/material";
import HorizontalStack from "./util/HorizontalStack";
import Cropper from "react-easy-crop";
import FilepickerDropzone from "./FilePicker";

import "./CircularCrop.css"

const CircularCrop = ({ defaultImage, cropSize = { width: 200, height: 200 }, onCropComplete, onCropCancelled }) => {
  const [image, setImage] = useState(defaultImage)
  const [crop, setCrop] = useState({ x: 0, y: 0, aspect: 1 });
  const [cropPixels, setCropPixels] = useState({ x: 0, y: 0, width: 0, height: 0 })
  const [zoom, setZoom] = useState(1)

  const onCropChange = (crop) => {
    setCrop(crop);
  };
  const onFileInput = (files) => {
    console.log(`user dropped files: ${files}`);
    if (files && files.length > 0) {
      setImage(URL.createObjectURL(files[0]))
    }
  }

  const onCropMovementFinished = (cropArea, cropPixels) => {
    setCropPixels(cropPixels)
  }

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image()
      image.addEventListener('load', () => resolve(image))
      image.addEventListener('error', (error) => reject(error))
      image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
      image.src = url
    })

  /**
   * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
   */
  const getCroppedImg = async (
    imageSrc,
    pixelCrop
  ) => {
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    if (!ctx) {
      return null
    }

    const bBoxWidth = image.width
    const bBoxHeight = image.height
    // set canvas size to match the bounding box
    canvas.width = bBoxWidth
    canvas.height = bBoxHeight

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
    // ctx.scale(zoom, zoom)
    ctx.translate(-image.width / 2, -image.height / 2)

    // draw image
    ctx.drawImage(image, 0, 0)

    const croppedCanvas = document.createElement('canvas')
    const croppedCtx = croppedCanvas.getContext('2d')

    if (!croppedCtx) {
      return null
    }

    // Set the size of the cropped canvas
    // croppedCanvas.width = pixelCrop.width
    // croppedCanvas.height = pixelCrop.height

    croppedCanvas.width = cropSize.width
    croppedCanvas.height = cropSize.height

    // Draw the cropped image onto the new canvas
    croppedCtx.drawImage(
      canvas,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      cropSize.width,
      cropSize.height
    )

    // As Base64 string
    return croppedCanvas.toDataURL('image/jpeg');

    // As a blob
    // return new Promise((resolve, reject) => {
    //   croppedCanvas.toBlob((file) => {
    //     resolve(URL.createObjectURL(file))
    //   }, 'image/jpeg')
    // })
  }


  return (
    <Box className="crop-container">
      {/* <Stack> */}

      {image ? (<Cropper
        image={image}
        crop={crop}
        zoom={zoom}
        cropSize={cropSize}
        aspect={1}
        cropShape="round"
        showGrid={false}
        restrictPosition={true}
        onCropChange={onCropChange}
        onCropComplete={onCropMovementFinished}
        onZoomChange={setZoom}
      />) : (
        <FilepickerDropzone
          onDrop={onFileInput}>
        </FilepickerDropzone>
      )
      }

      <HorizontalStack className="crop-controls">
        {/* <Button
                    onClick={() => {
                        setCrop({ x: 0, y: 0, aspect: 1 });
                        setZoom(1)
                    }}
                    >
                      Reset
                    </Button> */}
        <FilepickerDropzone
          onDrop={onFileInput}
          clickOnly={true}
        />

        <Button
          onClick={() => {
            // console.log({...cropPixels})
            onCropComplete(getCroppedImg(image, cropPixels, zoom));
          }}
        >
          Upload
        </Button>
        <Button
          onClick={() => {
            onCropCancelled();
          }}
        >
          Cancel
        </Button>
      </HorizontalStack>
      {/* </Stack> */}
    </Box>

  );
};

export default CircularCrop;