import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@mui/material";


const GoBackToSpaces = () => {
  const navigate = useNavigate()
  return (
    <Button onClick={()=>navigate(-1)}> 
      <Typography> 
        &lt;&lt; Go back to Games
      </Typography>
    </Button>
  );
};

export default GoBackToSpaces;
