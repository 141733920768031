import { CircularProgress, Stack, Typography } from "@mui/material";
import React from "react";
import { modeCol } from "../functions/colors";
import { useGlobalContext } from "../context/context";

const Loading = ({ label }) => {
  const { mode } = useGlobalContext();
  return (
    <Stack alignItems="center">
      <CircularProgress size={50} sx={{ my: 1 }} />
      <Typography color={modeCol(mode).textColor} sx={{ mb: 3 }}>
        {label || "Loading"}
      </Typography>
    </Stack>
  );
};

export default Loading;
