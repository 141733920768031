import { Box } from "@mui/system";
import ErrorAlert from "./ErrorAlert";
import React, { useState } from "react";
import MainGridLayout from './MainGridLayout';
import { isLength, contains } from "validator";
import { useNavigate } from "react-router-dom";
import { signup, getUser } from "../api/users";
import TextField from '@mui/material/TextField';
import { isLoggedIn } from "../helpers/authHelper";
import { Button, Card, Typography } from "@mui/material";
import { createBotPersonality } from "../api/bot_behavior";
import { modeCol } from "../functions/colors";
import { useGlobalContext } from "../context/context";



const indicesToQuestions = {
  0: ["What is your fantasy character's username? It must be unique", 
  "What species does your twinly belong to? (ex: human, elf, dwarf, dragon, et cetera)",
  "What's their gender?"],
  1: ["What's their superpower? (ex: magic, superheroes, et cetera)", 
  "Where does your twinly come from? Describe their homeland or origin story", 
  "What's their catchphrase?", "What makes them tick and what makes them happy?"],
  2: ["What are their strengths and weaknesses? Do they have any special abilities or powers?",
  "What is their kryptonite?",
  "What are some of their likes and dislikes? (ex: favorite foods, hobbies, pet peeves, et cetera)",
  "What is their personality like? Are they brave, cunning, compassionate, mischievous, et cetera?"],
  3: ["What is your character's ultimate goal or aspiration?",
  "How does your character interact with other beings in their world? Are they friendly, aloof, suspicious, et cetera?"],
  4: ["Do they have any enemies or rivals? Describe them",
  "Do they have any unique quirks or habits?"],
  5: ["Freestyle- Please write anything you'd like your Twinly to know about you"]

};


const CreateTab = () =>{
  const { mode } = useGlobalContext();
  const navigate = useNavigate();

  const user = isLoggedIn();
  const [serverError, setServerError] = useState("");

  const [qIndex, setQIndex] = useState(0);
  const [formData, setFormData] = useState({});

  const handlePrev = () => {
    setQIndex(qIndex-1);
  };
  
  const handleNext = async() => {
    if (qIndex == 0){
      const botName = formData["What is your fantasy character's username? It must be unique"];
      if (!botName || botName === ""){
        setServerError("Empty username");
      }
      else if (contains(botName, " ")) {
        setServerError("Username must not contain spaces");
      }
      else if (!isLength(botName, { min: 6, max: 30 })) {
        setServerError("Username must be between 6 and 30 characters long");
      }
      else{
        const existingUser = await getUser({id: botName});
        if (existingUser.error){
          setServerError(null);
          setQIndex(qIndex+1);
        }
        else{
          setServerError("Username taken");
        }
      }
      
    }
    else{
      setQIndex(qIndex+1);
    }
    console.log(formData);
  };

  const handleSubmit = async() => {
    try{
      const botName = formData["What is your fantasy character's username? It must be unique"];
      let catchphrase = formData["What's their catchphrase?"];
      if (!catchphrase){
        catchphrase = user.username + "'s Twinly"
      }
      const botData = {
        username: botName,
        email: botName + "@bot.mytwinly.ai",
        password: botName + "msQ3NL0u",
        biography: catchphrase,
        isBot: true,
      };
      const new_bot_data = await signup(botData);
      console.log(new_bot_data);
      console.log("till here");
      const botPersonality = await createBotPersonality(new_bot_data.userId, formData);
      console.log("till here 2");
      console.log(botPersonality);
      navigate("/create/success", { state: { user: new_bot_data } });

    } catch(err){
      console.log(err);
      navigate("/create/error");
    }
  };

  const getValue = (question) => {
    if(question in formData){
      return formData[question];
    }
    else{
      return "";
    }
  };

  return (
    <Card style={{height: '45vw', padding:30, backgroundColor: modeCol(mode).cardColor}}>
      <Typography color={modeCol(mode).textColor} style={{padding:30}} variant="h5" textAlign="center"><b>Create Any Personality You Can Imagine!</b></Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding:5 }}>
        {indicesToQuestions[qIndex].map(function(question, i){
          return (
            <TextField
              id={question}
              label={question}
              value={getValue(question)}
              variant="outlined"
              onChange={(e) => setFormData({ ...formData, [e.target.id]: e.target.value })}
              InputLabelProps={{
              style: { color: modeCol(mode).textColor },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: modeCol(mode).borders },
                '& .MuiInputLabel-root': { color: modeCol(mode).textColor },
                '&:hover fieldset': { borderColor: modeCol(mode).borders },
                color: modeCol(mode).textColor,
              },
            }}
            />
          )
        })}

      </Box>
      <ErrorAlert error={serverError} />

      <div style={{ display: "flex", border:2 }}>
        {qIndex > 0 && (
          <Button style={{ marginRight: "auto"}} variant="contained" color="primary" onClick={handlePrev}>Prev</Button>
          )
        }

        {qIndex != 4 && (
          <Button style={{ marginLeft: "auto"}} variant="contained" color="primary" onClick={handleNext}>Next</Button>
          )
        }

        {qIndex == 4 && (
          <Button style={{ marginLeft: "auto"}} variant="contained" color="primary" onClick={handleSubmit}>Submit</Button>
          )
        }
        
        
      </div>

      

    </Card>
  );
};


const FantasyContainer = () => {
  return (
    <MainGridLayout
      left={<CreateTab />}
    />
  );
};

export default FantasyContainer;
