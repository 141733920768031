export const Icon = ({ size }) => (
   <svg
      style={{
         width: size,
         height: size
      }}
      viewBox="0 0 84.310059 96.397087"
      version="1.1"
      id="svg1"
      xmlns="http://www.w3.org/2000/svg"
   ><defs
         id="defs1" /><g
            id="layer1"
            fill="#005dfe"
            transform="translate(-59.949029,-111.60159)"><path
            d="m 63.130878,207.43871 c -2.48346,-1.22928 -3.42609,-3.44131 -3.12894,-7.34251 0.63507,-8.33759 3.92234,-15.49465 9.7988,-21.33397 4.88515,-4.85428 10.26832,-7.76687 17.21475,-9.31414 2.80633,-0.62509 23.920472,-0.81056 28.237602,-0.24804 6.85961,0.8938 13.31676,3.9769 18.45747,8.81292 6.24893,5.87854 9.9144,13.74278 10.48849,22.50296 0.19888,3.03468 -0.061,4.13318 -1.31608,5.56261 -0.45628,0.51968 -1.30688,1.1617 -1.89022,1.4267 -1.00984,0.45877 -2.87128,0.48209 -38.89602,0.48743 l -37.835412,0.006 z m 36.18773,-47.84251 c -11.31346,-1.28811 -20.29791,-10.67144 -21.14019,-22.07874 -0.42869,-5.80592 0.89036,-10.88682 4.02612,-15.50836 1.22007,-1.79816 4.35213,-4.95389 6.10242,-6.14853 2.12332,-1.44925 5.5464,-3.01723 8.02615,-3.67647 2.69586,-0.7167 8.322222,-0.7848 11.016512,-0.13336 4.33458,1.04805 8.00873,3.02721 11.1184,5.98917 3.6274,3.4551 5.94626,7.40857 7.08039,12.07153 0.7989,3.28462 0.79739,8.35631 -0.003,11.34454 -0.69642,2.59881 -2.31634,6.04187 -3.85008,8.18313 -1.31239,1.83223 -4.21519,4.69377 -5.97217,5.88729 -4.60511,3.12825 -10.91797,4.69444 -16.404172,4.0698 z"
            id="path1" /></g></svg>
);

export default Icon;
