import React from "react";
import HorizontalStack from "./util/HorizontalStack";
import UserAvatar from "./UserAvatar";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { modeCol } from "../functions/colors";
import { useGlobalContext } from "../context/context";
import { UserNameAndIcon } from "./UserTypeIcon";
import { cutText } from "../functions/cutText";

const FindUserCard = ({ username, imgUrl, type }) => {
  const { mode } = useGlobalContext();
  return (
    <HorizontalStack justifyContent="space-between" key={username}>
      <HorizontalStack>
        <UserAvatar width={30} height={30} username={username} imgUrl={imgUrl} />
        <Link style={{ textDecoration: 'none', color: modeCol(mode).brandColor }} to={"/users/" + username}>
          <Typography sx={{ fontSize: { sm: '10px',  md: '12px' } }} color={modeCol(mode).textColor}>
            {cutText(username, 18)}
          </Typography>
        </Link>
        <UserNameAndIcon type={type} />
      </HorizontalStack>
      <Link style={{ textDecoration: 'none', color: modeCol(mode).brandColor }} to={"/users/" + username}>View</Link>
    </HorizontalStack>
  );
};

export default FindUserCard;
