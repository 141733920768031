import { useTheme } from "@emotion/react";
import {
  Avatar,
  Button,
  Card,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { AiFillEdit } from "react-icons/ai";
import { isLoggedIn } from "../helpers/authHelper";
import ContentUpdateEditor from "./ContentUpdateEditor";
import Footer from "./Footer";
import Loading from "./Loading";
import UserAvatar from "./UserAvatar";
import HorizontalStack from "./util/HorizontalStack";
import { modeCol } from "../functions/colors";
import { useGlobalContext } from "../context/context";
import TwinlyType from './TwinlyType';
import starwars from "./images/discover-section-images/starwars.jpeg";
import leagueoflegends from "./images/discover-section-images/leagueoflegends.webp";
import overwatch from "./images/discover-section-images/overwatch.webp";



const Space = (props) => {
  const { mode } = useGlobalContext();
  const [space, setSpace] = useState(null);
  const [following, setFollowing] = useState(null);
  const [followerCount, setFollowerCount] = useState(0);
  const [postCount, setPostCount] = useState(0);
  const currentUser = isLoggedIn();
  const theme = useTheme();
  const iconColor = theme.palette.primary.main;
  

  useEffect(() => {
    if (props.space) {
      setSpace(props.space.space);
      setFollowerCount(props.space.followerCount);
      setPostCount(props.space.postCount);


    }

    

  }, [props.space]);

  useEffect(() => {
    if (props.followingData) {
      setFollowing(props.followingData.data);
    }
  }, [props.followingData]);

  return (
    <Card sx={{ bgcolor: modeCol(mode).cardColor }}>
      {space ? (
        <Stack alignItems="center" spacing={2}>
          <Box my={1}>
            {space.name=="LeagueOfLegends" ? (
                <TwinlyType key={space.name} sourceImg={leagueoflegends} text={""} local={space.name} />
            ): space.name=="StarWarsOutlaws" ? (
              <TwinlyType key={space.name} sourceImg={starwars} text={""} local={space.name} />
            ):  (
              <TwinlyType key={space.name} sourceImg={overwatch} text={""} local={space.name} />
            )


            }

            
          </Box>

          <Typography color={modeCol(mode).textColor} variant="h5">{space.name}</Typography>

          {props.editing ? (
            <Box>
              <ContentUpdateEditor
                handleSubmit={props.handleSubmit}
                originalContent={space.description}
                validate={props.validate}
              />
            </Box>
          ) : space.description ? (
            <Typography color={modeCol(mode).textColor} textAlign="center" variant="p">
              {/* <b>Bio: </b> */}
              {space.description}
            </Typography>
          ) : (
            <Typography color={modeCol(mode).textColor} variant="p">
              <i>No Description yet</i>
            </Typography>
          )}

          {currentUser && space._id === currentUser.userId && (
            <Box>
              <Button
                startIcon={<AiFillEdit color={iconColor} />}
                onClick={props.handleEditing}
              >
                {props.editing ? <>Cancel</> : <>Edit bio</>}
              </Button>
            </Box>
          )}

          {currentUser && space._id !== currentUser.userId && (
            <Button variant="outlined" onClick={props.handleFollow}>
              {following ? (
                "Unfollow"
              ) : (
                "Follow"
              )}
            </Button>
          )}

          <HorizontalStack divider={<Divider orientation="vertical" flexItem />}>
            <Typography color={modeCol(mode).textColor}>
              Followers: <b>{followerCount}</b>
            </Typography>
            <Typography color={modeCol(mode).textColor}>
              Posts: <b>{postCount}</b>
            </Typography>
          </HorizontalStack>
        </Stack>
      ) : (
        <Loading label="Loading profile" />
      )}
    </Card>
  );
};

export default Space;
