import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { isLoggedIn } from '../helpers/authHelper';

const AppContext = React.createContext();


export const AppProvider = ({ children }) => {

    // Needed functions
    const navigate = useNavigate();
    const location = useLocation();

    // Navigating user by pages
    // Check local storage for registration status: (start || pending || done)
    const [hasRegistered, setHasRegistered] = useState(() => {
        const registerStatus = localStorage.getItem('hasRegistered');
        return registerStatus ? JSON.parse(registerStatus) : "start";
    });

    useEffect(() => {
        localStorage.setItem('hasRegistered', JSON.stringify(hasRegistered));
    }, [hasRegistered]);


    const [path, setPath] = useState({
        registerDone: false,
        verifyDone: false,
        interestDone: false,
        subcategoryDone: false,
        bioDone: false,
        socialDone: false
    });

    // IsUserLogged IN ?
    const user = isLoggedIn();

    // OPEN Modal
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalText, setModalText] = useState('');

    const handleOpenModal = () => {
        setModal(true)
    }

    const handleCloseModal = () => {
        setModal(false);
        setModalTitle('');
        setModalText('');
    }

    // Mode
    const [mode, setMode] = useState(() => {
        // const savedMode = localStorage.getItem('mode');
        // return savedMode ? JSON.parse(savedMode) : false;
        return true;
    });

    const handleMode = () => {
        setMode((prevMode) => !prevMode);
    }

    // Width AND Height (Working with DIMENTIONS)
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const { width, height } = dimensions;
    const mobile = width < 800;

    const updateDimensions = () => {
      const newWidth = window.innerWidth;
      const newHeight = window.innerHeight;
      setDimensions({ width: newWidth, height: newHeight });
    };

    // search
    const [search, setSearch] = useState("");
    const [searchIcon, setSearchIcon] = useState(false);

    const handleSearchIcon = (e) => {
        setSearchIcon(!searchIcon);
    };
    
    const handleSearchSubmit = (currentSearchValue) => {
        if (currentSearchValue) {
            navigate("/search?" + new URLSearchParams({ search: currentSearchValue }));
        } else {
            navigate('/explore')
        }
    };

    // sidebarControll
    const [sidebarControll, setSidebarControll] = useState(false);

    const handleSidebarControll = () => {
        setSidebarControll(!sidebarControll)
    }

    // Sidebar color
    const activeLink = () => {
        const { pathname } = location;
        if (pathname.startsWith('/explore')) return 'Explore';
        if (pathname.startsWith('/home')) return 'Feed';
        if (pathname.startsWith('/create')) return 'Create';
        if (pathname.startsWith('/users/')) return 'Profile';
        if (pathname.startsWith('/messenger')) return 'Messages';
        if (pathname.startsWith('/games')) return 'Games';
        if (user && pathname === '/') return 'Explore'; 
        return "Explore";
    };

    // Collect selected interests of user
    const [selectedInterests, setSelectedInterests] = useState([]);

    // Coolection of selected subcategories of user
    const [selectedSubCategories, setSelectedSubCategories] = useState([]);

    // Coolection of user Bio Data
    const [bioData, setBioData] = useState({ specialInfo: "", friendsInfo: "" })

    // FormData for sign UP User
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        username: "",
        password: "",
        phoneNumber: "",
        year: "",
        month: "",
        day: "",
    });

    // Collection of user Socials
    const [image, setImage] = useState(null);
    const [socialMedia, setSocialMedia] = useState({
      facebook: '',
      twitter: '',
      instagram: '',
      linkedin: ''
    });

    // ALL USEEFFECTS()

    // Location
    useEffect(() => {
        activeLink(location) 
    }, [location])

    // Dimentions
    useEffect(() => {
        updateDimensions();
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    // Mode ( Dark mode or White mode )
    useEffect(() => {
        localStorage.setItem('mode', JSON.stringify(mode));
    }, [mode]);

    // Register Status of User

    useEffect(() => {
        if (hasRegistered === "pending") {
            // if email hasn't been verified, send to verify page.
            if (user && !user.isVerified) {
                setPath({ ...path, registerDone: true })
                navigate('/verify')
            } else {
                // Update path state with callback to ensure latest state is used
                setPath({ ...path, registerDone: true, verifyDone: true })
                navigate('/interests')
            }
        }
        console.log("hasRegistered:", hasRegistered);
    }, []);


    return <AppContext.Provider value={{

        // Is User Logged In
        user,

        // Double protected paths
        path, setPath, 
        hasRegistered, setHasRegistered,

        // MODE
        mode, setMode,
        handleMode,
        location,

        // MODAL
        modal, setModal,
        handleOpenModal, 
        handleCloseModal,
        modalTitle, setModalTitle,
        modalText, setModalText,

        // SCREEN WIDTH (DIMENTIONS)
        mobile, height,

        // SEARCH 
        handleSearchIcon,
        search, setSearch,
        handleSearchSubmit,
        searchIcon, setSearchIcon,

        // USER BIO DATA
        bioData, setBioData,
        
        // FORM FOR SIGN UP USER
        formData, setFormData,

        // COLLECTION OF USER SOCIALS
        image, setImage,
        socialMedia, setSocialMedia,

        // SIDE OPEN AND CLOSE
        sidebarControll, handleSidebarControll,
        // SIDEBAR COLOR
        activeLink,

        // COLLECT SELECTED INTEREST OF USER
        selectedInterests, setSelectedInterests,

        // COLLECT SELECTED SUBCATEGORIES OF USER
        selectedSubCategories, setSelectedSubCategories,

    }}>
        {children}
    </AppContext.Provider>
}

export const useGlobalContext = () => {
    return useContext(AppContext);
}