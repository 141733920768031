import React from 'react';
import Markdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

const MessageMarkdown = ({ message }) => {
  return (
    <Markdown
      children={message.content}
      components={{
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || '');
          const text = String(children).replace(/\n$/, '');
          return !inline && match ? (
            <SyntaxHighlighter
              language={match[1]}
              style={{ ...a11yDark, borderRadius: '10px', padding: '1em', margin: '0.5em 0', fontSize: '0.9em' }}
              PreTag="div"
              {...props}
            >
              {text}
            </SyntaxHighlighter>
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          );
        }
      }}
    />
  );
};

export default MessageMarkdown;
