import React from 'react';
import UserAvatar from '../UserAvatar';
import { Link } from 'react-router-dom';
import { AiFillCaretLeft } from 'react-icons/ai';
import { modeCol } from '../../functions/colors';
import HorizontalStack from '../util/HorizontalStack';
import { IconButton, Typography } from '@mui/material';
import { useGlobalContext } from '../../context/context';
import { messagesContainer, topTitle, userNameStyle } from './Styles';

const MessagesTop = ({ mobile, conservant, setConservant }) => {
    const { mode } = useGlobalContext();

    return (
        <HorizontalStack spacing={2} sx={messagesContainer(mode)}>
            {/* {mobile && ( */}
                <IconButton onClick={() => setConservant(null)} sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'center', alignItems: 'center', p: 0 }}>
                  <AiFillCaretLeft color={modeCol(mode).textColor} />
                </IconButton>
            {/* )} */}
            <Link style={topTitle()} to={"/users/" + conservant.username}>
                <UserAvatar
                    username={conservant.username}
                    height={30}
                    width={30}
                    imgUrl={conservant.profilePicUrl}
                />
                <Typography style={userNameStyle(mode)}>{conservant.username}</Typography>
            </Link>
        </HorizontalStack>
    )
}

export default MessagesTop
