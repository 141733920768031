import { Card, Button, Typography, Stack, useTheme } from "@mui/material";
import React from "react";
import HorizontalStack from "./util/HorizontalStack";
import Moment from "react-moment";

const Notification = ({ notification, onClick, onDelete }) => {
  const theme = useTheme();
  // console.log(`background colors: ${theme.backgroundColors}`);
  const backgroundColor = theme.palette.grey["100"]
  
  const handleClick = (e) => {
    e.stopPropagation();
    onClick(notification);
  }

  return (
    <HorizontalStack
      sx={{ paddingY: 1, width: "100%" }}
      spacing={2}
      alignItems="center"
      onClick={handleClick}
    >
      <Stack>
        {/* TODO: icon based on notification.type */}
      </Stack>

      <Card
        sx={{
          borderRadius: "25px",
          borderWidth: "1px",
          paddingY: "12px",
          maxWidth: "70%",
          paddingX: 2,
          backgroundColor: backgroundColor
        }}
      >
        {notification.message}
      </Card>
      <Typography variant="caption">
        <Moment fromNow>{notification.createdAt}</Moment>
      </Typography>

      {/* <Stack>
        <Button onClick={onDelete}>X</Button> 
      </Stack> */}
    </HorizontalStack>
  );
};

export default  Notification;
