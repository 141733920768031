import { BASE_URL } from "../config";


const getSuperbasicFields = async (params) => {
  try {
    console.log("in getSuperbasicFields api");
    console.log("params.id is");
    console.log(params.id);
    const res = await fetch(BASE_URL + "api/misc/twinlycreationfields/superbasic/" + params.id);
    console.log("getting out of getSuperbasicFields api");
    return res.json();
  } catch (err) {
    console.log(err);
  }
};



export { getSuperbasicFields};
