import React from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Button } from "@mui/material";

const GoBack = () => {
  const navigate = useNavigate()
  return (
    <Button onClick={()=>navigate(-1)}>
      <Typography>
        &lt;&lt; Go back
      </Typography>
    </Button>
  );
};

export default GoBack;
