import './switch.css';
import React from 'react';
import { modeCol } from '../functions/colors';
import { IoMoonOutline } from "react-icons/io5";
import { MdOutlineWbSunny } from "react-icons/md";
import { useGlobalContext } from '../context/context';

// Style
const labelStyle = (mode) => {
    return {
        background: modeCol(mode).switchBgColor, 
        boxShadow: `inset 0 0 3px ${modeCol(mode).switchInnerShadow}`
    }
}

const ToggleSwitch = () => {
    const { mode, handleMode } = useGlobalContext();
    return (
      <div>
        <input
          type="checkbox"
          className="checkbox"
          id="checkbox"
          checked={mode}
          onChange={handleMode}
        />
        <label style={labelStyle(mode)} htmlFor="checkbox" className="checkbox-label">
          <MdOutlineWbSunny fontSize={20} className="sun-icon" />
          <span style={{ backgroundColor: modeCol(mode).switchBall }} className="ball">
              {mode ? (
                  <IoMoonOutline color="#FFF" fontSize={18} />
              ) : (
                  <MdOutlineWbSunny color="#FFF" fontSize={18} />
              )}
          </span>
          <IoMoonOutline fontSize={20} className="moon-icon" />
        </label>
      </div>
    );
};

export default ToggleSwitch;
