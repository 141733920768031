import { Button, Card, Link, Stack, Container, Typography, Tab, Tabs, Grid } from "@mui/material";
import { alignProperty } from "@mui/material/styles/cssUtils";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { MdSettingsInputAntenna } from "react-icons/md";
import { useLocation, useSearchParams } from "react-router-dom";
import { getPosts, getUserLikedPosts } from "../api/posts";
import { getUsers, getTwinlies } from "../api/users";
import { getSpaces } from "../api/spaces";
import { isLoggedIn } from "../helpers/authHelper";
import CreatePost from "./CreatePost";
import Loading from "./Loading";
import PostCard from "./PostCard";
import SortBySelect from "./SortBySelect";
import HorizontalStack from "./util/HorizontalStack";
import ConversationStarter from "./ConversationStarter";
import {testGCloudAPI} from "../api/bot_behavior";
import {getBotRes} from "../api/bot_behavior";
import UserEntry from "./UserEntry";
import SpaceEntry from "./SpaceEntry";
import { modeCol } from "../functions/colors";
import { useGlobalContext } from "../context/context";





const SearchResults = (props) => {
  const { mode } = useGlobalContext();
  const [posts, setPosts] = useState([]);
  const [twinlies, setTwinlies] = useState([]);
  const [users, setUsers] = useState([]);
  const [spaces, setSpaces] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [end, setEnd] = useState(false);
  const [sortBy, setSortBy] = useState("-createdAt");
  const [count, setCount] = useState(0);
  const user = isLoggedIn();

  const [search] = useSearchParams();
  const [effect, setEffect] = useState(false);

  const searchExists =
    search && search.get("search") && search.get("search").length > 0;

  const fetchUsers = async () => {
    let query = {};
    if (searchExists) query.search = search.get("search");
    const users_data = await getUsers(user && user.token, query);

    setUsers(users_data);
    setLoading(false);
    console.log(users);

  };

  const fetchTwinlies = async () => {
    let query = {};
    if (searchExists) query.search = search.get("search");
    const twinlies_data = await getTwinlies(user && user.token, query);

    setTwinlies(twinlies_data);
    setLoading(false);
    
  };

  const fetchSpaces = async () => {
    let query = {
    };
    if (searchExists) query.search = search.get("search");
    const spaces_data = await getSpaces(user && user.token, query);
    console.log("spaces_data is");
    console.log(spaces_data);

    console.log("spaces is");
    console.log(spaces);

    setSpaces(spaces_data);
    setLoading(false);

  };

  const fetchPosts = async () => {
    setLoading(true);
    const newPage = page + 1;
    setPage(newPage);

    let query = {
      page: newPage,
      sortBy,
    };

    let data;

    if (props.contentType === "posts") {
      if (searchExists) query.search = search.get("search");

      data = await getPosts(user && user.token, query);
    }

    if (data.data.length < 10) {
      setEnd(true);
    }

    setLoading(false);
    if (!data.error) {
      setPosts([...posts, ...data.data]);
      setCount(data.count);
    }
  };

  useEffect(() => {
    if (props.contentType=="posts"){
      fetchPosts();
    } else if (props.contentType=="all") {
      fetchTwinlies();
      fetchUsers();
    } else if (props.contentType=="twinlies"){
      fetchTwinlies();
    }
    else if (props.contentType=="users"){
      fetchUsers();
    }
    else if (props.contentType=="spaces"){
      fetchSpaces();
    }
    
  }, [sortBy, effect]);

  useEffect(() => {
    setPosts([]);
    setPage(0);
    setEnd(false);
    setEffect(!effect);
  }, [search]);

  const handleSortBy = (e) => {
    const newSortName = e.target.value;
    let newSortBy;

    Object.keys(sorts).forEach((sortName) => {
      if (sorts[sortName] === newSortName) newSortBy = sortName;
    });

    setPosts([]);
    setPage(0);
    setEnd(false);
    setSortBy(newSortBy);
  };

  const removePost = (removedPost) => {
    setPosts(posts.filter((post) => post._id !== removedPost._id));
  };

  const handleBackToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const contentTypeSorts = {
    posts: {
      "-createdAt": "Latest",
      "-likeCount": "Likes",
      "-commentCount": "Comments",
      createdAt: "Earliest",
    },
    liked: {
      "-createdAt": "Latest",
      createdAt: "Earliest",
    },
  };

  const sorts = contentTypeSorts[props.contentType];

  
  return (
    <>
      <Stack spacing={2}>

        {/* {searchExists && (
          <Box>
            <Typography color={modeCol(mode).textColor} variant="h5" gutterBottom>
              Showing results for "{search.get("search")}"
            </Typography>
            {(twinlies && twinlies.length === 0) || (users && users.length === 0) || (posts && posts.length === 0)  (
              <Typography color={modeCol(mode).textColor} variant="span">
                0 results found
            </Typography>
            )}
          </Box>
        )} */}
        <Stack alignItems={"center"}>
          <Stack spacing={2} sx={{ width: { xs: "100%", md: "80%" } }}>
            {props.contentType=="posts" && posts.map((post, i) => (
              <PostCard
                preview="primary"
                key={post._id}
                post={post}
                removePost={removePost}
              />
            ))}
          </Stack>
        </Stack>
        <Stack spacing={2} alignItems="center" justifyContent="center">
          {props.contentType=="users" && users.map((user) => (
            <UserEntry props={user} />
          ))}

          {props.contentType=="twinlies" && twinlies.map((twinly) => (
              <UserEntry props={twinly} />
          ))}

          {/* {props.contentType=="spaces" && spaces.map((space) => (
              <SpaceEntry name={space.name} key={space.name} />
          ))} */}
        </Stack>

        <Stack spacing={2} alignItems="center" justifyContent="center">
          {props.contentType=="all" && users.map((user) => (
            <UserEntry props={user} />
          ))}

          {props.contentType=="all" && twinlies.map((twinly) => (
              <UserEntry props={twinly} />
          ))}
        </Stack>

        {loading && <Loading />}
        {end ? (
          <Stack py={5} alignItems="center">
            <Typography variant="h5" color={modeCol(mode).textColor} gutterBottom>
              {posts.length > 0 ? (
                <>All posts have been viewed</>
              ) : (
                <>No posts available</>
              )}
            </Typography>
            <Button variant="text" size="small" onClick={handleBackToTop}>
              Back to top
            </Button>
          </Stack>
        ) : (
          !loading &&
          posts &&
          posts.length > 0 && (
            <Stack pt={2} pb={6} alignItems="center" spacing={2}>
              <Button onClick={fetchPosts} variant="contained">
                Load more
              </Button>
              <Button variant="text" size="small" onClick={handleBackToTop}>
                Back to top
              </Button>
            </Stack>
          )
        )}
      </Stack>
    </>
  );
};

export default SearchResults;
