import { Box, Button, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import { modeCol } from "../functions/colors";
import { useGlobalContext } from "../context/context";

const ContentUpdateEditor = (props) => {
  const { mode } = useGlobalContext();
  const [content, setContent] = useState(props.originalContent);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setContent(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const content = e.target.content.value;
    let error = null;

    if (props.validate) {
      error = props.validate(content);
    }

    if (error && error.length !== 0) {
      setError(error);
    } else {
      props.handleSubmit(e);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Stack>
        <TextField
          value={content}
          fullWidth
          margin="normal"
          name="content"
          onChange={handleChange}
          error={error.length !== 0}
          helperText={error}
          multiline
          sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: modeCol(mode).borders },
                '& .MuiInputLabel-root': { color: modeCol(mode).textColor },
                color: modeCol(mode).textColor,
              },
          }}
        />
        <Button
          type="submit"
          variant="outlined"
        >
          Update
        </Button>
      </Stack>
    </Box>
  );
};

export default ContentUpdateEditor;
