import React from 'react'
import TwinlyType from './TwinlyType';
import { twinlyTypes } from '../data/data';
import { modeCol } from '../functions/colors';
import { isLoggedIn } from '../helpers/authHelper';
import { useGlobalContext } from '../context/context';
import { Box, Card, Divider, Grid, Typography } from '@mui/material';

const charsStyle = () => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: { 
    xs: 'repeat(2, 1fr);',  
    md: 'repeat(3, 1fr);', 
  },
})

const CreateTab = () => {
  const { mode } = useGlobalContext();
  const user = isLoggedIn();
  return (
    <Card sx={{bgcolor: modeCol(mode).cardColor}}>
      <Typography color={modeCol(mode).textColor} style={{padding:20}} variant="h4" textAlign="center">
        <b>Create A Twinly!</b>
      </Typography>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} md={6}>
          <TwinlyType sourceImg={user.profilePicUrl} text={"You"}/>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ bgcolor: modeCol(mode).grayText }} orientation="horizontal" flexItem />
        </Grid>
        <Box sx={charsStyle()}>
          {twinlyTypes.map((twinly) => {
            const { id, text, local, img } = twinly
            return (
              <TwinlyType key={id} sourceImg={img} text={text} local={local} />
            )
          })}
        </Box>
      </Grid>
    </Card>
  );
};

export default CreateTab;
