export const mainCols = {

    // BOTH COLORS
    cardContentColor: '#778899',
    grayBorder: "#A4A4A4",
    brandColor: '#9595FE',
    grayText: '#A4A4A4',
    darkerText: "#888",

    // DARK MODE COLORS
    switchInnerShadowDark: "#9595FE",
    mainDarkColor: '#171717',
    darkContainer: '#1C1C1C',
    switchDarkBg: "#343434",
    darkBorder: '#424242',
    darkHover: '#2A2A2A',
    ballDark: "#9595FE",
    darkCard: '#2A2A2A',
    lightText: '#FFF',

    // LIGHT MODE COLORS
    switchInnerShadowLight: "#F8A100",
    mainLightColor: '#FFFFFF',
    lightContainer: '#F8F8F8',
    switchLightBg: "#EBEBEB",
    lightBorder: '#AFAFAF',
    lightHover: '#D2D2FF',
    lightCard: '#FFFFFF',
    ballLight: "#EEBA73",
    darkText: '#000',
    

}