import React, { useState } from 'react';
// import Dropzone from 'react-dropzone';
import {useDropzone} from 'react-dropzone';
import {Button} from "@mui/material";

const FilepickerDropzone = ({onDrop, clickOnly = false}) => {
//   const [files, setFile] = useState([]);
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone(
    {
      accept: {
        'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
      },
      maxFiles: 1,
      onDrop: onDrop
    }
  );

  return (
    <section className="dropzone-container">
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        {clickOnly ? (<Button>Choose File</Button>) : (<div>Drag 'n' drop some files here, or click to select files</div>)}
      </div>
    </section>
  );
};

export default FilepickerDropzone;