import React from 'react';
import { Drawer } from '@mui/material';
import TopTwinlies from './TopTwinlies';
import { DrawerBox, SideBox } from './Styles';
import MenuIcon from '@mui/icons-material/Menu';
import { useGlobalContext } from '../../context/context';

const drawerWidth = 80;

const MainSmallSidebar = () => {

  // Global
  const { mode, handleSidebarControll } = useGlobalContext();
  
  return (
    <Drawer
      variant="permanent" 
      anchor="left" 
      sx={DrawerBox(drawerWidth, mode)}
    >
      <SideBox onClick={handleSidebarControll}>
        <MenuIcon color='primary' />
      </SideBox>
      <TopTwinlies />
    </Drawer>
  );
};

export default MainSmallSidebar;
