import {
    Alert,
    Button,
    Container,
    Stack,
    TextField,
    Typography,
  } from "@mui/material";

  import { Box } from "@mui/system";
  import React, { useState } from "react";
  import { Link, useNavigate } from "react-router-dom";
  import { forgotPassword } from "../../api/users";
  import ErrorAlert from "../ErrorAlert";
  import Copyright from "../Copyright";
  
  const ForgotPasswordView = () => {
  
    const [formData, setFormData] = useState({
      email: "",
    });

    const [submitted, setSubmitted] = useState(false);
    const [serverError, setServerError] = useState("");
  
    const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      console.log({...formData})
  
      const data = await forgotPassword(formData.email);
      if (data.error) {
        setServerError(data.error);
      } else {
        setSubmitted(true);
      }
    };
  
    return (
      <Container maxWidth={"xs"} sx={{ mt: 6 }}>
        <Stack alignItems="center">
          <Typography variant="h2" color="text.secondary" sx={{ mb: 6 }}>
            <Link to="/" color="inherit" underline="none">
              TwinlyAI
            </Link>
          </Typography>
          <Typography variant="h5" gutterBottom>
            Forgot Password
          </Typography>

          {submitted ? (
              <Alert severity="success">Password reset email sent. Please check your email to reset your password.</Alert>
              // TODO: link back to homescreen? 
            ) : (
              <Stack alignItems="center">
                  <Typography color="text.secondary">
                      Please enter the email address you registered with.
                  </Typography>
                  <Box component="form" onSubmit={handleSubmit}>
                      <TextField
                      label="Email Address"
                      fullWidth
                      margin="normal"
                      autoComplete="email"
                      autoFocus
                      required
                      id="email"
                      name="email"
                      onChange={handleChange}
                      />
                      <ErrorAlert error={serverError} />
                      <Button type="submit" fullWidth variant="contained" sx={{ my: 2 }}>
                      Submit
                      </Button>
                  </Box>
              </Stack>
            )
         }
          <Box sx={{ mt: 3 }}>
            <Copyright />
          </Box>
        </Stack>
      </Container>
    );
  };
  
  export default ForgotPasswordView;
  