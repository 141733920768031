import * as React from 'react';
import Box from '@mui/material/Box';
import styled from '@emotion/styled';
import List from '@mui/material/List';
import MainTopBar from './MainTopBar';
import { navLinks } from '../data/data';
import { Link } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import { TextField } from '@mui/material';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import ListItem from '@mui/material/ListItem';
import { modeCol } from '../functions/colors';
import Typography from '@mui/material/Typography';
import MainSmallSidebar from './Messenger/MainSmallSidebar';
import MainMobileSidebar from './MainMobileSidebar';
import CssBaseline from '@mui/material/CssBaseline';
import { useGlobalContext } from '../context/context';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';

import { isLoggedIn } from '../helpers/authHelper';
import { getNavLinks } from '../data/data';
import ToggleSwitch from './ToggleSwitch';
import MainBottomNavbar from './MainBottomNavbar';



// const drawerWidth = 240;

export const MenuContainer = styled(Box)`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
`;

export const Privacy = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;


export const ToolbarContainer = styled(Toolbar)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
`

export const TopLogo = styled(Typography)`
    font-size: 27px;
    font-weight: 700;
`

export const ItemIcon = styled(ListItemIcon)`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const StyledListItemButton = styled(ListItemButton)(({ active, brandColor, hover }) => ({
  '&:hover': {
    backgroundColor: active ? brandColor : hover,
  },
}));

const drawerWidth = 240;

export default function MainSidebar({ children }) {

  const { user, search, searchIcon, location, mode, activeLink, setSearch, handleSearchSubmit, setModalTitle, setModalText, handleOpenModal } = useGlobalContext();
  let messengerPage = location.pathname.startsWith('/messenger')
  // const homePage = location.pathname === "/"
  // if (homePage && user) {
  //   messengerPage = true
  // }

  const activeColor = (active) => {
    return active ? '#fff' : modeCol(mode).brandColor
  }

  const navLinks = getNavLinks();

  const handlePrivacy = () => {
    setModalTitle("Privacy")
    setModalText("Expected to have Privacy Text")
    handleOpenModal()
  }

  const handleTerms = () => {
    setModalTitle("Terms")
    setModalText("Expected to have Terms Text")
    handleOpenModal()
  }

  const handleGuide = () => {
    setModalTitle("Community Guidelines")
    setModalText("Expected to have Community Guidelines Text")
    handleOpenModal()
  }

  const onSearch = (event) => {
    const value = event.target.value;
    setSearch(value); 
    handleSearchSubmit(value);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      <MainMobileSidebar messengerPage={messengerPage} />

      {messengerPage && (
        <MainSmallSidebar />
      )}

      {!messengerPage && (
        <Drawer
          sx={{
            display: { xs: 'none', md: 'block' },
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              bgcolor: modeCol(mode).sidebarBgColor,
              borderRight: `1px solid ${modeCol(mode).borders}`
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <ToolbarContainer>
            <TopLogo color={modeCol(mode).brandColor}>TwinlyAI</TopLogo>
          </ToolbarContainer>

          <MenuContainer>
            {/* TOP */}
            <Box>
                 <List>
                    {navLinks.map((item) => {
                      const { id, name, link, icon, secure } = item;
                      const active = activeLink() === name
                      const to = (isLoggedIn() || !secure) ? link : '/login';
                      return (
                        <ListItem key={id} disablePadding>
                            <Link to={to} style={{ textDecoration: 'none', width: '100%', color: 'inherit' }}>
                              <StyledListItemButton active={active} brandColor={modeCol(mode).brandColor} hover={modeCol(mode).hoverColor} sx={{ margin: '5px 0', backgroundColor: active ? modeCol(mode).brandColor : 'transparent', borderRadius: '10px'}}>
                                  <ItemIcon>
                                    {icon(activeColor(active), 25)}
                                  </ItemIcon>
                                  <Typography 
                                    color={active ? '#fff' : modeCol(mode).textColor}
                                    fontWeight='bold'
                                  >
                                    {name}
                                  </Typography>
                              </StyledListItemButton>
                            </Link>
                        </ListItem>
                      );
                    })}
                </List>
            </Box>

            {/* BOTTOM  */}
            <Privacy>
              <Typography onClick={handlePrivacy} sx={{ cursor: 'pointer' }} color={modeCol(mode).grayText} fontSize={12}>Privacy</Typography>
              <Typography onClick={handleTerms} sx={{ cursor: 'pointer' }} color={modeCol(mode).grayText} fontSize={12}>Terms</Typography>
              <Typography onClick={handleGuide} sx={{ cursor: 'pointer' }} color={modeCol(mode).grayText} fontSize={12}>Community Guidelines</Typography>
              <Typography color={modeCol(mode).grayText} fontSize={12}>© 2024 Twinly AI</Typography>
            </Privacy>

            {/* TOGGLE BUTTON */}
            <ToggleSwitch />
            
          </MenuContainer>
          <Divider />
        </Drawer>
      )}

      {!messengerPage && (
        <MainTopBar drawerWidth={drawerWidth} />
      )}

      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: modeCol(mode).containerColor, p: messengerPage ? 0 : 3, mt: messengerPage ? 0 : 3, overflow: 'hidden' }}
      >
        {(!messengerPage && searchIcon) ? (
          <Box component="form" 
          onSubmit={handleSearchSubmit} 
          sx={{ display: { xs: 'block', sm: 'none', md: 'none' }}}
          mt={5}>
            <TextField 
              size="small"
              fullWidth
              InputProps={{
                style: {
                  borderRadius: "30px",
                  backgroundColor: modeCol(mode).cardColor,
                  color: modeCol(mode).textColor
                }
              }}
              InputLabelProps={{
                style: {
                  color: modeCol(mode).textColor,
                }
              }}
              label={'Search for posts, users, twinlies, or spaces'}
              value={search}
              onChange={onSearch}
            />
          </Box>
        ) : (null)}
        {!messengerPage && (<Toolbar />)}
        <Box sx={{ minHeight: '100vh', marginBottom: { xs: !messengerPage ? '60px' : 'none', sm: 'none' } }}>{children}</Box>
      </Box>

      {/* Bottom Navbar */}
      {!messengerPage && (
        <MainBottomNavbar />
      )}

    </Box>
  );
}