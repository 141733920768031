import { cutText } from '../../functions/cutText';
import React, { useEffect, useState } from 'react';
import { Typography, Avatar } from '@mui/material';
import { isLoggedIn } from '../../helpers/authHelper';
import { GCLOUD_PUBLIC_BUCKET_URL } from "../../config";
import { useGlobalContext } from '../../context/context';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchBotHumans, handleItemClick } from './functions';
import { MenuContainer, TwinlyItem, avatarText, styledAvatar } from './Styles';

const TopTwinlies = () => {

  // Global
  const { mode } = useGlobalContext();
  // Local
  const user = isLoggedIn();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [activeItem, setActiveItem] = useState(null);
  const [profiles, setProfiles] = useState([{_id: user.userId, username: user.username, profilePicUrl: user.profilePicUrl}]);

  useEffect(() => {
    fetchBotHumans(user, setProfiles);
  }, []); 

  useEffect(() => {
    if (state && state.profileId){
      setActiveItem(state.profileId);
    }
    else{
      setActiveItem(user.userId);
    }
  }, []);
    
  const handleItemClick = (id) => {
    setActiveItem(id);
    let selected_profile = null;
    for (let i = 0; i < profiles.length; i++) {
      if (profiles[i]._id == id){
        selected_profile = profiles[i];
      }
    }
    navigate("/messenger", { state: { user: null, profileId: id, profileUsername: selected_profile.username, profileDisplayPic: selected_profile.profilePicUrl } })
  };

  const userImage = (profilePicUrl, username) => {
    return profilePicUrl ? `${GCLOUD_PUBLIC_BUCKET_URL}${profilePicUrl}` : `https://robohash.org/${username}`
  }

  return (
    <MenuContainer>
      {profiles.map((item) => {
          const { _id, username, profilePicUrl } = item;
          return (
            <TwinlyItem key={_id} onClick={() => handleItemClick(_id)}>
              <Avatar sx={styledAvatar(_id, activeItem, mode)} src={userImage(profilePicUrl, username)}/>
              <Typography sx={avatarText(mode)}>
                  {cutText(username, 10)}
              </Typography>
            </TwinlyItem>
          );
      })}
    </MenuContainer>
  );
};

export default TopTwinlies
