import React from 'react';
import Message from './Message';
import { Stack } from '@mui/material';
import { middleStack } from './Styles';
import { useGlobalContext } from '../../context/context';

const MessagesMiddle = ({ messages, profileId, profileDisplayPic, profileUsername, conservant, messagesEndRef }) => {
    const { mode } = useGlobalContext();
    return (
        <Stack direction="column-reverse" sx={middleStack(mode)}>
            <div ref={messagesEndRef} />
            {messages.map((message, i) => (
                <Message
                  profileId={profileId}
                  profileDisplayPic={profileDisplayPic}
                  profileUsername={profileUsername}
                  conservant={conservant}
                  message={message}
                  key={i}
                />
            ))}
        </Stack>
    )
}

export default MessagesMiddle
