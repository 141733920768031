import "./styles.css";
import React from "react";
import MainGridLayout from './MainGridLayout';
import { Button, Stack, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { modeCol } from '../functions/colors';
import { useGlobalContext } from '../context/context';



const SuccessAnimation = () => {
  return (
      <svg width="300" height="250">
        <circle
          fill="none"
          stroke="#68E534"
          strokeWidth="15"
          cx="250"
          cy="150"
          r="80"
          strokeLinecap="round"
          transform="rotate(-90 200 200)"
          className="circle"
        />
        <polyline
          fill="none"
          stroke="#68E534"
          points="110,165 140,190 190,125"
          strokeWidth="15"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="tick"
        />
      </svg>
  );
};


const ContentTab = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { mode } = useGlobalContext();

  console.log("state is ");
  console.log(state);

  return (
    <Stack direction="column" justifyContent="flex-start" alignItems="center" spacing={2} sx={{ minHeight: '100vh' }}>
      <SuccessAnimation />
      <Typography color={modeCol(mode).textColor} variant="h5" textAlign="center"><b>Your Twinly is now alive!</b></Typography>
      <Button variant="contained" onClick={() => navigate("/messenger", { state: { user: state.user } })}>Talk to your Twinly</Button>
    </Stack>
      
  );
};

const SuccessContainer = () => {
  return (
    <MainGridLayout
      left={<ContentTab />}
    />
  );
};

export default SuccessContainer;
