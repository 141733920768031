import React, { useState, useEffect } from "react"
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, Switch, TextField, Popover, Popper, Stack, Badge } from '@mui/material';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { Link, useNavigate } from 'react-router-dom';
import UserAvatar from './UserAvatar';
import { isLoggedIn, logoutUser } from '../helpers/authHelper';
import { AiOutlineSearch } from 'react-icons/ai';
import { useGlobalContext } from '../context/context';
import { modeCol } from '../functions/colors';
import { FaBell } from "react-icons/fa";
import NotificationsView from "./views/NotificationsView";
import HorizontalStack from './util/HorizontalStack';
import {getNotifications} from "../api/notifications";
import ToggleSwitch from "./ToggleSwitch";
import Feedback from "./Feedback";


export default function MainTopBar({ drawerWidth }) {

  // Global
  const { search, setSearch, mode, handleSidebarControll, handleSearchIcon, handleSearchSubmit, setHasRegistered } = useGlobalContext();

  const navigate = useNavigate();
  const user = isLoggedIn();
  const username = user && isLoggedIn().username;

  const [showNotifications, setShowNotifications] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null);
  const [unseenNotifications, setUnseenNotifications] = useState(0)

  const handleLogout = async (e) => {
    setHasRegistered("start")
    logoutUser();
    navigate("/login");
  };

  const toggleNotifications = (e) => {
    let newVal = !showNotifications
    let newAnchorEl = newVal ? document.getElementById("icon-notifications") : null
    setAnchorEl(newAnchorEl)
    setShowNotifications(newVal)
    if (newVal) {
      handleNotificationsSeen()
    }
  }

  const handleNotificationsSeen = () => {
    setUnseenNotifications(0)
  }

  const closeNotifications = () => {
    // console.log('close notifications')
    setAnchorEl(null)
    setShowNotifications(false)
  }

  const onClickedNotification = (notification) => {
    console.log(`clicked notification with id: ${notification._id}`)
  }

  useEffect(() => {
    const getNotificationsNum = async() => {
      const response_data = await getNotifications(user);
      const new_notifications = response_data.data;
      const numNotifications = new_notifications.filter(entry => !entry.seen).length;

      setUnseenNotifications(numNotifications);
    }
    getNotificationsNum();
    
  }, []);

  const onSearch = (event) => {
    const value = event.target.value;
    setSearch(value); 
    handleSearchSubmit(value);
  };

  return (
    <AppBar
        position="fixed"
        sx={{ 
          width: { xs: '100%', md:`calc(100% - ${drawerWidth}px)`}, 
          ml: `${drawerWidth}px`, 
          backgroundColor: modeCol(mode).topBarBgColor, 
          boxShadow: 'none', 
          borderBottom: `1px solid ${modeCol(mode).borders}`,
        }}
      >
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
          <Box sx={{ display: 'flex' }}>
            <Box onClick={handleSidebarControll} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: { xs: 3, md: 0 }, cursor: 'pointer' }}>
              <MenuIcon color='primary' sx={{ display: { xs: 'none', sm: 'block', md: 'none' }}} />
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <Typography color={modeCol(mode).textColor} sx={{ marginLeft: '10px', display: { xs: 'none', md: 'block'} }}>
                Hey there,
              </Typography>
              <Box sx={{ display: 'flex' }}>
                {user && (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton component={Link} to={"/users/" + username}>
                      <UserAvatar width={30} height={30} username={user.username} imgUrl={user.profilePicUrl} />
                    </IconButton>
                  </Box>
                )}
                <Typography color={modeCol(mode).textColor} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold' }}>
                  {username}
                </Typography>
              </Box>
            </Box>
          </Box>

          {/* SEARCH START */}

          <Box sx={{ width: '50%', display: { xs: 'none', sm: 'block', md: 'block' } }} component="form">
            <TextField 
              size="small"
              fullWidth
              InputProps={{
                style: {
                  borderRadius: "30px",
                  backgroundColor: modeCol(mode).cardColor,
                  color: modeCol(mode).textColor
                }
              }}
              InputLabelProps={{
                style: {
                  color: modeCol(mode).textColor,
                }
              }}
              label={'Search for posts, users, twinlies, or spaces'}
              value={search}
              onChange={onSearch}
            />
          </Box>

        <HorizontalStack>
          
          <Box display={{ xs: 'block', sm: 'none', md: 'none'  }}>
            <IconButton onClick={handleSearchIcon}>
              <AiOutlineSearch color={modeCol(mode).textColor} />
            </IconButton>
          </Box>

          {/* SEARCH END */}

          {/* Notification */}
          
          { user && (
            <ClickAwayListener onClickAway={closeNotifications} disableReactTree={true}>
            <div>
            <Box position={"relative"}>
              <Badge badgeContent={unseenNotifications} color="secondary">
                <IconButton id="icon-notifications" onClick={toggleNotifications}>
                  <FaBell color={modeCol(mode).grayText} />
                </IconButton>
              </Badge>

              <Popover
                open={showNotifications} 
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >

                <NotificationsView 
                  onClickedNotification={onClickedNotification} 
                  onClickedClose={toggleNotifications}
                />
              </Popover>
            </Box>
            </div>
            </ClickAwayListener>
          )}
          {/* Notification END */}

          
          {/* FEEDBACK */}
          
            <Feedback />

          {/* FEEDBACK END */}

          

            {user ? (
              <Button onClick={handleLogout}>Logout</Button>
            ): (
              <Box sx={{ display: 'flex' }}>
                <Button variant="text" sx={{ minWidth: 80 }} href="/signup">Sign Up</Button>
                <Button variant="text" sx={{ minWidth: 65 }} href="/login">Login</Button>
              </Box>
            )}
          </HorizontalStack>

        </Toolbar>
      </AppBar>
  );
}
