import {
  Button,
  Card,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createPost } from "../api/posts";
import { createSpace } from "../api/spaces";
import ErrorAlert from "./ErrorAlert";
import { isLoggedIn } from "../helpers/authHelper";
import HorizontalStack from "./util/HorizontalStack";
import UserAvatar from "./UserAvatar";
import { modeCol } from "../functions/colors";
import { useGlobalContext } from "../context/context";

const SpaceEditor = () => {
  const { mode } = useGlobalContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });

  const [serverError, setServerError] = useState("");
  const [errors, setErrors] = useState({});
  const user = isLoggedIn();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    const errors = validate();
    setErrors(errors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    const data = await createSpace(formData, isLoggedIn());
    setLoading(false);
    if (data && data.error) {
      setServerError(data.error);
    } else {
      // navigate("/spaces/t/" + data._id);
      console.log("Space created");
    }
  };

  const validate = () => {
    const errors = {};

    return errors;
  };

  return (
    <Card sx={{ mt: 2, bgcolor: modeCol(mode).cardColor }}>
      <Stack spacing={1}>
        {user && (
          <Typography color={modeCol(mode).textColor} align="center" variant="h5">
            Create a new Game
          </Typography>
        )}

        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Name your game"
            required
            name="name"
            margin="normal"
            onChange={handleChange}
            error={errors.name !== undefined}
            helperText={errors.name}
            InputLabelProps={{
              style: { color: modeCol(mode).textColor },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: modeCol(mode).borders },
                '& .MuiInputLabel-root': { color: modeCol(mode).textColor },
                '&:hover fieldset': { borderColor: modeCol(mode).borders },
                color: modeCol(mode).textColor,
              },
            }}
          />
          <TextField
            fullWidth
            label="Description of your game"
            multiline
            rows={4}
            name="description"
            margin="normal"
            onChange={handleChange}
            error={errors.description !== undefined}
            helperText={errors.description}
            required
            InputLabelProps={{
              style: { color: modeCol(mode).textColor },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: modeCol(mode).borders },
                '& .MuiInputLabel-root': { color: modeCol(mode).textColor },
                '&:hover fieldset': { borderColor: modeCol(mode).borders },
                color: modeCol(mode).textColor,
              },
            }}
          />
          <ErrorAlert error={serverError} />
          <Button
            variant="outlined"
            type="submit"
            fullWidth
            disabled={loading}
            sx={{
              mt: 2,
            }}
          >
            {loading ? <>Submitting</> : <>Submit</>}
          </Button>
        </Box>
      </Stack>
    </Card>
  );
};

export default SpaceEditor;
