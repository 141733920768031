import Loading from "./Loading";
import ErrorAlert from "./ErrorAlert";
import { FaCircle } from "react-icons/fa";
import { FaRegCircle } from "react-icons/fa";
import MainGridLayout from './MainGridLayout';
import { signup, getUser } from "../api/users";
import { getSuperbasicFields } from "../api/misc";
import React, { useEffect, useState } from "react";
import { isLoggedIn } from "../helpers/authHelper";
import HorizontalStack from "./util/HorizontalStack";
import { createBotPersonality, testGCloudAPI, updateBotPersonality } from "../api/bot_behavior";
import { updateBot } from "../api/users";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Box, Card, Stack, Typography, TextField, Snackbar } from "@mui/material";
import { modeCol } from "../functions/colors";
import { useGlobalContext } from "../context/context";
import UserAvatar from "./UserAvatar";
import { GCLOUD_PUBLIC_BUCKET_URL } from "../config";
import { uploadTwinlyPic } from "../api/users";
import CircularCrop from "./CircularCrop";
import { GoHubot } from "react-icons/go";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { isLength, contains } from "validator";
import { uploadUsageMetric } from "../api/users";



const CreateTab = (props) => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const { mode } = useGlobalContext();
  const navigate = useNavigate();
  const [tabId, setTabId] = useState(0);
  const [formData, setFormData] = useState({"Bot type": "bot"});
  const [error, setError] = useState("");

  const user = isLoggedIn();
  const [displayPicUrl, setDisplayPicUrl] = useState(null);
  const [isEditingImage, setEditingImage] = useState(false);


  const [bottype, setBotType] = useState('bot');

  // UseEffect set form qAndA if editing is true
  useEffect(() => {
    
    if (props.state.edit == true && props.state.existingPersonalityDetails){
      const qAndA = props.state.existingPersonalityDetails.qAndA;
      setFormData(qAndA);
      setBotType(qAndA["Bot type"]);

    }
  }, []);

  const handleCloseAlert = () => {
    setAlertOpen(false)
  }
  // Toggle button
  const handleToggleChange = (event, newType) => {
    setBotType(newType);
    setFormData({ ...formData, "Bot type": newType })
  };

  // Uploading image
  const clickedUpload = () => {
      setEditingImage(true);
  }
  const imageEditingCancelled = () => {
      setEditingImage(false);
  };

  const imageEditingCompleted = async (imageData) => {
      const imgData = await imageData;
      console.log(`base64 string length:`);
      console.log(imgData.length);
      setEditingImage(false);

      let jsonData = { 
        image: imgData, 
        userId: user.userId
      };

      try {
        const {twinlyPicUrl} = await uploadTwinlyPic(user, jsonData);
        console.log(twinlyPicUrl);
      //   setUser({...user, profilePicUrl})
        // update user data in session 
        setDisplayPicUrl(twinlyPicUrl);


      } catch (err) {
        console.log(err);
      }
    }
  ////////////////////////////////////////////////


  const ProgressCircle = (props) => {
    if (props.id == tabId){
      return (<FaCircle fontSize={15} color={modeCol(mode).textColor} onClick={() => setTabId(props.id)}/>);
    }
    else{
      return (<FaRegCircle fontSize={15} color={modeCol(mode).textColor} onClick={() => setTabId(props.id)}/>);
    }
  };

  const isDisplayPicAndBotStatusScreen = () => {
    return tabId === Object.keys(props.twinlyFields).length
  };

  const isFinalScreen = () => {
    return tabId === Object.keys(props.twinlyFields).length + 1
  };

  const handleSubmit = async() => {
    uploadUsageMetric(user.userId, "Submitted twinly type in Create page. Type: " + props.state.type);
    formData["type"] = props.state.type;
    try{
      const botName = formData["Username"];
      if (props.state.edit == true){
        const botId = props.state.existingPersonalityDetails.botUser; 
        const botData = {
          botId: botId,
          username: botName,
          email: botName + "@bot.mytwinly.ai",
          password: botName + "msQ3NL0u",
          profilePicUrl: displayPicUrl,
          type: formData["Bot type"],
        };
        const updatedBot = await updateBot(user, botData);
        const updatedBotPersonality = await updateBotPersonality(botId, formData);

        const bot_data = {"username": formData["Username"], "_id": botId, "profilePicUrl": displayPicUrl};
        navigate("/create/success", { state: { user: bot_data } });

      }
      else{
        const existingUser = await getUser({id: botName});

        if (existingUser.error){
          const botData = {
            username: botName,
            email: botName + "@bot.mytwinly.ai",
            password: botName + "msQ3NL0u",
            biography: user.username + "'s Twinly",
            isBot: true,
            creator: user.userId,
            profilePicUrl: displayPicUrl,
            type: bottype,
          };
          const new_bot_data = await signup(botData);
          if (new_bot_data.error){
            setError(new_bot_data.error);
          }
          else{
            const botPersonality = await createBotPersonality(new_bot_data.userId, formData);
            const bot_data = {"username": new_bot_data.username, "_id": new_bot_data.userId, "profilePicUrl": new_bot_data.profilePicUrl};

            navigate("/create/success", { state: { user: bot_data } });
          }
        } else{
          setError("Username already exists");
        }

      }
    } catch(err){
      navigate("/create/error");
    }
  };

  const getValue = (question) => {
    if(question in formData){
      return formData[question];
    }
    else{
      return "";
    }
  };

  return (
    <Card sx={{ bgcolor: modeCol(mode).cardColor }}>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
        message={alertMessage}
        action={
          <Button color="secondary" size="small" onClick={handleCloseAlert}>
              Close
          </Button>
        }
      />

      <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
      
        {
        isFinalScreen() ? (
          <Stack> 
            <Typography sx={{ fontSize: { xs: 20, md: 25 } }} color={modeCol(mode).textColor} style={{padding:20}} variant="h4" textAlign="center"><b>Everything looks good?</b></Typography>
            { Object.keys(formData).map( (k)=>{
              return (
              <HorizontalStack sx={{padding: "10px", maxWidth: "50vw"}} spacing={2} >
                  <Typography color={modeCol(mode).textColor}>{k}:</Typography>
                  <Typography color={modeCol(mode).textColor} style={{pt:10}} variant="h6" textAlign="center" >{formData[k]}</Typography>
                </HorizontalStack>
              )
            }) }
          </Stack>
          ) : isDisplayPicAndBotStatusScreen() ? (
            <Stack> 
            <Typography color={modeCol(mode).textColor} style={{padding:20}} variant="h4" textAlign="center"><b>Finishing up</b></Typography>
            <Box sx={{ display: 'grid', gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }, margin: '30px 0' }}>

                <Box sx={{ display: 'grid', gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)',  md: 'repeat(1, 1fr)' }, border: '1px solid #1976D2', borderRadius: 4, margin: '8px 8px' }} p={2}>

                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                        {displayPicUrl ? (<UserAvatar 
                                    width={150} 
                                    height={150} 
                                    imgUrl={displayPicUrl} />)
                        : (
                            <Box sx={{ width: '200px', height: '200px', borderRadius: '50%', overflow: 'hidden' }} >
                                <Box 
                                    sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f0f0f0' }} 
                                >
                                    <Box><GoHubot fontSize={100} color='#1976D2' /></Box>
                                </Box>
                            </Box>
                        )}

                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '20px', position: 'relative', marginTop: {xs: '15px', md: '0'} }}>
                        
                        <Button 
                            type="file" 
                            id="upload-button" 
                            onClick={clickedUpload} 
                            style={{ 
                                position: 'absolute', 
                                top: 0, 
                                left: 0, 
                                opacity: 0, 
                                width: '100%', 
                                height: '100%', 
                                cursor: 'pointer' 
                            }}
                        />
                        <label 
                            htmlFor="upload-button" 
                            style={{ 
                                cursor: 'pointer', 
                                padding: '10px 20px', 
                                backgroundColor: '#1976D2', 
                                color: '#ffffff', 
                                borderRadius: '5px',
                                textAlign: 'center',
                            }}
                        >
                            Upload a display pic for your twinly
                        </label>
                    </Box>

                </Box>

                <Box sx={{ border: '1px solid #1976D2', borderRadius: 4, margin: '8px 8px' }} p={3}>
                  <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', spacing:2}}>
                    <Typography color={modeCol(mode).textColor} variant="h6" gutterBottom>
                        Select bot type:
                    </Typography>
                    
                    <Stack justifyContent="center" alignItems="center" spacing={2}>
                      <ToggleButtonGroup
                        color="primary"
                        value={bottype}
                        exclusive
                        onChange={handleToggleChange}
                        aria-label="Platform"
                      >
                        <ToggleButton sx={{ color: modeCol(mode).textColor }} value="bot">Bot</ToggleButton>
                        <ToggleButton sx={{ color: modeCol(mode).textColor }} value="bot-human">Bot-Human</ToggleButton>
                      </ToggleButtonGroup>

                      {
                        bottype=="bot" ? (
                          // <Typography sx={{ color: modeCol(mode).textColor }} textAlign="center">A Bot is an individual whose existence is separate from you. You will not be able to view its chats or edit its configuration after it is created, but it will continue to grow and evolve in a way that is true to its character.</Typography>
                          <Typography sx={{ color: modeCol(mode).textColor }} textAlign="center">A Bot is an individual whose existence is separate from you. You will not be able to view its chats or reply on its behalf after it is created, but you will be able to edit it. Use this type to create a fictional or real-life character that is not you.</Typography>
                        ) : (
                          <Typography sx={{ color: modeCol(mode).textColor }} textAlign="center">A Bot-Human is an individual that can think and act on its own but you will be able to monitor its interactions and influence its behavior even after it is created. Use this type to create a character that lives as an extension of you.</Typography>
                        )
                      }
                      

                    </Stack>
                      

                  </div>


                </Box>

            </Box>
          </Stack>
          ) : (
            props.twinlyFields[tabId.toString()].map(function(entry, i) {
              if (entry[1] === "Typography-header") {
                  return <Typography sx={{ fontSize: { xs: 18, md: 25 } }} color={modeCol(mode).textColor} style={{padding:20}} variant="h4" textAlign="center"><b>{entry[0]}</b></Typography>
              }
              else if (entry[1] === "Typography") {
                  return <Typography sx={{ fontSize: { xs: 15, sm: 18, md: 25 } }} color={modeCol(mode).textColor} style={{pt:10}} variant="h6" textAlign="center" >{entry[0]}</Typography>
              }
              else if (entry[1] === "TextField" || entry[1] === "TextField-multiline") {
                  const isMultiline = entry[1] === "TextField-multiline";
                  return (
                      <TextField 
                          id={entry[0]}
                          align="center" 
                          label={entry[0]} 
                          multiline={isMultiline} 
                          rows={isMultiline ? 4 : 1}
                          variant="outlined" 
                          value={getValue(entry[0])} 
                          onChange={(e) => setFormData({ ...formData, [e.target.id]: e.target.value })}
                          InputLabelProps={{
                            style: { color: modeCol(mode).textColor },
                          }}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': { borderColor: modeCol(mode).borders },
                              '& .MuiInputLabel-root': { color: modeCol(mode).textColor },
                              '&:hover fieldset': { borderColor: modeCol(mode).borders },
                              color: modeCol(mode).textColor,
                            },
                            width: { xs: '100%', sm: '90%', md: '70%' }
                          }} 
                      />
                  ); 
              } 
          })
        )}
  
      </Stack>

      {error!="" && (
          <ErrorAlert error={error} sx={{ my: 4 }} />
        )}

      <Stack sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '25px 10px' }}>
        <HorizontalStack>
          {Object.keys({...props.twinlyFields, "displayPic&botStatus": "","confirm": ""}).map(function(entry, i){
            return (<ProgressCircle id={i} />);
          })
          }
        </HorizontalStack>
      </Stack>

      <Stack style={{padding:30}} direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
      
        <Button style={{ marginRight: "auto"}} disabled={tabId==0} variant="contained" onClick={()=>setTabId(tabId-1)}>Prev</Button>
        
        {tabId==0 || !isFinalScreen() ? (
        <Button
          style={{ marginLeft: "auto" }}
          variant="contained"
          color="primary"
          onClick={async () => {
            const username = formData["Username"];
            if (username.length < 6) {
              setAlertMessage("Username should be at least 6 characters.");
              setAlertOpen(true);
              return;
            }
            else if (username.length > 30) {
              setAlertMessage("Username should be at most 30 characters.");
              setAlertOpen(true);
              return;
            }
            else if (contains(username, " ")) {
              setAlertMessage("Username should not contain any spaces.");
              setAlertOpen(true);
              return;
            }
            try {
              const existingUser = await getUser({ id: username });
              // I loved this Part! Thank you bro :) (@Vish)
              // You're welcome, bro (@Fazliddin)
              const regex1 = /muhammad/i;
              const regex2 = /mohamed/i;
              const regex3 = /mohammed/i;
              const regex4 = /mohammad/i;
              const regex5 = /muhammad/i;
              if (props.state.edit == true && existingUser && existingUser.user && existingUser.user.username == username) {
                console.log("Using the same username as before");
                setTabId(tabId + 1);
              }
              else if (!existingUser.error) {
                setAlertMessage("Username already exists.");
                setAlertOpen(true);
              } else if (regex1.test(username) || regex2.test(username) || regex3.test(username) || regex4.test(username) || regex5.test(username)) {
                setAlertMessage("You are not allowed to create Twinly by the name of Muhammad!\nPlease choose a different username.");
                setAlertOpen(true);
              } else {
                setTabId(tabId + 1);
              }
            } catch (error) {
              console.error("Error checking username:", error);
              setAlertMessage(error);
              setAlertOpen(true);
            }
          }}
        >
          Next
        </Button>

        ): (
          <Button style={{ marginLeft: "auto"}} variant="contained" color="primary" onClick={handleSubmit}>Submit</Button>
        )}

        { isEditingImage && 
            <CircularCrop 
                defaultImage={user.profilePicUrl ? GCLOUD_PUBLIC_BUCKET_URL + user.profilePicUrl : null}
                cropSize={{width: 200, height: 200}}
                onCropCancelled={imageEditingCancelled}
                onCropComplete={imageEditingCompleted}
            />
        }

      </Stack>

    </Card>
  );
};


const CreateQuestions = () => {
  const { state } = useLocation();
  const user = isLoggedIn();

  const [twinlyFields, setTwinlyFields] = useState(null);

  const retrieveFields = async() => {
    console.log("state is ");
    console.log(state);
    const titles_and_questions = await getSuperbasicFields({id:state.type});
    setTwinlyFields(titles_and_questions);

  };

  useEffect(() => {
    const fetchData = async () => {
      const status = await testGCloudAPI();
      console.log(status);
      return status;
    }
  
    try{

      uploadUsageMetric(user.userId, "Clicked on twinly type in Create page. Type: " + state.type);


      console.log("API pinged");
      const status = fetchData();
      console.log(status);
    
    } catch (err) {
        console.log(JSON.stringify(err));
    }
    retrieveFields();
  }, []);

  if(!twinlyFields){
    return (
      <MainGridLayout left={<Loading />}/>
    );
  }
  else{
    return (
      <MainGridLayout left={<CreateTab state={state} twinlyFields={twinlyFields} />}/>
    );
  }
  
};

export default CreateQuestions;
