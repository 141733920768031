import { categories } from '../../data/data';
import React, { useState } from 'react';
import EnterBottom from '../EnterBottom';
import EnterTopTitle from '../EnterTopTitle';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../context/context';
import { Button, Card, CardMedia, Container, Stack, Typography, Box, Snackbar } from '@mui/material';


const SingleCardInterest = ({ props, selected, onSelect }) => {

  const handleClick = () => {
    onSelect(props.id);
  };

  return (
    <Card
      key={props.id}
      sx={{
        width: { md: 180, xs: 150 },
        margin: '10px auto',
        cursor: 'pointer',
        backgroundColor: selected ? '#6fb7ff' : 'transparent',
        color: selected ? '#FFFFFF' : 'inherit',
      }}
      onClick={handleClick}
    >
      <CardMedia
        sx={{ height: 100 }}
        image={props.img}
        title={props.title}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography sx={{ fontWeight: 600 }} textAlign='center'>
              {props.title}
          </Typography>
      </Box>
    </Card>
  );
};

const InterestView = () => {

  // Global
  const { selectedInterests, path, setPath, setSelectedInterests } = useGlobalContext();
  
  // Local
  const navigate = useNavigate();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  
  // NEXT FUNCTION

  const handleNext = (e) => {
    e.preventDefault()
    if (selectedInterests.length < 3) {
      setAlertMessage('Please select at least 3 categories.');
      setAlertOpen(true);
    } else {
      console.log('Selected interests:', selectedInterests);
      setPath({...path, interestDone: true})
      navigate('/subcategories')
    }
  };
  //   GO PREV FUNCTION
  
  const handlePrev = () => {
    navigate('/verify');
  };

  // SELECT LOGIC 

  const handleInterestSelect = (id) => {
      const index = selectedInterests.includes(id);
      
      if (!index) {
          if (selectedInterests.length < 7) {
              setSelectedInterests([...selectedInterests, id]);
          } else {
              setAlertMessage('You can select maximum 7 categories!');
              setAlertOpen(true);
          }
      } else {
          setSelectedInterests(selectedInterests.filter((interest) => interest !== id));
      }
  };

  // CLOSE ALERT

  const handleCloseAlert = () => {
    setAlertOpen(false);
  };
  
  return (
    <Container maxWidth="xl" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', minHeight: '100vh' }}>

      {/* TOP */}

      <Box mt={5}>
        <EnterTopTitle 
          title='TwinlyAI'
          textOne='Choose Your Interests'
          textTwo='(Select between 3-7)'
        />
      </Box>

      {/* MIDDLE */}

      <Box sx={{ display: 'grid', gridTemplateColumns: { xs: 'repeat(2, 1fr)', sm: 'repeat(3, 1fr)', md: 'repeat(5, 1fr)' }, margin: '50px 0' }}>
        {categories.map((category) => (
          <SingleCardInterest
            key={category.id}
            props={category}
            selected={selectedInterests.includes(category.id)}
            onSelect={handleInterestSelect}
          />
        ))}
      </Box>

      {/* BOTTOM */}

      {/* <EnterBottom leftText='prev' rightText='next' leftFunc={handlePrev} rightFunc={handleNext} /> */}
      <Stack direction="row" spacing={2} sx={{ marginBottom: 2, display: 'flex', justifyContent: 'end' }}>
        <Button variant="contained" onClick={handleNext} sx={{ minWidth: '40%', height: '50px' }}>Next</Button>
      </Stack>
      {/* ALERT MESSAGE */}

      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
        message={alertMessage}
        action={
          <Button color="secondary" size="small" onClick={handleCloseAlert}>
              Close
          </Button>
        }
      />
      
    </Container>
  );
};

export default InterestView;
