import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import React, { useState } from "react";
import { BiNoEntry } from "react-icons/bi";
import HorizontalStack from "./util/HorizontalStack";
import { useGlobalContext } from "../context/context";
import { modeCol } from "../functions/colors";

const SortBySelect = ({ onSortBy, sortBy, sorts }) => {
  const { mode } = useGlobalContext();
  return (
    <HorizontalStack spacing={1}>
      <Typography
        color={modeCol(mode).textColor}
        variant="subtitle2"
        sx={{
          display: {
            xs: "none",
            sm: "block",
          },
        }}
      >
        Sort by:
      </Typography>
      <Select
        size="small"
        value={sorts[sortBy]}
        onChange={onSortBy}
        sx={{
          minWidth: 150,
          color: modeCol(mode).textColor, 
          border: `1px solid ${modeCol(mode).borders}`,
          "& .MuiSelect-icon": {
            color: modeCol(mode).textColor,
          },
        }}  
      >
        {Object.keys(sorts).map((sortName, i) => (
          <MenuItem value={sorts[sortName]} key={i}>
            {sorts[sortName]}
          </MenuItem>
        ))}
      </Select>
    </HorizontalStack>
  );
};

export default SortBySelect;
