import { useTheme } from "@emotion/react";
import {
  Avatar,
  Button,
  Card,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { AiFillEdit } from "react-icons/ai";
import { isLoggedIn } from "../helpers/authHelper";
import ContentUpdateEditor from "./ContentUpdateEditor";
import Footer from "./Footer";
import Loading from "./Loading";
import UserAvatar from "./UserAvatar";
import HorizontalStack from "./util/HorizontalStack";

import { GCLOUD_PUBLIC_BUCKET_URL } from "../config";
import { uploadProfilePic } from "../api/users";
import CircularCrop from "./CircularCrop";
import { modeCol } from "../functions/colors";
import { useGlobalContext } from "../context/context";
import { UserNameAndIcon } from "./UserTypeIcon";

const Profile = (props) => {
  const { mode } = useGlobalContext();
  const [user, setUser] = useState(null);
  const [isEditingImage, setEditingImage] = useState(false);
  const currentUser = isLoggedIn();
  const theme = useTheme();
  const iconColor = theme.palette.primary.main;

  const clickedAvatar = () => {
    if (currentUser.userId === user._id) {
      setEditingImage(true);
    }
  }
  const imageEditingCancelled = () => {
    setEditingImage(false);
  };
  
  const imageEditingCompleted = async (imageData) => {
    const imgData = await imageData
    // console.log(`base64 string length:`)
    // console.log(imgData.length)
    setEditingImage(false);

    let jsonData = { 
      image: imgData, 
      userId: user._id
    };

    try {
      const {profilePicUrl} = await uploadProfilePic(currentUser, jsonData)
      setUser({...user, profilePicUrl})
      // update user data in session 
      currentUser.profilePicUrl = profilePicUrl
      localStorage.setItem("user", JSON.stringify(currentUser));

    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (props.profile) {
      setUser(props.profile.user);
    }
  }, [props.profile]);

  return (
    <Card sx={{ bgcolor: modeCol(mode).cardColor, display: { xs: 'none', md: 'block' } }}>
      {user ? (
        <Stack alignItems="center" spacing={2}>
          <Box my={1} onClick={clickedAvatar}>
            <UserAvatar 
              width={150}
              height={150} 
              username={user.username} 
              imgUrl={user.profilePicUrl} />
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography color={modeCol(mode).textColor} variant="h5">{user.username}</Typography>
            <UserNameAndIcon type={user.type} />
          </Box>

          { isEditingImage && 
            <CircularCrop 
              defaultImage={user.profilePicUrl ? GCLOUD_PUBLIC_BUCKET_URL + user.profilePicUrl : null}
              cropSize={{width: 200, height: 200}}
              onCropCancelled={imageEditingCancelled}
              onCropComplete={imageEditingCompleted}
            />
          }

          {props.editing ? (
            <Box>
              <ContentUpdateEditor
                handleSubmit={props.handleSubmit}
                originalContent={user.biography}
                validate={props.validate}
              />
            </Box>
          ) : user.biography ? (
            <Typography color={modeCol(mode).textColor} textAlign="center" variant="p">
              <b>Bio: </b>
              {user.biography}
            </Typography>
          ) : (
            <Typography color={modeCol(mode).textColor} variant="p">
              <i>No bio yet</i>
            </Typography>
          )}

          {currentUser && user._id === currentUser.userId && (
            <Box>
              <Button
                startIcon={<AiFillEdit color={iconColor} />}
                onClick={props.handleEditing}
              >
                {props.editing ? <>Cancel</> : <>Edit bio</>}
              </Button>
            </Box>
          )}

          {currentUser && user.creator === currentUser.userId && (
            <Box>
              <Button
                startIcon={<AiFillEdit color={iconColor} />}
                onClick={props.handleEditBot}
              >
                <>Edit This Twinly</>
              </Button>
            </Box>
          )}

          {currentUser && user._id !== currentUser.userId && (
            <Button variant="outlined" onClick={props.handleMessage}>
              Message
            </Button>
          )}

          <HorizontalStack>

            {user.type == "human" ? (
              <>
                <Typography color={modeCol(mode).grayText}>
                  Likes <b>{props.profile.posts.likeCount}</b>
                </Typography>
                <Typography color={modeCol(mode).grayText}>
                  Posts <b>{props.profile.posts.count}</b>
                </Typography>
              </>
              ): (
              <>
                <Typography color={modeCol(mode).grayText}>
                  Comments: <b>100+</b>
                </Typography>
                <Typography color={modeCol(mode).grayText}>
                  Messages: <b>2k+</b>
                </Typography>
              </>

              )
  
            }

            
          </HorizontalStack>
        </Stack>
      ) : (
        <Loading label="Loading profile" />
      )}
    </Card>
  );
};

export default Profile;
