import UserAvatar from "../UserAvatar";
import React, { useState } from "react";
import MessageMarkdown from "./MessageMarkdown";
import { modeCol } from "../../functions/colors";
import { formatTime } from "../../functions/time";
import HorizontalStack from "../util/HorizontalStack";
import { copyToClipboard } from "../../functions/copy";
import { useGlobalContext } from "../../context/context";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Card, IconButton, Typography, Box } from "@mui/material";
import { cardStyle, copyIcon, copyTypo, getMessageStyles, timeText } from "./Styles";

const Message = (props) => {
  // Global
  const { mode, user } = useGlobalContext();

  // Local
  const message = props.message;
  const username = props.conservant.username;
  const imgUrl = props.conservant.profilePicUrl;
  let profileDisplayPic = props.profileDisplayPic;
  const [isCopied, setIsCopied] = useState(false);

  if (!profileDisplayPic){profileDisplayPic = "https://robohash.org/"+ props.profileUsername}
  if (props.profileId == user.userId){profileDisplayPic = user.profilePicUrl}

  const renderCopyButton = () => {
    if (message.direction === "to") {
      return (
        <>
          {isCopied ? (
            <Typography variant="body2" sx={copyTypo()}>
              Copied
            </Typography>
          ) : (
            <IconButton
              aria-label="copy"
              sx={copyIcon()}
              onClick={() => copyToClipboard(message.content, setIsCopied)}
            >
              <ContentCopyIcon fontSize="inherit" />
            </IconButton>
          )}
        </>
      );
    }
    return null;
  };

  return (
    <HorizontalStack
      sx={getMessageStyles(message.direction, mode)}
      spacing={2}
      alignItems="flex-end"
    >
      {message.direction === "to" && (
        <UserAvatar username={username} height={30} width={30} imgUrl={imgUrl} />
      )}

      <Box sx={{ maxWidth: "70%" }}>
        <Card sx={cardStyle(message.direction, mode)}>
          {renderCopyButton()}
          <MessageMarkdown message={message} />
        </Card>
        <Typography color={modeCol(mode).grayText} sx={timeText(message.direction)}>
          {formatTime(message.createdAt)}
        </Typography>
      </Box>

      {message.direction === "from" && (
        <UserAvatar username={username} height={30} width={30} imgUrl={profileDisplayPic} />
      )}
      
    </HorizontalStack>
  );
};

export default Message;