import React, { useState } from 'react'
import SearchResults from './SearchResults';
import { Card, Stack, Tab, Tabs } from '@mui/material';
import { modeCol } from '../functions/colors';
import { useGlobalContext } from '../context/context';

const tabs = {
  all: (
    <SearchResults
      contentType="all"
      key="all"
    />
  ),
  twinlies: (
    <SearchResults
      contentType="twinlies"
      key="twinlies"
    />
  ),
  users: (
    <SearchResults
      contentType="users"
      key="users"
    />
  ),
  posts: (
    <SearchResults
      contentType="posts"
      key="posts"
    />
  ),
  // spaces: (
  //   <SearchResults
  //     contentType="spaces"
  //     key="spaces"
  //   />
  // )
};


const SearchTabs = (props) => {
  const { mode } = useGlobalContext();
  const handleChange = (e, newValue) => {
    props.setTab(newValue);
  };

  return (
    <Card sx={{ padding: 0, border:0 }}>
      <Tabs sx={{ gap:10000, bgcolor: modeCol(mode).containerColor }} value={props.tab} onChange={handleChange} variant="scrollable">
        <Tab sx={{border: `1px solid ${modeCol(mode).borders}`, borderRadius: '20px', margin: '10px 10px', color: modeCol(mode).textColor }} label="All" value="all" />
        <Tab sx={{border: `1px solid ${modeCol(mode).borders}`, borderRadius: '20px', margin: '10px 10px', color: modeCol(mode).textColor }} label="Twinlies" value="twinlies" />
        <Tab sx={{border: `1px solid ${modeCol(mode).borders}`, borderRadius: '20px', margin: '10px 10px', color: modeCol(mode).textColor }} label="Users" value="users" />
        <Tab sx={{border: `1px solid ${modeCol(mode).borders}`, borderRadius: '20px', margin: '10px 10px', color: modeCol(mode).textColor }} label="Posts" value="posts" />
        {/* <Tab sx={{border: `1px solid ${modeCol(mode).borders}`, borderRadius: '20px', margin: '10px 10px', color: modeCol(mode).textColor }} label="Spaces" value="spaces" /> */}
      </Tabs>
    </Card>
  );
};

const SearchContainer = () => {
    const [tab, setTab] = useState("all");
    return (
      <Stack spacing={2}>
          <SearchTabs tab={tab} setTab={setTab} />
          {tabs[tab]}
      </Stack>
    )
}

export default SearchContainer