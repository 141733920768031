import React, { useState } from 'react';
import { Button, Container, Stack, Typography, Box } from '@mui/material';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { useGlobalContext } from '../../context/context';
import EnterTopTitle from '../EnterTopTitle';
import ErrorAlert from '../ErrorAlert';
import { verifyEmail } from '../../api/users';
import { updateLocalUser} from '../../helpers/authHelper';
import { isSemVer } from 'validator';

export const MuiOtpInputStyled = styled(MuiOtpInput)`
  gap: 20px;
  max-width: 450px;
  margin-inline: auto;
`

const VerifyView = () => {

    // Global
    const { formData, path, setPath } = useGlobalContext();

    // Locals
    const [verificationCode, setVerificationCode] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleNext = async (e) => {
      e.preventDefault()
      try {
        const verified = await verifyEmail(formData.email, verificationCode);
        setPath({ ...path, verifyDone: true });
        // update verified user in local storage
        updateLocalUser({isVerified: true});
        navigate('/interest');
      } catch (err) {
        setError(err.message);
        console.log(err);
      }
    };

    const handlePrev = () => {
      navigate('/signup');
    };

    const handleChange = (newValue) => {
      setVerificationCode(newValue);
    };

    return (
      <Container sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>

          {/* TOP */}

          <EnterTopTitle 
            title='TwinlyAI' 
            textOne='We sent you a code' 
            textTwo={`Enter it below to verify ${formData.email}`}
          />

          <Stack alignItems="center" spacing={2} sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <MuiOtpInputStyled 
                    length={6}
                    autoFocus
                    value={verificationCode} 
                    onChange={handleChange} 
                  />
              </Box>
          </Stack>

          <ErrorAlert error={error} />
          <Stack direction="row" spacing={2} sx={{ marginBottom: 2, display: 'flex', justifyContent: 'space-between' }}>
              <Button variant="contained" onClick={handlePrev} sx={{ minWidth: '40%', height: '50px' }}>Prev</Button>
              <Button disabled={verificationCode.length !== 6} variant="contained" onClick={handleNext} sx={{ minWidth: '40%', height: '50px' }}>Next</Button>
          </Stack>

      </Container>
    );
};

export default VerifyView;
