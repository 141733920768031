import { Stack, Typography } from '@mui/material'
import React from 'react'

const EnterTopTitle = ({title, textOne, textTwo}) => {
    return (
        <Stack alignItems="center" spacing={2}>
            <Typography variant="h3" sx={{ color: '#1976D2' }}>
                {title}
            </Typography>
            <Typography variant="h5" gutterBottom>
              {textOne}
            </Typography>
            {textTwo && (
                <Typography color="text.secondary">
                  {textTwo}
                </Typography>
            )}
        </Stack>
    )
}

export default EnterTopTitle
