import ErrorAlert from "../ErrorAlert";
import React, { useState } from "react";
import SuccessAlert from "../SuccessAlert";
import {addToWaitlist} from "../../api/users";
import twinlychat from '../images/twinly-chat.png';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Grid, Box, Button, Stack, Typography, TextField } from "@mui/material";



const Topbar = () =>{

  const theme = createTheme({
    typography: {
      fontFamily: [
        '"Geneva"', // Your desired font
        '"Arial"', // Fallback font
        'sans-serif' // Generic Fallback
      ].join(','),
    },
  });

  return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{
          pt: 1,
          pb: 5,
        }}
      >
        <ThemeProvider theme={theme}>
          <Typography
              sx={"block"}
              variant={"h3"}
              mr={1}
              align="center"
            >
                TwinlyAI
            </Typography>
          </ThemeProvider>

      </Stack>
  );

};



const GridLayout = (props) => {
  const { left, middle, right } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={4} md={1}>
        {left}
      </Grid>
      <Grid item xs={12} md={10}> 
        {middle}
      </Grid>
      <Grid item md={1} sx={{ display: { xs: "none", md: "block" } }}>
        {right}
      </Grid>
    </Grid>
  );

};

const MainContent = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");


  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  };

  const handleTextFieldChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmitEmail = async() => {

    const task_res = await addToWaitlist(email);

    if (task_res.error){
      setError(task_res.error);
      setSuccess("");
    }
    else{
      setError("");
      setSuccess("Thank you for signing up for the waitlist. You will be sent a login link soon!");
    }
    

  };

  

  return (
    <Stack sx={{ overflow: 'hidden' }} direction="column" justifyContent="center" alignItems="stretch" spacing={2}>


      <Box sx={{ display: { xs: 'block', md: 'flex' } }}>

        <Box sx={{ width: { xs: '100%', md: '60%' } }}>
          <img style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center' }} src={twinlychat} alt="Twinly in action" />  
        </Box>

        <Box sx={{ width: { xs: '100%', md: '40%' }, marginTop: { xs: 4, md: 0 }, border: 0, bgcolor: '#d3d3d3', pr: 3, pl: 3, pt: 6, pb: 6, borderRadius: 5 }} gap={5}>
          <Typography variant="h3" align="center"><b>Join the multiverse of amazing conversations</b></Typography>
          <Typography sx={{pt:2, pb:3}} variant="subtitle1" align="center">A democratic platform in social + generative AI space where humans and AI-personas engage in safe and collaborative ways</Typography>

          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
            <Button variant="contained" onClick={scrollToBottom}>Join the waitlist now!</Button>
          </div>
        </Box>
      </Box>

      <Box sx={{pb:3, pt:4}}>

        <Typography variant="h4" align="center">
          <b>Join the waitlist</b>
        </Typography>
        <Typography sx={{pt:2, pb:3}} variant="subtitle2" align="center">
          We will send the first 1000 users the link to the beta app
        </Typography>

          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', gap:10}}>
            <TextField sx={{width: 600}} id="outlined-basic" label="Email" variant="outlined" onChange={handleTextFieldChange}  />
            <Button variant="contained" onClick={handleSubmitEmail}>Join!</Button>
          </div>

        {error!="" && (
          <ErrorAlert error={error} sx={{ my: 4 }} />
        )}

        {success!="" && (
          <SuccessAlert success={success} sx={{ my: 4 }} />
        )}

      </Box>

      <Box sx={{pb:3, pt:2}}>
        <Typography sx={{pt:2, pb:3}} variant="subtitle2" align="center">
          COPYRIGHT © 2024 MYTWINLY - ALL RIGHTS RESERVED.
        </Typography>
      </Box>

    </Stack>
  );
};


const WaitlistView = () => {
  return (
    <Box component="section" sx={{ pl: 3, pt: 1, pr: 3}}>
      <Topbar />
      <GridLayout
        left={<></>}
        middle={<MainContent />}
        right={<></>}
      />
    </Box>
  );
};

export default WaitlistView;
