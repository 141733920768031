import { Box, Card, Stack, Typography, Button, Avatar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getPosts } from "../api/posts";
import { isLoggedIn } from "../helpers/authHelper";
import Loading from "./Loading";
import PostCard from "./PostCard";
import HorizontalStack from "./util/HorizontalStack";
import "react-icons/md";
import { MdLeaderboard } from "react-icons/md";
import { useGlobalContext } from "../context/context";
import { modeCol } from "../functions/colors";
import styled from '@emotion/styled';
import { useNavigate } from "react-router-dom";
import { getRandomBots } from "../api/users";
import UserAvatar from "./UserAvatar";
import { default_bot_image } from "../data/data";
import { UserNameAndIcon } from "./UserTypeIcon";

const CardWithGradient = styled(Card)`
  background: rgb(230, 230, 250);
  background: linear-gradient(90deg, rgba(230, 230, 250, 1) 0%, rgba(106, 90, 205, 1) 50%, rgba(255, 255, 255, 1) 100%);
  border-radius: 10px;
  padding: 20px 0 0 0;
`;


const RoundBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 400px 400px 0 0 / 150px 150px 0 0;
  padding: 20px 0 0 0;
`;

const ContainerBox = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

const CreateButton = styled(Button)`
  width: 80%;
  border-color: #FFD700;
  border-radius: 20px;
  text-transform: none;
  font-size: 1rem;
  margin: 8px 0;
  border-width: 0.5px;
  background-color: transparent;
`;

const SecondRoundBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 400px 400px 0 0 / 150px 150px 0 0;
  padding: 20px 0 20px 0;
`;

const TopPosts = () => {

  const { mode } = useGlobalContext();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [bots, setBots] = useState(null);

  const fetchBots = async () => {
    setLoading(true);
    const data = await getRandomBots({ size: 5 });
    setBots(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchBots();
    console.log(bots)
  }, []);

  return (

    <Stack spacing={2}>

      <CardWithGradient sx={{ display: { xs: "none", md: "block" }, border: `1px solid ${modeCol(mode).borders}` }}>
        <RoundBox sx={{ backgroundColor: modeCol(mode).cardColor }}>
          <Typography fontWeight='bold' color={modeCol(mode).textColor} variant="h6">Home</Typography>
          <ContainerBox>
            <Typography fontSize='16px' color={modeCol(mode).grayText}>Your Twinly front page</Typography>
            <Typography fontSize='16px' color={modeCol(mode).grayText}>Personalize your space</Typography>
          </ContainerBox>
          <ContainerBox>
            <CreateButton onClick={() => navigate("/create")} color="warning" variant="outlined" sx={{ color: modeCol(mode).textColor }}>
              Create Twinly
            </CreateButton>
            <CreateButton onClick={() => navigate("/posts/create")} color="warning" variant="outlined" sx={{ color: modeCol(mode).textColor }}>
              Create Post
            </CreateButton>
          </ContainerBox>
        </RoundBox>
      </CardWithGradient>

      {/* DESKTOP */}

      <CardWithGradient sx={{ display: { xs: "none", md: "grid" }, border: `1px solid ${modeCol(mode).borders}`}}>
        <SecondRoundBox sx={{ backgroundColor: modeCol(mode).cardColor }}>
          <Typography fontWeight='bold' color={modeCol(mode).textColor} variant="h6">For you</Typography>
        </SecondRoundBox>
        {!loading ? (
          <>
            {
              bots &&
              bots.map((bot) => (
                <Card onClick={() => navigate("/messenger", { state: { user: bot } }) } sx={{ bgcolor: modeCol(mode).cardColor, borderRadius: 0, cursor: 'pointer' }}>
                  <HorizontalStack>
                    <UserAvatar
                      width={40} 
                      height={40} 
                      username={bot.username}
                      imgUrl={bot.profilePicUrl || default_bot_image}
                      sx={{top: "10px"}}
                    />
                    {/* <Typography fontWeight={600} color={modeCol(mode).textColor}>
                      {bot.username}
                    </Typography> */}
                    <UserNameAndIcon username={bot.username} type={bot.type} />
                  </HorizontalStack>
                </Card>
              ))
            }
            <Box onClick={() => navigate('/explore')} sx={{ backgroundColor: modeCol(mode).cardColor, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button sx={{ borderRadius: 0 }} variant="text">see more</Button>
            </Box>
          </>
        ) : (
          <Box sx={{ backgroundColor: modeCol(mode).cardColor }}>
            <Loading />
          </Box>
        )}
      </CardWithGradient>

      {/* NO NEED LATER SHOULD BE DELETED  */}
      
      {/* MOBILE */}

      {/* <Box sx={{ display: { xs: "block", md: "none" } }}>
        <HorizontalStack>
          <Typography color={modeCol(mode).textColor} fontWeight={600}>Popular Posts</Typography>
          <MdLeaderboard color={modeCol(mode).textColor} />
        </HorizontalStack>
      </Box> */}


      {/* <Box
        sx={{
          display: {
            xs: "",
            md: "none",
          },
          overflowX: "scroll", 
          overflowY: 'hidden',
          whiteSpace: "nowrap", 
          maxWidth: "100%",
        
          // SCROLL
          "&::-webkit-scrollbar": {
            width: '6px',
            height: '6px',
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#D9D9D9",
            borderRadius: "3px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: 'transparent',
            borderRadius: "3px",
          },
          "& scrollbarWidth": "thin", 
          "& scrollbarColor": "#888",
        }}
      >
        {!loading ? (
          posts &&
          posts.map((post) => (
            <PostCard preview="secondary" post={post} key={post._id} />
          ))
        ) : (
          <Box>
            <Loading />
          </Box>
        )}
      </Box> */}

    </Stack>
  );
};

export default TopPosts;
