import { BASE_URL } from "../config";

const testGCloudAPI = async () => {
  try {
    const res = await fetch(
      BASE_URL +
        "api/bot_behavior"
    );
    return await res.json();
  } catch (err) {
    console.log(err);
  }
};

const getBotRes = async (messages, twinly_bot_id) => {
  try {
    const res = await fetch(
      BASE_URL +
        "api/bot_behavior/getBotRes",
      {
        method: "POST",
        headers: {
          "Access-Control-Allow-Headers" : "Content-Type",
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "messages": messages,
          "twinly_bot_id": twinly_bot_id
        })
      }
    );
    const data = await res.json();    
    console.log(JSON.stringify(data, null, 2));
    return data;
  } catch (err) {
    console.log(err);
  }
};


const createBotPersonality = async (botUser, formData) => {
  try {
    const res = await fetch(
      BASE_URL +
        "api/bot_behavior/createbotpersonality",
      {
        method: "POST",
        headers: {
          "Access-Control-Allow-Headers" : "Content-Type",
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "botUser": botUser,
          "formData": formData,
        })
      }
    );
    const data = await res.json();    
    console.log(JSON.stringify(data, null, 2));
    return data;
  } catch (err) {
    console.log(err);
  }
};

const getBotPersonality = async (botUser) => {
  try {
    const res = await fetch(
      BASE_URL +
        "api/bot_behavior/getbotpersonality",
      {
        method: "POST",
        headers: {
          "Access-Control-Allow-Headers" : "Content-Type",
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "botUser": botUser,
        })
      }
    );
    const data = await res.json();
    return data;
  } catch (err) {
    console.log(err);
  }
};

const updateBotTagline = async (botId, token) => {
  try {
    const res = await fetch(
      BASE_URL +
      "api/bot_behavior/updatebotpersonality?"+ 
        new URLSearchParams({botId: botId}),
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    return await res.json();
  } catch (err) {
    console.log(err);
  }
};

const updateBotPersonality = async (botUser, formData) => {
  try {
    const res = await fetch(
      BASE_URL +
        "api/bot_behavior/updatebotpersonality",
      {
        method: "POST",
        headers: {
          "Access-Control-Allow-Headers" : "Content-Type",
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "botUser": botUser,
          "formData": formData,
        })
      }
    );
    const data = await res.json();    
    console.log(JSON.stringify(data, null, 2));
    return data;
  } catch (err) {
    console.log(err);
  }
};


const getBotsByCategory = async (query) => {
  try {
    const res = await fetch(
      BASE_URL +
        "api/bot_behavior/getbots?" + new URLSearchParams(query)
    );
    return await res.json();
  } catch (err) {
    console.log(err);
  }
};


export {
  testGCloudAPI,
  getBotRes,
  createBotPersonality,
  updateBotTagline,
  updateBotPersonality,
  getBotsByCategory,
  getBotPersonality,
};
