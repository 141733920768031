import { getProfileConvos } from "../../api/messages";
import { getUserCreatedBotHumans } from "../../api/users";

// =========================================================>
// ================== Important Functions ==================>
// =========================================================>

export const checkIfExists = (user, profileId, profileUsername, profileDisplayPic) => {
    if(!profileId) {profileId = user.userId}
    if(!profileUsername) {profileUsername = user.username}
    if(profileUsername === user.username){profileDisplayPic = "https://robohash.org/"+ profileUsername}
    return {profileId, profileUsername, profileDisplayPic}
}

export const getConversation = (conversations, conservantId) => {
    for (let i = 0; i < conversations.length; i++) {
      const conversation = conversations[i];
      if (conversation.recipient && conversation.recipient._id === conservantId) {
        return conversation;
      }
    }
};

export const handleItemClick = (id, setActiveItem, profiles, navigate) => {
    setActiveItem(id);
    const selectedProfile = profiles.find(profile => profile._id === id);
    if (selectedProfile) {
      navigate("/messenger", { state: { user: null, profileId: id, profileUsername: selectedProfile.username, profileDisplayPic: selectedProfile.profilePicUrl } });
    }
};

// =========================================================>
// ================== Fetch Messages =======================>
// =========================================================>

export const fetchConversations = async (user, newConservant, setConservant, setConversations, profileId, setLoading) => {
    let conversations = await getProfileConvos({profileId: profileId, token: user.token});
    if (newConservant) {
      // setConservant(newConservant);
      if (!getConversation(conversations, newConservant._id)) {
        const newConversation = {
          _id: newConservant._id,
          recipient: newConservant,
          new: true,
          messages: [],
        };
        conversations = [newConversation, ...conversations];
      }
    }
    setConversations(conversations);
    setLoading(false);
};

// =========================================================>
// ================== Fetch Bot-Humans =====================>
// =========================================================>

export const fetchBotHumans = async (user, setProfiles) => {
    const bothumans = await getUserCreatedBotHumans({ userid: user.userId });
    setProfiles([{_id: user.userId, username: user.username, profilePicUrl: user.profilePicUrl },...bothumans]);
};