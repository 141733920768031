import React from 'react';
import styled from '@emotion/styled';
import { useGlobalContext } from '../context/context';
import { Typography, Box, Drawer, List, ListItem, ListItemButton } from '@mui/material';

// ICONS
import { Link } from 'react-router-dom';
import ToggleSwitch from './ToggleSwitch';
import { getNavLinks } from '../data/data';
import { modeCol } from '../functions/colors';
import { isLoggedIn } from '../helpers/authHelper';

// Styled components
const MenuContainer = styled(Box)`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
`;

const Privacy = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const ToolbarContainer = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
`;

const TopLogo = styled(Typography)`
    font-size: 25px;
    font-weight: 700;
    color: #9595FE;
`;

const ItemIcon = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
`;

const StyledListItemButton = styled(ListItemButton)(({ active, brandColor, hover }) => ({
  backgroundColor: active ? brandColor : 'transparent',
  borderRadius: '15px',
  '&:hover': {
    backgroundColor: active ? brandColor : hover,
  },
}));

const MainMobileSidebar = ({ messengerPage }) => {
    const { sidebarControll, mode, handleSidebarControll, activeLink, user } = useGlobalContext();

    const activeColor = (active) => {
        return active ? '#fff' : modeCol(mode).brandColor
    }

    const navLinks = getNavLinks();

    return (
        <Drawer
            open={sidebarControll}
            onClose={handleSidebarControll}
            sx={{ display: { sm: 'block', md: messengerPage ? 'block' : 'none' } }}
        >
            <ToolbarContainer bgcolor={modeCol(mode).sidebarBgColor}>
                <TopLogo color={modeCol(mode).brandColor}>TwinlyAI</TopLogo>
            </ToolbarContainer>

            <MenuContainer bgcolor={modeCol(mode).sidebarBgColor}>
                {/* Navigation links */}
                
                <Box>
                    <List>
                        {navLinks.map((item) => {
                            const { id, name, link, icon, secure } = item;
                            const active = activeLink() === name
                            const to = (isLoggedIn() || !secure) ? link : '/login';
                            return (
                                <ListItem key={id}>
                                    <Link onClick={handleSidebarControll} to={to} style={{ textDecoration: 'none', width: '100%', color: 'inherit' }}>
                                        <StyledListItemButton active={active} brandColor={modeCol(mode).brandColor} hover={modeCol(mode).hoverColor} >
                                            <ItemIcon>
                                              {icon(activeColor(active), 25)}
                                            </ItemIcon>
                                            <Typography 
                                              color={active ? '#fff' : modeCol(mode).textColor}
                                              fontWeight='bold'
                                            >
                                              {name}
                                            </Typography>
                                        </StyledListItemButton>
                                    </Link>
                                </ListItem>
                            );
                        })}
                    </List>
                </Box>

                {/* Privacy section */}

                <Privacy>
                    <Typography color={modeCol(mode).grayText} fontSize={12}>Privacy</Typography>
                    <Typography color={modeCol(mode).grayText} fontSize={12}>Terms</Typography>
                    <Typography color={modeCol(mode).grayText} fontSize={12}>Community Guidelines</Typography>
                    <Typography color={modeCol(mode).grayText} fontSize={12}>© 2024 Twinly AI</Typography>
                </Privacy>

                {/* TOGGLE BUTTON */}
                <ToggleSwitch />

            </MenuContainer>
        </Drawer>
    );
};

export default MainMobileSidebar;
