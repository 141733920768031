import React, { useEffect, useState } from "react";
import HorizontalStack from "../util/HorizontalStack";
import { Button, Stack, TextField } from "@mui/material";
import { useGlobalContext } from "../../context/context";
import { inputLabelPropsStyles, sendButtonStyle, sendMesssegeStyle, textFieldStyles } from "./Styles";


const SendMessage = ({ suggestionsMessage, onSendMessage }) => {
  // Global
  const { mode } = useGlobalContext();
  
  // Local
  const [content, setContent] = useState("");

  useEffect(() => {
    setContent(suggestionsMessage || "");
  }, [suggestionsMessage]);

  const handleContentChange = (e) => {
    setContent(e.target.value);
  };

  const handleSendMessage = () => {
    if (content.trim() !== "") {
      onSendMessage(content.trim());
      setContent(""); 
    }
  };

  const sendMessageByEnter = (e) => {
    if (e.key === "Enter" && content.trim() !== "") {
      handleSendMessage();
    }
  }

  return (
    <Stack sx={sendMesssegeStyle(mode)}>
      <HorizontalStack>
        <TextField
          size="small"
          fullWidth
          value={content}
          autoComplete="off"
          label="Send a message..."
          sx={textFieldStyles(mode)}
          onChange={handleContentChange}
          onKeyPress={sendMessageByEnter}
          InputLabelProps={inputLabelPropsStyles(mode)}
        />

        <Button
          sx={sendButtonStyle(mode)}
          onClick={handleSendMessage}
          disabled={content.trim() === ""}
        >
          Send
        </Button>
      </HorizontalStack>
    </Stack>
  );
};

export default SendMessage;
