const react = require("react");
const { useState } = react;
const { TextField } = require("@mui/material");
const { InputAdornment } = require("@mui/material");
const { IconButton } = require("@mui/material");
const { Visibility, VisibilityOff } = require("@mui/icons-material");

const PasswordInput = (props) => {
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);

    return (
      <TextField
        label={props.label || "Password"}
        fullWidth
        required
        margin="normal"
        id={props.id || "password  "}
        name={props.name || "password"}
        onChange={props.onChange}
        type={showPassword ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
};
export default PasswordInput;