import React from 'react';
import { emptyStack } from './Styles';
import { AiFillMessage } from 'react-icons/ai';
import { modeCol } from '../../functions/colors';
import { Stack, Typography } from '@mui/material';
import { useGlobalContext } from '../../context/context';

const MessagesEmpty = () => {
    const { mode } = useGlobalContext();
    return (
      <Stack
        sx={emptyStack()}
        spacing={2}
      >
        <AiFillMessage color={modeCol(mode).brandColor} size={80} />
        <Typography color={modeCol(mode).textColor} variant="h5">Twinly Messenger</Typography>
        <Typography color={modeCol(mode).textColor}>
          Privately message other users on TwinlyAI
        </Typography>
      </Stack>
    )
}

export default MessagesEmpty