
export const Icon = ({ size }) => (
  <svg
    style={{
      width: size,
      height: size
    }}
    viewBox="0 0 84.373116 96.898254"
    version="1.1"
    id="svg1"
    // xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
  // xmlns:svg="http://www.w3.org/2000/svg"
  >
    <defs
      id="defs1" />
    <g
      id="layer1"
      transform="translate(-67.874918,-109.52916)"
    >
      <g
        id="g5"
        fill="#fd3132"
        transform="translate(3.5395068,-7.4722909)">
        <path
          // style="fill:#fd3132"
          d="m 64.350328,201.50908 -0.01492,-12.39064 7.167878,-7.18528 7.167878,-7.18529 19.344536,-0.1473 c 21.7083,-0.16529 22.76866,-0.10702 27.62511,1.51804 5.38741,1.80275 10.11767,4.8142 13.93379,8.87076 5.45264,5.79618 8.54231,12.97679 9.07389,21.08836 0.19896,3.03593 -0.061,4.13309 -1.31834,5.56521 -1.77216,2.01839 1.72961,1.85008 -42.95673,2.06466 l -40.008166,0.19211 z m 18.819103,-36.40401 c -0.825529,-0.19341 -1.547921,-0.65733 -2.550518,-1.63794 -1.158909,-1.1335 -1.449151,-1.60854 -1.69253,-2.77019 -0.408825,-1.95133 -0.40834,-37.06361 5.38e-4,-38.97014 0.234839,-1.09502 0.568908,-1.65347 1.653646,-2.76437 1.99431,-2.04241 2.047422,-2.0484 16.936479,-1.91071 12.358674,0.1143 12.491044,0.12128 14.999074,0.79158 8.17246,2.18417 14.46434,8.19399 17.00167,16.23949 2.43368,7.71686 0.87667,15.83618 -4.2759,22.29743 -3.02335,3.79124 -8.08159,7.00418 -13.00717,8.262 -2.13067,0.5441 -2.9239,0.57847 -15.081256,0.6535 -8.081986,0.0499 -13.258647,-0.0207 -13.984033,-0.19065 z m 24.302779,-12.26646 v -3.04271 h -3.24842 c -3.5586,0 -4.09127,0.17566 -4.822878,1.59042 -0.552375,1.06818 -0.493887,1.88896 0.20911,2.93452 0.807648,1.20119 1.895398,1.52762 5.150218,1.54554 l 2.71197,0.0149 z M 93.797421,139.4454 c 1.904468,-0.97653 3.044365,-3.08008 2.693912,-4.97132 -0.437101,-2.35884 -2.080918,-3.72818 -4.475467,-3.72818 -1.791254,0 -2.782928,0.45992 -3.7634,1.74538 -1.596693,2.09337 -0.928174,5.34618 1.365766,6.6454 1.161171,0.65765 3.204343,0.80858 4.179189,0.30872 z m -18.87067,6.66247 c -3.574159,-1.08053 -5.520005,-4.69674 -4.449633,-8.26933 0.444912,-1.48498 2.069162,-3.20909 3.67337,-3.8992 0.747245,-0.32146 1.527799,-0.51955 1.734564,-0.4402 0.313035,0.12012 0.364065,1.19307 0.304986,6.41267 -0.04415,3.90033 -0.170904,6.29262 -0.335533,6.33248 -0.145521,0.0352 -0.56301,-0.0262 -0.927754,-0.13642 z"
          id="path6" />
      </g>
      <g
        id="g6"
        fill="#025dfc"
        transform="translate(3.5395068,-7.4722909)"
      >
        <path
          // style="fill:#025dfc"
          d="m 107.5939,213.22976 c -0.0695,-0.1819 -0.0955,-8.96276 -0.0578,-19.51302 l 0.0684,-19.1823 4.61897,-0.0726 c 2.94573,-0.0463 5.69576,0.0677 7.59144,0.31468 12.30286,1.60305 23.15564,10.55226 27.06879,22.32096 1.01973,3.06679 1.55612,5.79631 1.76478,8.98028 0.19888,3.03468 -0.061,4.13318 -1.31608,5.56261 -1.75333,1.99694 -0.76273,1.90783 -21.28152,1.91434 -14.42513,0.005 -18.35761,-0.0647 -18.45699,-0.32492 z m -0.057,-71.96667 0.0676,-23.87865 1.04944,-0.0855 c 1.65799,-0.13513 5.71296,0.9356 8.26224,2.18166 7.74971,3.78798 12.60464,10.76016 13.58995,19.5166 0.87082,7.73885 -2.56089,15.91353 -8.77668,20.90701 -3.54544,2.84824 -9.4613,5.23048 -13.00188,5.2357 l -1.25818,0.002 z"
          id="path5"
        />
      </g>
    </g>
  </svg>
);

export default Icon;
