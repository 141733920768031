import React, { useEffect } from 'react';
import { Box, Stack } from '@mui/material';

import GoBack from '../GoBack';
import TopPosts from '../TopPosts';
import CreateTab from '../CreateTab';
import YourSpaces from '../YourSpaces';
import PostEditor from '../PostEditor';
import SpaceEditor from '../SpaceEditor';
import PostBrowser from "../PostBrowser";
import GoBackToSpaces from '../GoBackToSpaces';
import DiscoverSpaces from '../DiscoverSpaces';
import SpaceContainer from '../SpaceContainer';
import MainGridLayout from '../MainGridLayout';
import ErrorContainer from '../ErrorContainer';
import CreateQuestions from '../CreateQuestions';
import SearchContainer from '../SearchContainer';
import TwinlyContainer from '../TwinlyContainer';
import FantasyContainer from '../FantasyContainer';
import ProfileContainer from '../ProfileContainer';
import SuccessContainer from '../SuccessContainer';
import OnBoardingContainer from '../OnBoardingContainer';
import RecommendedTwinlies from '../RecommendedTwinlies';
import SinglePostContainer from '../SinglepostContainer';
import TwinlyBasicContainer from '../TwinlyBasicContainer';
import TwinlyBasicFantasyContainer from '../TwinlyBasicFantasyContainer';
import { testGCloudAPI } from "../../api/bot_behavior";
import { uploadUsageMetric } from "../../api/users";
import { MessengerContainer } from '../Messenger';
import { isLoggedIn } from "../../helpers/authHelper";


// HOME
export const HomePage = () => {
    const user = isLoggedIn();

    useEffect(() => {
        const fetchData = async () => {
            const status = await testGCloudAPI();
            console.log(status);
            return status;
          }
        
        uploadUsageMetric(user.userId, "In home page");

        try{
            console.log("API pinged");
            const status = fetchData();
            console.log(status);
        
        } catch (err) {
            console.log(JSON.stringify(err));
        }

      }, []);

    return (
        <MainGridLayout 
            left={<PostBrowser createPost contentType="posts" isHome={true} />}
            right={
                <Stack spacing={2}>
                    <TopPosts />
                </Stack>
            }
        />
    )
}

// SINGLEPOST
export const PostPage = () => {
    return <SinglePostContainer />
}

// CREATE POST
export const CreatePostPage = () => {
    const user = isLoggedIn();

    useEffect(() => {
        
        uploadUsageMetric(user.userId, "In create post page");

      }, []);
    return (
        <>
            <GoBack />
            <Box sx={{mt: 2}}>
                <MainGridLayout left={<PostEditor />} right={<Stack spacing={2}><TopPosts /></Stack>} />    
            </Box>
        </>
    )
}

// CREATE QUESTIONS
export const CreateQuestionsPage = () => {
    const user = isLoggedIn();

    useEffect(() => {
        
        uploadUsageMetric(user.userId, "In create questions page");

      }, []);
    return <CreateQuestions />
}

// CREATE SUCCESS
export const CreateSuccessPage = () => {
    const user = isLoggedIn();

    useEffect(() => {
        
        uploadUsageMetric(user.userId, "In create success page");

      }, []);
    return <SuccessContainer />
}

// CREATE ERROR
export const CreateErrorPage = () => {
    const user = isLoggedIn();

    useEffect(() => {
        
        uploadUsageMetric(user.userId, "In create error page");

      }, []);
    return <ErrorContainer />
}

// CREATE NEW SPACE
export const CreateSpacePage = () => {
    return <MainGridLayout left={<Box sx={{minHeight: '100vh'}}><GoBackToSpaces /> <SpaceEditor /></Box>} />
}

// CREATE NEW TWINLY
export const CreateTwinlyPage = () => {
    return <TwinlyContainer />
}

// CREATE NEW FANTASY TWINLY
export const CreateFantasyTwinlyPage = () => {
    return <FantasyContainer />
}

// CREATE FANTASY BASIC
export const CreateFantasyTwinlyBasicPage = () => {
    return <TwinlyBasicFantasyContainer />
}

// CREATE BASIC
export const CreateBasicTwinlyPage = () => {
    return <TwinlyBasicContainer />
}

// EXPLORE
export const ExplorePage = () => {
    const user = isLoggedIn();

    useEffect(() => {
        
        uploadUsageMetric(user.userId, "In explore page");

      }, []);
    return <MainGridLayout left={<RecommendedTwinlies />} />
}

// MESSENGER
export const MessengerPage = () => {
    const user = isLoggedIn();

    useEffect(() => {
        const fetchData = async () => {
            const status = await testGCloudAPI();
            console.log(status);
            return status;
          }
        
          uploadUsageMetric(user.userId, "In messenger page");

        try{
            console.log("API pinged");
            const status = fetchData();
            console.log(status);
        
        } catch (err) {
            console.log(JSON.stringify(err));
        }
        
    }, []);
    return <MessengerContainer />
}

// SPACES
export const SpacesPage = () => {
    return <MainGridLayout left={<><YourSpaces /><DiscoverSpaces /></>}/>
}

// SINGLE SPACE
export const SpacePage = () => {
    return <SpaceContainer />
}

// CREATE
export const CreatePage = () => {
    const user = isLoggedIn();

    useEffect(() => {
        
        uploadUsageMetric(user.userId, "In create page");

      }, []);
    return <MainGridLayout left={<CreateTab />} />
}

// SEARCH
export const SearchPage = () => {
    const user = isLoggedIn();

    useEffect(() => {
        
        uploadUsageMetric(user.userId, "In search page");

      }, []);
    return <MainGridLayout left={<SearchContainer />} />
}

// ONBOARDING
export const OnboardingPage = () => {
    return <OnBoardingContainer />
}

// PROFILE
export const ProfilePage = () => {
    const user = isLoggedIn();

    useEffect(() => {
        
        uploadUsageMetric(user.userId, "In profile page");

      }, []);
    return <ProfileContainer />
}

