import GoBack from "./GoBack";
import Loading from "./Loading";
import Comments from "./Comments";
import PostCard from "./PostCard";
import ErrorAlert from "./ErrorAlert";
import { Stack, Box } from "@mui/material";
import { getPost } from "../api/posts";
import {createComment} from "../api/posts";
import { useParams } from "react-router-dom";
import MainGridLayout from './MainGridLayout';
import {getBotRes} from "../api/bot_behavior";
import {uploadUsageMetric} from "../api/users";
import React, { useEffect, useState } from "react";
import { isLoggedIn } from "../helpers/authHelper";
import TopPosts from "./TopPosts";


const SinglePostContainer = () => {
  const params = useParams();

  const [post, setPost] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const user = isLoggedIn();

  const [reloadValue, setReloadValue] = useState(0);

  const reload = () =>{
    setReloadValue((current) => (current + 1));
  };

  const checkIfCommentsExist = async(data) =>{
    // if(data.commentCount == 0){
    //   console.log("No comments yet");
    //   console.log("hello");
    //   const trump_res = await getBotRes([{"role": "user", "content": data.content + ". Don't mention the date and time unless necessary"}], "trump")
    //   const trumpres = await trump_res.response;
    //   console.log(trump_res);
    //   console.log(trumpres);

    //   const biden_res = await getBotRes([{"role": "user", "content": data.content + ". Don't mention the date and time unless necessary"}], "biden")
    //   const bidenres = await biden_res.response;
    //   console.log(biden_res);
    //   console.log(bidenres);

    //   const sportsfanatic_res = await getBotRes([{"role": "user", "content": data.content + ". Don't mention the date and time unless necessary"}], "sports-fanatic")
    //   const fanaticres = await sportsfanatic_res.response;
    //   console.log(biden_res);
    //   console.log(fanaticres);

    //   const body1 = {
    //     content: trumpres,
    //     parentID: "",
    //     commenterId: "65ed2efb9e19016097ba49a6",
    //   };
    //   const comment1 = await createComment(body1, params, user);

    //   const body2 = {
    //     content: bidenres,
    //     parentID: "",
    //     commenterId: "65ed2f469e19016097ba49a7",
    //   };
    //   const comment2 = await createComment(body2, params, user);

    //   const body3 = {
    //     content: fanaticres,
    //     parentID: "",
    //     commenterId: "65ed2f829e19016097ba49a8",
    //   };
    //   const comment3 = await createComment(body3, params, user);

    //   reload();

    // }
    // else{
    //   console.log("Comments already exist");
    // }
  };

  const fetchPost = async () => {
    uploadUsageMetric(user.userId, "Post clicked. Post ID: " + params.id);

    setLoading(true);
    const data = await getPost(params.id, user && user.token);
    if (data.error) {
      setError(data.error);
    } else {
      setPost(data);
      checkIfCommentsExist(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPost();
  }, [params.id, reloadValue]);

  return (
    <>
      <GoBack />
      <Box sx={{ mt: 2 }}>
        <MainGridLayout
          left={
            loading ? (
              <Loading />
            ) : post ? (
              <Stack spacing={2}>
                <PostCard post={post} key={post._id} />

                <Comments />
              </Stack>
            ) : (
              error && <ErrorAlert error={error} />
            )
          }
          right={
            <Stack spacing={2}>
              <TopPosts />
            </Stack>
          }

        />
      </Box>
    </>
  );
};

export default SinglePostContainer;
