import { Avatar } from "@mui/material";
import React from "react";

import { GCLOUD_PUBLIC_BUCKET_URL } from "../config";

const UserAvatar = ({ username, height, width, imgUrl }) => {
  return (
    <Avatar
      sx={{
        height: height,
        width: width,
        backgroundColor: "lightgray",
        border: "1px solid purple"
      }}
      // src={imgUrl ? `${new URL(imgUrl, GCLOUD_PUBLIC_BUCKET_URL)}` : "https://robohash.org/" + username}
      src={imgUrl ? GCLOUD_PUBLIC_BUCKET_URL + imgUrl : "https://robohash.org/" + username}

    />
  );
};

export default UserAvatar;
