import { initiateSocketConnection } from "./socketHelper";

const isLoggedIn = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user === null) return false
  else {
    return user
  }
};

const loginUser = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
  initiateSocketConnection();
};

const logoutUser = () => {
  localStorage.removeItem("user");
  initiateSocketConnection();
};

const updateLocalUser = (data) => {
  let user = JSON.parse(localStorage.getItem("user"));
  if (!user) 
    return

  user = { ...user, ...data };
  localStorage.setItem("user", JSON.stringify(user));
}

export { loginUser, isLoggedIn, logoutUser, updateLocalUser };
