import { BASE_URL } from "../config";

const setSeen = async (user, notificationId) => {
  try {
    const res = await fetch(BASE_URL + "api/notifications/setseen", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": user.token,
      },
      body: JSON.stringify({notificationId: notificationId}),
    });
    return res.json();
  } catch (err) {
    console.log(err);
  }
};


const getNotifications = async (user) => {
  try {
    const res = await fetch(BASE_URL + "api/notifications/get", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": user.token,
      },
      body: JSON.stringify({userId: user.userId}),
    });
    return res.json();
  } catch (err) {
    console.log(err);
  }
};


export { setSeen, getNotifications };
