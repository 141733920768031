import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useGlobalContext } from '../context/context';

const ModalPopUp = () => {
    const { modal, setModal, handleOpenModal, modalTitle, modalText, handleCloseModal, } = useGlobalContext();

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        border: 'none',
        borderRadius: '10px',
        p: 4,
    };

    return (
        <div>
            <Modal
              open={modal}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {modalTitle}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {modalText}
                </Typography>
              </Box>
            </Modal>
        </div>
    )
}

export default ModalPopUp