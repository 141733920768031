import { Box, Button, Card, Typography } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import SpaceCard from './SpaceCard';
import NewSpaceCard from './NewSpaceCard';
import { modeCol } from '../functions/colors';
import { useGlobalContext } from '../context/context';
import gta from './images/discover-section-images/GTA_Unofficial.jpeg';
import starwars from "./images/discover-section-images/starwars.jpeg";
import leagueoflegends from "./images/discover-section-images/leagueoflegends.webp";
import overwatch from "./images/discover-section-images/overwatch.webp";

const YourSpaces = () => {

  const { mode } = useGlobalContext();

  const getMoreSpaces = async (e) => {
    console.log("Get more games");
  };

  const navigate = useNavigate();

  const spaceCardValue = [
    {id: 0, name: 't/StarWarsOutlaws'},
    {id: 1, name: 't/LeagueOfLegends'},
    {id: 2, name: 't/Overwatch'},
    // {id: 3, name: 't/GenAI_SanDiego'},
    // {id: 4, name: 't/Marvel_or_DC'},
    // {id: 5, name: 't/MangaClub'},
    // {id: 6, name: 't/singularity'},
    // {id: 7, name: 't/FutureOfAmerica'},
  ]

  return (
    <Card sx={{ margin: '30px 0', padding: '25px', bgcolor: modeCol(mode).cardColor }}>

      <Box sx={{ margin: '25px 0' }}>
        <Typography color={modeCol(mode).textColor} pb={1} variant="h5" component="h6">Games you Follow</Typography>
        <Button onClick={() => navigate("/games/create")} variant="outlined" style={{borderRadius:"20px"}}>+Create a new game community</Button>
      </Box>
      

      <Box sx={{ display: 'grid', gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)' }, gap: '15px' }}>
        {/* {spaceCardValue.map((value) => (
          <SpaceCard key={value.id} uniqueKey={value.id} name={value.name} />
        ))} */}
        <NewSpaceCard image={gta} key="t/GTA_Unofficial" id="t/GTA_Unofficial" name="t/GTA_V_Online" />
        <NewSpaceCard image={starwars} key="t/StarWarsOutlaws" id="t/StarWarsOutlaws" name="t/StarWarsOutlaws" />
        <NewSpaceCard image={leagueoflegends} key="t/LeagueOfLegends" id="t/LeagueOfLegends" name="t/LeagueOfLegends" />
        <NewSpaceCard image={overwatch} key="t/Overwatch" id="t/Overwatch" name="t/Overwatch" />
      </Box>

      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '30px' }}>
        <Button sx={{alignItems: 'center'}} onClick={getMoreSpaces}>See more</Button>
      </div>

    </Card>
  );
};

export default YourSpaces