import { BASE_URL } from "../config";

const getConversations = async (user) => {
  try {
    console.log("In getConversations. user is ");
    console.log(user);
    const res = await fetch(BASE_URL + "api/messages", {
      headers: {
        "x-access-token": user.token,
      },
    });
    return await res.json();
  } catch (err) {
    console.log(err);
  }
};

const getProfileConvos = async (user) => {
  try {
    const res = await fetch(BASE_URL + "api/messages/profile", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": user.token,
      },
      body: JSON.stringify({profileId: user.profileId}),
    });
    // console.log("Response of getProfileConvos")
    // console.log(res.json());
    return await res.json();
  } catch (err) {
    console.log(err);
  }
};

const getRecommendedMessages = async (suggestorId, convoId, user) => {
  try {

    const res = await fetch(BASE_URL + "api/messages/suggestions", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": user.token,
      },
      body: JSON.stringify({
        suggestorId: suggestorId,
        convoId: convoId,
        userId: user.userId
      }),
    });
    const data = await res.json();
    return data;
  } catch (err) {
    console.log(err);
  }
};

const updateLastSeen = async (user, convoId) => {
  try {
    const res = await fetch(BASE_URL + "api/messages/lastseen", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": user.token,
      },
      body: JSON.stringify({
        convoId: convoId,
        userId: user.userId
      }),
    });
    const data = await res.json();
    return data;
  } catch (err) {
    console.log(err);
  }
};

const sendNotificationEmail = async (user, convoId, recipientId, recipientEmail) => {
  try {
    const res = await fetch(BASE_URL + "api/messages/notificationemail", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": user.token,
      },
      body: JSON.stringify({
        username: user.username,
        convoId: convoId,
        recipientId: recipientId,
        recipientEmail: recipientEmail
      }),
    });
    const data = await res.json();
    return data;
  } catch (err) {
    console.log(err);
  }
};

const getNumUnreadMessages = async (user, conversation) => {
  try {
    const res = await fetch(BASE_URL + "api/messages/unreadMessages", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": user.token,
      },
      body: JSON.stringify({
        userId: user.userId,
        conversation: conversation
      }),
    });
    const data = await res.json();
    return data;
  } catch (err) {
    console.log(err);
  }
};


const getMessages = async (user, conversationId) => {
  try {
    const res = await fetch(BASE_URL + "api/messages/" + conversationId, {
      headers: {
        "x-access-token": user.token,
      },
    });
    return await res.json();
  } catch (err) {
    console.log(err);
  }
};

const sendMessage = async (user, message, recipientId) => {
  try {
    const res = await fetch(BASE_URL + "api/messages/" + recipientId, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": user.token,
      },
      body: JSON.stringify(message),
    });
    return await res.json();
  } catch (err) {
    console.log(err);
  }
};

export { getConversations, getProfileConvos, getRecommendedMessages, updateLastSeen, getNumUnreadMessages, sendNotificationEmail, getMessages, sendMessage };
