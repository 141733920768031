import { io } from "socket.io-client";
import { BASE_URL } from "../config";
import { isLoggedIn } from "./authHelper";

export let socket;

export const initiateSocketConnection = () => {
  const user = isLoggedIn();

  socket = io(BASE_URL, {
    auth: {
      token: user && user.token,
    },
    cors: { 
      origin: [
        "http://localhost:3000",
        "http://localhost:4000",
        "https://twinlyai-webapp.wl.r.appspot.com/",
        "https://storage.googleapis.com",
        "https://frontend-mekk.onrender.com",
        "https://mytwinly.ai",
        "https://www.mytwinly.ai",
        "https://twinlyai.com",
        "https://www.twinlyai.com",
        "https://backend-l4ql.onrender.com"
    ] },
    // withCredentials: true
  });

  socket.on("connect_error", (err) => {
    // the reason of the error, for example "xhr poll error"
    console.log(err.message);
  
    // some additional description, for example the status code of the initial HTTP response
    console.log(err.description);
  
    // some additional context, for example the XMLHttpRequest object
    console.log(err.context);
  });
};



export const disconnectSocket = () => {
  if (socket) socket.disconnect();
};
