import { Alert } from "@mui/material";
import React from "react";

const SuccessAlert = ({ success }) => {
  return (
    success && (
      <Alert variant="filled" severity="success">
        {success}
      </Alert>
    )
  );
};

export default SuccessAlert;
