import {
  Avatar,
  Card,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { MdRefresh } from "react-icons/md";
import { Link } from "react-router-dom";
import { getRandomUsers } from "../api/users";
import Loading from "./Loading";
import UserAvatar from "./UserAvatar";
import HorizontalStack from "./util/HorizontalStack";
import UserEntry from "./UserEntry";
import { modeCol } from "../functions/colors";
import { useGlobalContext } from "../context/context";
import FindUserCard from "./FindUsersCard";

const FindUsers = () => {
  const { mode } = useGlobalContext();
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState(null);

  const fetchUsers = async () => {
    setLoading(true);
    const data = await getRandomUsers({ size: 5 });
    setLoading(false);
    setUsers(data);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleClick = () => {
    fetchUsers();
  };

  return (
    <Card sx={{ display: { xs: "none", md: "block" }, backgroundColor: modeCol(mode).cardColor }}>
      <Stack spacing={2}>
        <HorizontalStack justifyContent="space-between">
          <HorizontalStack>
            <AiOutlineUser color={modeCol(mode).textColor} />
            <Typography color={modeCol(mode).textColor}>Find Others</Typography>
          </HorizontalStack>
          <IconButton
            sx={{ padding: 0 }}
            disabled={loading}
            onClick={handleClick}
          >
            <MdRefresh color={modeCol(mode).textColor} />
          </IconButton>
        </HorizontalStack>

        <Divider />

        {loading ? (
          <Loading />
        ) : (
          users &&
          users.map((user) => (
            <FindUserCard type={user.type} username={user.username} key={user.username} imgUrl={user.profilePicUrl} />
          ))
        )}
      </Stack>
    </Card>
  );
};

export default FindUsers;
