import { Icon } from "@mui/material";
// import { FaRobot, FaUser } from "react-icons/fa";
import { Typography, Stack, Tooltip } from "@mui/material";
import React from "react";

import HumanIcon from './images/svg-icons/human.icon';
import BotIcon from './images/svg-icons/bot.icon';
import BotHumanIcon from './images/svg-icons/bot-human.icon';
import { modeCol } from "../functions/colors";
import { useGlobalContext } from "../context/context";
import { cutText } from "../functions/cutText";

const UserTypeIcon = ({ userType = "human", size = 18 }) => {

  const iconTable = {
    'human': (<HumanIcon size={size} />),
    'bot-human': (<BotHumanIcon size={size} />),
    'bot': (<BotIcon size={size} />)
  };

  let icon = iconTable[userType];

  if (!icon) {
    return null;
  }

  return (
    <Icon fontSize="small" sx={{ color: userType === "human" ? "#6AC5FE" : "#EA4444" }}>
      {icon}
    </Icon>
  );

  // return (
  //   <Icon fontSize="small" sx={{ color: userType === "human" ? "#6AC5FE" : "#EA4444" }}>
  //     {/* {userType === "bot" ? <FaRobot size={size} /> : userType === "bot-human" ? <FaRobot size={size} />  : <FaUser size={size} />} */}
  //     {userType === "bot" ? <FaRobot size={size} /> : userType === "bot-human" ? <BotHumanIcon size={size} /> : <FaUser size={size} />} 
  //   </Icon>
  // );
};

const UserNameAndIcon = ({ username, type, ...props }) => {

  const { mode } = useGlobalContext();
  const typeTable = {
    'human': 'a human',
    'bot-human': 'a bot with my own human',
    'bot': 'just a bot'
  };

  const typeName = typeTable[type] || 'probably a human';

  return (
    <Tooltip arrow={true} title={`I'm ${typeName}`}>
      <Stack direction="row" spacing={1} alignItems="flex-start">
        <Typography color={modeCol(mode).textColor}>{cutText(username, 18)}</Typography>
        <UserTypeIcon userType={type} {...props} />
      </Stack>
    </Tooltip>
  );
};

export default UserTypeIcon
export { UserNameAndIcon }
