// ICONS
import { CgProfile } from "react-icons/cg";
import { FaGamepad } from "react-icons/fa";
import { AiOutlineHome } from "react-icons/ai";
import { IoCreateOutline } from "react-icons/io5";
import { isLoggedIn } from '../helpers/authHelper';
import { MdOutlineExplore, MdOutlineMessage } from "react-icons/md";

// Create Twinly Type Images
import fantasyImg from "../components/images/create-page-icons/1.png";
import historicalImg from "../components/images/create-page-icons/2.png";
import superheroImg from "../components/images/create-page-icons/3.png";
import scifiImg from "../components/images/create-page-icons/4.png";
import celebrityImg from "../components/images/create-page-icons/5.png";
import customImg from "../components/images/create-page-icons/6.png";

export const twinlyTypes = [
    { id: 0, text: "Superhero", local: true, img: superheroImg },
    { id: 1, text: "Sci-fi", local: true, img: scifiImg },
    { id: 2, text: "Celebrity", local: true, img: celebrityImg },
    { id: 3, text: "Historical", local: true, img: historicalImg },
    { id: 4, text: "Fantasy", local: true, img: fantasyImg },
    { id: 5, text: "Custom", local: true, img: customImg },
]

// SERVICE BOT DEFAULT

export const default_serviceBot = {
  _id: "66272017dfba5edbea908eaf",
  username: "TwinlyAI_CustomerService",
  email: "twinlyai_customerservice@bot.mytwinly.ai",
  biography: "Empowering connections through exceptional customer service, elevating your TwinlyAI experience.",
  profilePicUrl: "/users/66257c7be1a20c33ddfd97f6_1713842732167.jpeg",
  isAdmin: false,
  isBot: true,
  type: "bot-human",
  createdAt: "2024-04-23T02:42:31.305Z",
  updatedAt: "2024-04-23T03:25:38.292Z",
  __v: 0,
  creator: "66257c7be1a20c33ddfd97f6"
}


// DEFAULT IMAGE IN EXPLORE PAGE FOR BOTS
export const default_bot_image = "https://media.istockphoto.com/id/1221348467/vector/chat-bot-ai-and-customer-service-support-concept-vector-flat-person-illustration-smiling.jpg?s=612x612&w=0&k=20&c=emMSOYb4jWIVQQBVpYvP9LzGwPXXhcmbpZHlE6wgR78=";
// BOT OPTIONS
export const bot_options = [
    { id: 0, value: 'Games' },
    { id: 1, value: 'Superhero' },
    { id: 2, value: 'Sci-fi' },
    { id: 3, value: 'Historical' },
    { id: 4, value: 'Fantasy' },
    { id: 5, value: 'Celebrity' },
    { id: 6, value: 'Custom' },
    { id: 7, value: 'You-Twinlies' },
    
  ]


// /ONBOARDING COMPLETE YOUR PROFILE
// SELECTIONS
export const selections = [
  {id: 0, link: '/create/yourtwinly/superbasic', title: 'Super Basic', text1: 'Pro: Approx 5-10 min setup time. Your Twinly will act a lot like you. You\'d be surprised.', text2: 'Con: Your Twinly is not super trained, but you can add more training later.' },
  {id: 1, link: '/', title: 'Super Realistic', text1: 'Pro: If you spend a few hours or days training your Twinly, you\'ll be surprised how smart it is.', text2: 'Con: Could take anywhere between a few hours of training to 50+ years, depending on what you\'re looking for.'  },
]


// NAVLINKS
export const getNavLinks = () =>{
    const user = isLoggedIn();
    const username = user?.username || '';
    const userId = user?.userId || '';

    const navLinks = [
        { id: 0, secure: true, name: 'Create', link: '/create', icon: ((col) => <IoCreateOutline fontSize={25} color={col} />) },
        { id: 1, secure: false, name: 'Feed', link: '/home', icon: ((col) => <AiOutlineHome fontSize={25} color={col} />) },
        { id: 2, secure: false, name: 'Explore', link: '/explore', icon: ((col) => <MdOutlineExplore fontSize={25} color={col} />) },
        { id: 3, secure: true, name: 'Messages', link: '/messenger', icon: ((col) => <MdOutlineMessage fontSize={25} color={col} />) },
        { id: 4, secure: false, name: 'Games', link: '/games', icon: ((col) => <FaGamepad fontSize={25} color={col} />) },
    ];

    if(username){
        navLinks.push({ id: 5, name: 'Profile', link: `/users/${username}`, icon: ((col, size) => <CgProfile fontSize={size} color={col} />) });
    }

    return navLinks;
}


export const categories = [
    { id: 1, title: "Entertainment", img: "https://res.cloudinary.com/dqhljaplg/image/upload/v1713129106/vnab3sbpcotlkxqxizty.jpg" },
    { id: 2, title: "Hobbies & Interests", img: "https://res.cloudinary.com/dqhljaplg/image/upload/v1713129106/huxvyhpgw9iazht7bqbv.jpg" },
    { id: 3, title: "Technology", img: "https://res.cloudinary.com/dqhljaplg/image/upload/v1713129106/p3h45aoe1vbtmwjuy8vp.jpg" },
    { id: 4, title: "Arts & Creativity", img: "https://res.cloudinary.com/dqhljaplg/image/upload/v1713129106/s6sdcvcwnaxlpm6ogjmk.jpg" },
    { id: 5, title: "Health & Wellness", img: "https://res.cloudinary.com/dqhljaplg/image/upload/v1713129106/oydva4lrvtylqa8u84dc.jpg" },
    { id: 6, title: "Fashion & Style", img: "https://res.cloudinary.com/dqhljaplg/image/upload/v1713129107/oyqrctedkqo3igxg0edm.jpg" },
    { id: 7, title: "Nature & Outdoors", img: "https://res.cloudinary.com/dqhljaplg/image/upload/v1713129107/gvmdzosunp1tixusdkys.jpg" },
    { id: 8, title: "Food & Cuisine", img: "https://res.cloudinary.com/dqhljaplg/image/upload/v1713129107/g8fjmqeeyrc5qcdk5oqx.jpg" },
    { id: 9, title: "History & Culture", img: "https://res.cloudinary.com/dqhljaplg/image/upload/v1713129107/yy3fl0jsuqsqdly6tooa.jpg" },
    { id: 10, title: "Science & Discovery", img: "https://res.cloudinary.com/dqhljaplg/image/upload/v1713129107/xidq1s0ynwc8z4yo3cwm.jpg" },
    { id: 11, title: "Social Causes", img: "https://res.cloudinary.com/dqhljaplg/image/upload/v1713129108/t2ml9xzp390fmrpghcfl.jpg" },
    { id: 12, title: "Business & Finance", img: "https://res.cloudinary.com/dqhljaplg/image/upload/v1713129108/ajfhnhfohrodxptkeywt.jpg" },
    { id: 13, title: "Languages & Travel", img: "https://res.cloudinary.com/dqhljaplg/image/upload/v1713129620/tv04tmalibwzwefkyxvs.jpg" },
    { id: 14, title: "Relationships", img: "https://res.cloudinary.com/dqhljaplg/image/upload/v1713129108/ahuwjpxhcm7xsdx3gskk.jpg" },
    { id: 15, title: "Add Your Own", img: "https://res.cloudinary.com/dqhljaplg/image/upload/v1713129744/gcxmgnlub6hgwqkr034f.jpg" }
];

export const subCategories = [
    { 
        id: 1,
        category_id: 1,
        subCategory: [
            { id: 1, title: "Movies"},
            { id: 2, title: "TV Shows"},
            { id: 3, title: "Music"},
            { id: 4, title: "Books"},
            { id: 5, title: "Video Games"},
            { id: 6, title: "Theater"},
            { id: 7, title: "Sports Events"}
        ] 
    },
    { 
        id: 2,
        category_id: 2,
        subCategory: [
            { id: 8, title: "Cooking"},
            { id: 9, title: "Photography"},
            { id: 10, title: "Travel"},
            { id: 11, title: "Gardening"},
            { id: 12, title: "DIY Projects"},
            { id: 13, title: "Collectibles"},
            { id: 14, title: "Fitness"}
        ] 
    },
    { 
        id: 3,
        category_id: 3,
        subCategory: [
            { id: 15, title: "Programming Languages"},
            { id: 16, title: "Web Development"},
            { id: 17, title: "Mobile Apps"},
            { id: 18, title: "Gadgets"},
            { id: 19, title: "AI/ML"},
            { id: 20, title: "Cybersecurity"}
        ] 
    },
    { 
        id: 4,
        category_id: 4,
        subCategory: [
            { id: 21, title: "Painting"},
            { id: 22, title: "Writing"},
            { id: 23, title: "Sculpture"},
            { id: 24, title: "Dance"},
            { id: 25, title: "Poetry"},
            { id: 26, title: "Graphic Design"},
            { id: 27, title: "Pottery"}
        ] 
    },
    { 
        id: 5,
        category_id: 5,
        subCategory: [
            { id: 28, title: "Yoga"},
            { id: 29, title: "Meditation"},
            { id: 30, title: "Nutrition"},
            { id: 31, title: "Running"},
            { id: 32, title: "Weightlifting"},
            { id: 33, title: "Mental Health"},
            { id: 34, title: "Sleep Patterns"}
        ] 
    },
    { 
        id: 6,
        category_id: 6,
        subCategory: [
            { id: 35, title: "Clothing Trends"},
            { id: 36, title: "Accessories"},
            { id: 37, title: "Footwear"},
            { id: 38, title: "Makeup"},
            { id: 39, title: "Hairstyles"},
            { id: 40, title: "Jewelry"},
            { id: 41, title: "Personal Branding"}
        ] 
    },
    { 
        id: 7,
        category_id: 7,
        subCategory: [
            { id: 42, title: "Hiking"},
            { id: 43, title: "Camping"},
            { id: 44, title: "Birdwatching"},
            { id: 45, title: "Stargazing"},
            { id: 46, title: "Fishing"},
            { id: 47, title: "Wildlife Photography"},
            { id: 48, title: "Beach Activities"}
        ] 
    },
    { 
        id: 8,
        category_id: 8,
        subCategory: [
            { id: 49, title: "Italian"},
            { id: 50, title: "Japanese"},
            { id: 51, title: "Vegan"},
            { id: 52, title: "BBQ"},
            { id: 53, title: "Baking"},
            { id: 54, title: "Street Food"},
            { id: 55, title: "Wine Pairing"},
            { id: 56, title: "Food Photography"}
        ] 
    },
    { 
        id: 9,
        category_id: 9,
        subCategory: [
            { id: 57, title: "Ancient Civilizations"},
            { id: 58, title: "Art Movements"},
            { id: 59, title: "World Religions"},
            { id: 60, title: "Folklore"},
            { id: 61, title: "Historical Fiction"},
            { id: 62, title: "Heritage Travel"}
        ] 
    },
    { 
        id: 10,
        category_id: 10,
        subCategory: [
            { id: 63, title: "Astronomy"},
            { id: 64, title: "Biology"},
            { id: 65, title: "Physics"},
            { id: 66, title: "Archaeology"},
            { id: 67, title: "Space Exploration"},
            { id: 68, title: "Environmental Science"},
            { id: 69, title: "Popular Science"}
        ] 
    },
    { 
        id: 11,
        category_id: 11,
        subCategory: [
            { id: 70, title: "Climate Change"},
            { id: 71, title: "Human Rights"},
            { id: 72, title: "Animal Welfare"},
            { id: 73, title: "Education"},
            { id: 74, title: "Poverty Alleviation"},
            { id: 75, title: "Gender Equality"}
        ] 
    },
    { 
        id: 12,
        category_id: 12,
        subCategory: [
            { id: 76, title: "Entrepreneurship"},
            { id: 77, title: "Stock Market"},
            { id: 78, title: "Personal Finance"},
            { id: 79, title: "Real Estate"},
            { id: 80, title: "Marketing"},
            { id: 81, title: "Startups"},
            { id: 82, title: "Cryptocurrency"}
        ] 
    },
    { 
        id: 13,
        category_id: 13,
        subCategory: [
            { id: 83, title: "Learning New Languages"},
            { id: 84, title: "Cultural Immersion"},
            { id: 85, title: "Backpacking"},
            { id: 86, title: "City Exploration"},
            { id: 87, title: "Language Exchange"}
        ] 
    },
    { 
        id: 14,
        category_id: 14,
        subCategory: [
            { id: 88, title: "Dating"},
            { id: 89, title: "Marriage"},
            { id: 90, title: "Parenting"},
            { id: 91, title: "Friendship"},
            { id: 92, title: "Long-Distance Relationships"},
            { id: 93, title: "LGBTQ+"},
            { id: 94, title: "Relationship Advice"}
        ] 
    },
    { 
        id: 15,
        category_id: 15,
        subCategory: [
            { id: 95, title: "Meditation Practices"},
            { id: 96, title: "Philosophy"},
            { id: 97, title: "Mind-Body Connection"},
            { id: 98, title: "Energy Healing"},
            { id: 99, title: "Tarot"},
            { id: 100, title: "Astrology"}
        ] 
    }
];
