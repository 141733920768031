import './index.css';
import React from 'react';
import { CssBaseline } from '@mui/material';
import MainRoutes from './Routers/MainRoutes';
import { Routes, Route } from 'react-router-dom';
import { useGlobalContext } from './context/context';
import { initiateSocketConnection } from './helpers/socketHelper';

import BioView from './components/views/BioView';
import LoginView from './components/views/LoginView';
import SocialView from './components/views/SocialView';
import SignupView from './components/views/SignupView';
import ForgotPasswordView from './components/views/ForgotPasswordView';
import ResetPasswordView from './components/views/ResetPasswordView';
import VerifyView from './components/views/VerifyView';
import InterestView from './components/views/InterestView';
import WaitlistView from './components/views/WaitlistView';
import DoublePrivateRoute from './components/DoublePrivateRoute';
import SubCategoriesView from './components/views/SubCategoriesView';

const App = () => {
  initiateSocketConnection();

  // Global
  const { path } = useGlobalContext();
  const { registerDone, verifyDone, interestDone, subcategoryDone, bioDone, socialDone } = path;
  
  // Local
  const doubleSecure = (children, path) => {
    return <DoublePrivateRoute path={path}>{children}</DoublePrivateRoute>;
  };

  return (
    <>
      <CssBaseline />
      <Routes>
        {/* Sidebar */}
        <Route path="/*" element={<MainRoutes />} />

        {/* No Sidebar */}
        <Route path="/login" element={<LoginView />} />
        <Route path="/waitlist" element={<WaitlistView />} />
        <Route path="/signup" element={<SignupView />} />
        <Route path="/forgotpassword" element={<ForgotPasswordView />} />
        <Route path="/resetpassword" element={<ResetPasswordView />} />
        {/* Double Protected routes */}
        {/* Follow each other if interest => if subcategory => if bio => social */}

        <Route path="/verify" element={doubleSecure(<VerifyView />, registerDone)} />
        <Route path="/interest" element={doubleSecure(<InterestView />, verifyDone)} />
        <Route path="/subcategories" element={doubleSecure(<SubCategoriesView />, interestDone)}/>
        <Route path="/bio" element={doubleSecure(<BioView />, subcategoryDone)} />
        <Route path="/social" element={doubleSecure(<SocialView />, bioDone)} />
        
      </Routes>
    </>
  );
};

export default App;
