import { Button, Stack } from '@mui/material'
import React from 'react'

const EnterBottom = ({leftText, rightText, leftFunc, rightFunc }) => {
    return (
        <Stack direction="row" spacing={2} sx={{ marginBottom: 2, display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="contained" onClick={leftFunc} sx={{ minWidth: '40%', height: '50px' }}>{leftText}</Button>
            <Button variant="contained" onClick={rightFunc} sx={{ minWidth: '40%', height: '50px' }}>{rightText}</Button>
        </Stack>
    )
}

export default EnterBottom
