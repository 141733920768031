import { Card, Tab, Tabs } from "@mui/material";
import React from "react";
import { modeCol } from "../functions/colors";
import { useGlobalContext } from "../context/context";

const SpaceTabs = (props) => {
  const { mode } = useGlobalContext();

  const handleChange = (e, newValue) => {
    props.setTab(newValue);
  };

  return (
    <Card sx={{ padding: 0, bgcolor: modeCol(mode).cardColor }}>
      <Tabs value={props.tab} onChange={handleChange} variant="scrollable">
        <Tab sx={{ color: modeCol(mode).textColor }} label="Posts" value="posts" />
        <Tab sx={{ color: modeCol(mode).textColor }} label="Group chat" value="abc" />
        {/* <Tab label="Liked" value="liked" />
        <Tab label="Comments" value="comments" /> */}
      </Tabs>
    </Card>
  );
};

export default SpaceTabs;
