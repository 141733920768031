import React from 'react';
import { AiFillMessage } from "react-icons/ai";
import { Box, TextField } from "@mui/material";
import { modeCol } from "../../functions/colors";
import HorizontalStack from "../util/HorizontalStack";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { useGlobalContext } from '../../context/context';
import { 
    InviteFriendBox, 
    SmallMessageIconBox, 
    InputStylesUserEntries, 
    userEntriesHorizontalOne, 
    InputPropsStyleUserEntries, 
    InputLabelPropsStyleUserEntries, 
} from "./Styles";

const UserEntriesTop = ({ searchConversation, handleFindConversation, setShowInviteModal }) => {
    // Global
    const { mode } = useGlobalContext();

    // Local
    const handleInviteFriend = () => {
        setShowInviteModal(true)
    }

    return (
        <HorizontalStack sx={userEntriesHorizontalOne(mode)}>

            <Box sx={SmallMessageIconBox()}>
                <AiFillMessage 
                    size={30} 
                    color={modeCol(mode).textColor} 
                />
            </Box>

            <TextField 
                fullWidth
                size="small"
                value={searchConversation}
                sx={InputStylesUserEntries(mode)}
                placeholder='Find your conversations'
                InputProps={InputPropsStyleUserEntries(mode)}
                InputLabelProps={InputLabelPropsStyleUserEntries(mode)}
                onChange={handleFindConversation}
            />

            <Box sx={InviteFriendBox()} onClick={handleInviteFriend}>
                <AiOutlineUsergroupAdd 
                    size={30}
                    cursor='pointer' 
                    color={modeCol(mode).brandColor} 
                />
            </Box>

        </HorizontalStack>
    )
}

export default UserEntriesTop