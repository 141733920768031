import React, { useState } from 'react';
import EnterBottom from '../EnterBottom';
import EnterTopTitle from '../EnterTopTitle';
import { useNavigate } from 'react-router-dom';
import { categories, subCategories } from '../../data/data';
import { useGlobalContext } from '../../context/context';
import { Card, Container, Typography, Box, Button, Snackbar } from '@mui/material';

const SubCategoriesView = () => {

    // Global
    const { selectedInterests, selectedSubCategories, setSelectedSubCategories, path, setPath } = useGlobalContext();

    // Filtered SubCategories
    const filteredSubCategories = subCategories.filter((item) => selectedInterests.includes(item.category_id));
    
    // Local
    const navigate = useNavigate();
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    
    // NEXT

    const handleNext = (e) => {
        e.preventDefault()
        if (selectedSubCategories.length < 3) {
            setAlertMessage("Please select at least 3");
            setAlertOpen(true);
        } else {
            console.log('Selected subcategories:', selectedSubCategories);
            setPath({...path, subcategoryDone: true})
            navigate('/bio')
        }
    };

    // PREV

    const handlePrev = () => {
        navigate('/interest');
    };

    // SELECTION LOGIC

    const handleSubCategorySelect = (id) => {
        const index = selectedSubCategories.includes(id);
        if (!index) {
            setSelectedSubCategories([...selectedSubCategories, id]);
        } else {
            setSelectedSubCategories(selectedSubCategories.filter((subCategory) => subCategory !== id));
        }
    };

    // CLOSE ALERT
    
    const handleCloseAlert = () => {
      setAlertOpen(false);
    };

    return (
        <Container maxWidth="xl" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', minHeight: '100vh' }}>

            {/* TOP */}

            <Box mt={5}>
                <EnterTopTitle 
                    title='TwinlyAI'
                    textOne="Let's learn a bit more!"
                    textTwo="(Select your favorites)"
                />
            </Box>

            {/* MIDDLE */}

            <Box sx={{ margin: "40px 0" }}>
                {filteredSubCategories.map((singleCategory) => (
                    <Box key={singleCategory.id}>
                        <Typography sx={{ marginLeft: '25px', fontWeight: 600, color: '#1976D2' }}>{categories[singleCategory.category_id - 1].title}</Typography>
                        <Box sx={{ display: 'grid', gridTemplateColumns: { xs: 'repeat(2, 1fr)', sm: 'repeat(3, 1fr)', md: 'repeat(5, 1fr)' }, margin: '30px 0' }}>
                            {
                                singleCategory.subCategory.map((item) => (
                                    <Card
                                        key={item.id}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: { md: 180, xs: 150 },
                                            margin: '10px auto',
                                            cursor: 'pointer',
                                            border: 'solid 1px #1976D2',
                                            backgroundColor: selectedSubCategories.includes(item.id) ? '#6fb7ff' : 'transparent',
                                            color: selectedSubCategories.includes(item.id) ? '#FFFFFF' : 'inherit',
                                        }}
                                        onClick={() => handleSubCategorySelect(item.id)}
                                    >
                                        <Typography variant="subtitle1" textAlign='center'>
                                            {item.title}
                                        </Typography>
                                    </Card>
                                ))
                            }
                        </Box>
                    </Box>
                ))}
            </Box>

            {/* BOTTOM */}

            <EnterBottom leftText='prev' rightText='next' leftFunc={handlePrev} rightFunc={handleNext} />

            {/* ALERT MESSAGE */}

            <Snackbar
              open={alertOpen}
              autoHideDuration={6000}
              onClose={handleCloseAlert}
              message={alertMessage}
              action={
                <Button color="secondary" size="small" onClick={handleCloseAlert}>
                    Close
                </Button>
              }
            />

        </Container>
    );
};

export default SubCategoriesView;
