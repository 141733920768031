// Function to copy code to clipboard
export const copyToClipboard = (text, setIsCopied) => {
    if (navigator.clipboard && window.isSecureContext) {
      return navigator.clipboard.writeText(text).then(() => setIsCopied(true));
    } else {
      let textArea = document.createElement("textarea");
      textArea.value = text;
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      const copied = document.execCommand('copy');
      setIsCopied(copied);
      textArea.remove();
      return Promise.resolve();
    }
  };