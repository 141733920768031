import React, { useEffect, useState } from "react";
import moment from "moment";
import UserAvatar from "../UserAvatar";
import { modeCol } from "../../functions/colors";
import { UserNameAndIcon } from "../UserTypeIcon";
import { useGlobalContext } from "../../context/context";
import { Box, ListItemAvatar, ListItemText, MenuItem, Typography } from "@mui/material";
import { messageCircle } from "./Styles";


const UserMessengerEntry = ({ conversation, conservant, setConservant, unreadMessages, setConvoUnreadMsgsToZero }) => {

  // Global
  const { mode } = useGlobalContext();

  // Local
  const recipient = conversation.recipient;
  const username = recipient.username;
  const imgUrl = recipient.profilePicUrl;
  const selected = conservant && conservant.username === recipient.username;

  const handleClick = () => {
    setConservant(recipient);
    setConvoUnreadMsgsToZero(conversation);
  };

  useEffect(() => {
    if (selected){
      setConvoUnreadMsgsToZero(conversation);
    }
  }, [conversation.unreadmsgs]);



  return (
    <>
      <MenuItem
        onClick={handleClick}
        sx={{ padding: { xs: 4, md: 2 }}}
        divider
        disableGutters
        selected={selected}
      >
        <ListItemAvatar>
          <UserAvatar height={45} width={45} username={username} imgUrl={imgUrl} />
        </ListItemAvatar>
        <ListItemText
          primary={<UserNameAndIcon username={username} type={recipient.type} />}
          secondary={moment(conversation.lastMessageAt).fromNow()}
          primaryTypographyProps={{ style: { color: modeCol(mode).textColor } }}
          secondaryTypographyProps={{ style: { color: modeCol(mode).textColor } }}
        />

        {/* Bubble for unread messages */}
        {conversation.unreadmsgs != 0 && (
          <Box sx={messageCircle(mode)}>
          {conversation.unreadmsgs}
        </Box>
        )}

        
      </MenuItem>
    </>
  );
};

export default UserMessengerEntry;
