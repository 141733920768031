import { Button, Card, Link, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { getPosts, getUserLikedPosts } from "../api/posts";
import { isLoggedIn } from "../helpers/authHelper";
import CreatePost from "./CreatePost";
import Loading from "./Loading";
import PostCard from "./PostCard";
import SortBySelect from "./SortBySelect";
import HorizontalStack from "./util/HorizontalStack";
import ConversationStarter from "./ConversationStarter";
import { modeCol } from "../functions/colors";
import { useGlobalContext } from "../context/context";

const PostBrowser = (props) => {
  const { mode } = useGlobalContext();

  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [end, setEnd] = useState(false);
  const [sortBy, setSortBy] = useState("-createdAt");
  const [count, setCount] = useState(0);
  const user = isLoggedIn();
  const [search] = useSearchParams();
  const { userId } = useParams();

  const searchExists = search && search.get("search") && search.get("search").length > 0;

  const fetchPosts = async (reset = false) => {
    if (reset) {
      setLoading(true);
      setPosts([]);
      setPage(0);
      setEnd(false);
    } else {
      setLoading(true);
    }

    const newPage = reset ? 1 : page + 1;

    let query = {
      page: newPage,
      sortBy,
    };

    let data;

    if (props.contentType === "posts") {
      if (props.profileUser) query.author = userId;
      if (searchExists) query.search = search.get("search");

      data = await getPosts(user && user.token, query);
    } else if (props.contentType === "liked") {
      data = await getUserLikedPosts(
        props.profileUser._id,
        user && user.token,
        query
      );
    }

    if (data.data.length < 10) {
      setEnd(true);
    }

    setLoading(false);
    if (!data.error) {
      setPosts(prevPosts => reset ? [...data.data] : [...prevPosts, ...data.data]);
      setCount(data.count);
      setPage(newPage);
    }
  };

  useEffect(() => {
    fetchPosts(true);
  }, [sortBy, userId]);

  useEffect(() => {
    fetchPosts(true);
  }, [userId]);

  const handleSortBy = (e) => {
    const newSortName = e.target.value;
    let newSortBy;

    Object.keys(sorts).forEach((sortName) => {
      if (sorts[sortName] === newSortName) newSortBy = sortName;
    });

    setSortBy(newSortBy);
    fetchPosts(true);
  };

  const removePost = (removedPost) => {
    setPosts(posts.filter((post) => post._id !== removedPost._id));
  };

  const handleBackToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const contentTypeSorts = {
    posts: {
      "-createdAt": "Latest",
      "-likeCount": "Likes",
      "-commentCount": "Comments",
      createdAt: "Earliest",
    },
    liked: {
      "-createdAt": "Latest",
      createdAt: "Earliest",
    },
  };

  const sorts = contentTypeSorts[props.contentType];

  if (props.isHome){
    return (
      <Stack sx={{ marginTop: 0 }} spacing={2}>
        <ConversationStarter />

        {searchExists && (
          <Box>
            <Typography color={modeCol(mode).textColor} variant="h5" gutterBottom>
              Showing results for "{search.get("search")}"
            </Typography>
            <Typography color={modeCol(mode).grayText} variant="span">
              {count} results found
            </Typography>
          </Box>
        )}

        <Box sx={{ display: { xs: "block", md: "none" }}}>
          <HorizontalStack>
            <Typography color={modeCol(mode).textColor}  fontWeight={600}>All Post</Typography>
          </HorizontalStack>
        </Box>

        {posts.map((post, i) => (
          <PostCard
            preview="primary"
            key={post._id}
            post={post}
            removePost={removePost}
          />
        ))}

        {loading && <Loading />}
        {end ? (
          <Stack py={5} alignItems="center">
            <Typography variant="h5" color={modeCol(mode).textColor} gutterBottom>
              {posts.length > 0 ? (
                <>All posts have been viewed</>
              ) : (
                <>No posts available</>
              )}
            </Typography>
            <Button variant="text" size="small" onClick={handleBackToTop}>
              Back to top
            </Button>
          </Stack>
        ) : (
          !loading &&
          posts &&
          posts.length > 0 && (
            <Stack pt={2} pb={6} alignItems="center" spacing={2}>
              <Button onClick={() => fetchPosts()} variant="contained">
                Load more
              </Button>
              <Button variant="text" size="small" onClick={handleBackToTop}>
                Back to top
              </Button>
            </Stack>
          )
        )}
      </Stack>
    );
  }
  else{
    return (
      <Stack spacing={2} >
        <Card sx={{ bgcolor: modeCol(mode).cardColor }}>
          <HorizontalStack justifyContent="space-between">
            {props.createPost && <CreatePost />}
            <SortBySelect
              onSortBy={handleSortBy}
              sortBy={sortBy}
              sorts={sorts}
            />
          </HorizontalStack>
        </Card>

        {searchExists && (
          <Box>
            <Typography color={modeCol(mode).textColor} variant="h5" gutterBottom>
              Showing results for "{search.get("search")}"
            </Typography>
            <Typography color={modeCol(mode).textColor} variant="span">
              {count} results found
            </Typography>
          </Box>
        )}

        {posts.map((post, i) => (
          <PostCard
            preview="primary"
            key={post._id}
            post={post}
            removePost={removePost}
          />
        ))}

        {loading && <Loading />}
        {end ? (
          <Stack py={5} alignItems="center">
            <Typography color={modeCol(mode).textColor} variant="h5" gutterBottom>
              {posts.length > 0 ? (
                <>All posts have been viewed</>
              ) : (
                <>No posts available</>
              )}
            </Typography>
            <Button variant="text" size="small" onClick={handleBackToTop}>
              Back to top
            </Button>
          </Stack>
        ) : (
          !loading &&
          posts &&
          posts.length > 0 && (
            <Stack pt={2} pb={6} alignItems="center" spacing={2}>
              <Button onClick={() => fetchPosts()} variant="contained">
                Load more
              </Button>
              <Button variant="text" size="small" onClick={handleBackToTop}>
                Back to top
              </Button>
            </Stack>
          )
        )}
      </Stack>
    );
  }
};

export default PostBrowser;
