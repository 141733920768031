import React from "react";
import MainGridLayout from './MainGridLayout'
import { useNavigate } from "react-router-dom";
import CardContent from '@mui/material/CardContent';
import { Button, Card, Typography } from "@mui/material";
import { modeCol } from "../functions/colors";
import { useGlobalContext } from "../context/context";



const ContentTab = () => {
  const { mode } = useGlobalContext();
  const navigate = useNavigate();
  return (
    <Card style={{minHeight: '100vh', padding:60, backgroundColor: modeCol(mode).cardColor}}>
      <Typography color={modeCol(mode).textColor} style={{padding:15}} variant="h4" textAlign="center"><b>Oops! We encountered an error.</b></Typography>

      <Typography color={modeCol(mode).textColor} style={{padding:15}} variant="subtitle1" textAlign="center"><b>The issue has been logged for investigation. Breathe in, and on the out breath, go back and try again.</b></Typography>

      <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button variant="contained" onClick={()=> navigate("/create")}>Go Back to Create Page</Button>
      </CardContent>

      

    </Card>
  );
};

const ErrorContainer = () => {
  return (
    <MainGridLayout
      left={<ContentTab />}
    />
  );
};

export default ErrorContainer;
