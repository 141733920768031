// Styles:

import { styled } from '@mui/system';
import { Box, Stack, colors } from '@mui/material';
import { modeCol } from '../../functions/colors';

// ======================================================>
// ============= Messenger Container ====================>
// ======================================================>



// ======================================================>
// ============ MainSmallSidebar Component ==============>
// ======================================================>

export const DrawerBox = (drawerWidth, mode) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: drawerWidth,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
    bgcolor: modeCol(mode).sidebarBgColor,
  },
  height: '100%',
  overflowX: 'hidden',
  padding: '20px',
  cursor: 'pointer',
  '@media (max-width: 900px)': {
    display: 'none'
  }
});

export const SideBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center; 
  padding: 20px;
  cursor: pointer;
`;

// ======================================================>
// =============== Send Messege Component ===============>
// ======================================================>

export const textFieldStyles = (mode) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': { borderColor: modeCol(mode).borders },
    '& .MuiInputLabel-root': { color: modeCol(mode).textColor },
    '&:hover fieldset': { borderColor: modeCol(mode).borders },
    color: modeCol(mode).textColor,
  },
  '& .MuiInputLabel-root': { color: modeCol(mode).textColor },
});

export const sendMesssegeStyle = (mode) => ({
  display: 'flex',
  flex: 0,
  border: `1px solid ${modeCol(mode).borders}`,
  p: 2,
})

export const sendButtonStyle = (mode) => ({
  color: 'white',
  backgroundColor: modeCol(mode).brandColor
})

export const inputLabelPropsStyles = (mode) => ({
  style: { color: modeCol(mode).textColor },
});

// ======================================================>
// ================ Messeges Component ==================>
// ======================================================>

export const userNameStyle = (mode) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: modeCol(mode).textColor,
  marginLeft: '10px',
  fontWeight: 'bold',
})

export const topTitle = () => ({
  display: 'flex',
  textDecoration: 'none',
})

export const messagesBox = () => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%'
})

export const messagesContainer = (mode) => ({
  width: '100%',
  bgcolor: modeCol(mode).cardColor,
  display: 'flex',
  alignItems: 'center',
  position: 'fixed',
  flex: 0,
  p: 2,
  zIndex: 100,
  top: 0,
  border: `1px solid ${modeCol(mode).borders}`
})

export const middleStack = (mode) => ({
  padding: 2,
  overflowY: "auto",
  display: 'flex',
  flex: 1,
  border: `0.5px solid ${modeCol(mode).borders}`,
  marginTop: 8,
})

export const emptyStack = (mode) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  border: `0.5px solid ${modeCol(mode).borders}`
})

export const loadingStack = (mode) => ({
  height: "100%",
  border: `0.5px solid ${modeCol(mode).borders}`,
})

// ======================================================>
// =============== Single Messege Component =============>
// ======================================================>

export const getMessageStyles = (messageDirection) => {
  if (messageDirection === "to") {
    return {
      justifyContent: "start-end",
      paddingY: 1,
      width: "100%"
    };
  } else if (messageDirection === "from") {
    return {
      justifyContent: "flex-end",
      paddingY: 1,
      width: "100%"
    };
  } else {
    return {};
  }
};

export const cardStyle = (messageDirection, mode) => ({
  borderRadius: messageDirection === "to" ? "20px 20px 20px 0px" : "20px 20px 0px 20px",
  backgroundColor: messageDirection === "to" ? "#FFF" : modeCol(mode).brandColor,
  color: messageDirection === "to" ? "#000" : "#FFF",
  borderWidth: "1px",
  paddingY: "12px",
  paddingX: 2,
  position: 'relative',
  mb: 1,
})

export const copyTypo = () => ({
  color: '#000',
  position: 'absolute',
  top: '5px',
  right: '20px'
})

export const copyIcon = () => ({
  position: 'absolute',
  top: '5px',
  right: '5px',
  fontSize: '15px'
});

export const timeText = (messageDirection) => ({
  textAlign: messageDirection === "to" ? 'start' : 'end',
  fontSize: '12px'
})

// ======================================================>
// =============== Suggestions Style ====================>
// ======================================================>

export const switchBot = () => ({
  display: 'flex', justifyContent: 'center', alignItems: 'center'
})

export const switchBox = (mode) => ({
  "& .MuiSwitch-thumb": {
    backgroundColor: modeCol(mode).brandColor,
  },
  "& .MuiSwitch-track": {
    backgroundColor: modeCol(mode).borders,
  },
})

export const botText = (mode, open) => ({
  display: open ? 'block' : 'none',
  color: modeCol(mode).brandColor,
  fontSize: '13px',
  fontWeight: 'bold',
  textAlign: 'center'
})

export const BoxContainer = (mode) => ({
  position: 'absolute',
  width: '300px',
  height: '260px',
  bgcolor: modeCol(mode).containerColor,
  bottom: '235px',
  right: 0
})

export const InputStyles = (mode) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': { borderColor: modeCol(mode).borders },
    '& .MuiInputLabel-root': { color: modeCol(mode).textColor },
    '&:hover fieldset': { borderColor: modeCol(mode).borders },
    color: modeCol(mode).textColor,
    borderRadius: '2px 2px 0 0',
  },
})

export const InputPropsStyle = (mode) => ({
  style: {
    backgroundColor: modeCol(mode).containerColor,
    color: modeCol(mode).textColor
  }
})

export const InputLabelPropsStyle = (mode) => ({
  style: {
    color: modeCol(mode).textColor,
  }
})

export const ListStyles = (mode) => ({
  width: '100%',
  width: '100%',
  height: '100%',
  bgcolor: modeCol(mode).containerColor,
  overflowY: 'auto',
  borderRadius: 0,
  border: `1px solid ${modeCol(mode).borders}`
})

export const ListBox = () => ({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  padding: '8px',
  cursor: 'pointer'
})

export const TypoStyle = (mode) => ({
  color: modeCol(mode).textColor,
  fontWeight: 'bold',
  p: 1
})

export const ListLoadingStyle = () => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
})

// ======================================================>
// =============== User Message Intries =================>
// ======================================================>

export const userEntriesContainer = () => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%'
})


export const userEntriesIconOne = () => ({
  display: { xs: 'block', md: 'none' }
})

export const userEntriesBoxOne = () => ({
  display: { xs: 'flex', md: 'none' },
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer'
})

export const userEntriesHorizontalOne = (mode) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 0,
  p: 2,
  border: `1px solid ${modeCol(mode).borders}`
})

export const userEntriesHorizontalTwo = () => ({
  display: { xs: 'block', md: 'none' }
})

export const userTypoOne = (mode) => ({
  fontWeight: 'bold',
  ml: 2,
  color: modeCol(mode).textColor
})

export const userEntriesList = (mode) => ({
  overflowY: "auto",
  display: 'grid',
  padding: 0,
  marginBottom: { xs: '75px', md: 0 },
})

export const userEntriesLoading = () => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%'
})

export const userEntriesHorizontalThree = (mode) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flex: 0,
  p: 2,
  border: `1px solid ${modeCol(mode).borders}`
})

export const userEntriesBoxThree = () => ({
  display: { xs: 'flex', md: 'none' },
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer'
})

export const userEntriesStackOne = (mode) => ({
  border: `1px solid ${modeCol(mode).borders}`,
  height: '100%'
})

export const InputStylesUserEntries = (mode) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': { borderColor: modeCol(mode).borders },
    '& .MuiInputLabel-root': { color: modeCol(mode).textColor },
    '&:hover fieldset': { borderColor: modeCol(mode).borders },
    color: modeCol(mode).textColor,
    borderRadius: '20px',
  },
})

export const InputPropsStyleUserEntries = (mode) => ({
  style: {
    backgroundColor: modeCol(mode).cardColor,
    color: modeCol(mode).textColor
  }
})

export const InputLabelPropsStyleUserEntries = (mode) => ({
  style: {
    color: modeCol(mode).textColor,
  }
})

export const SmallMessageIconBox = () => ({
  display: { xs: 'flex', md: 'none' },
  justifyContent: 'center',
  alignItems: 'center',
})

export const InviteFriendBox = () => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export const messageCircle = (mode) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '25px',
  height: '25px',
  bgcolor: modeCol(mode).brandColor,
  padding: '5px',
  borderRadius: '50%',
  fontSize: '10px',
  color: '#FFFFFF',
})

// ======================================================>
// =============== Top Twinlies Component ===============>
// ======================================================>

export const MenuContainer = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  
  @media (max-width: 900px) {
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
  }
`;


export const TwinlyItem = styled(Stack)`
  margin: 10px 0;
  cursor: pointer;
  text-align: center;
  padding: 5px;

  @media (max-width: 900px) {
    margin: 10px 10px;
  }
`;

export const styledAvatar = (_id, activeItem, mode) => ({
  height: 50,
  width: 50,
  backgroundColor: "lightgray",
  border: _id === activeItem ? `5px solid ${modeCol(mode).brandColor}` : `1px solid ${modeCol(mode).brandColor}`
})

export const avatarText = (mode) => ({
  fontSize: '10px',
  color: modeCol(mode).textColor,
  mt: 1
})


