import {
    Alert,
    Button,
    Container,
    Stack,
    Typography,
  } from "@mui/material";
  import { Box } from "@mui/system";
  import React, { useState } from "react";
  import { Link, useNavigate, useSearchParams } from "react-router-dom";
  import { isLength } from "validator";
  import { login, resetPassword } from "../../api/users";
  import ErrorAlert from "../ErrorAlert";
  import { loginUser } from "../../helpers/authHelper";
  import Copyright from "../Copyright";
  import PasswordInput from "../PasswordInput";

  const ResetPasswordView = () => {
    const navigate = useNavigate();
  
    const [formData, setFormData] = useState({
      password: "",
      confirmPassword: "",
    });
  
    const [serverError, setServerError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const resetCode = searchParams.get("code");
    const email = searchParams.get("email");
  
    const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      if (formData.password !== formData.confirmPassword) {
        setServerError("Passwords do not match");
        return;
      }
  
      if (!isLength(formData.password, { min: 8 })) {
        setServerError("Password must be at least 8 characters");
        return;
      }
  
      const data = await resetPassword(email, formData.password, resetCode);
      if (data.error) {
        setServerError(data.error);
      } else {
        navigate("/login");
      }
    };
  
    return (
      <Container maxWidth={"xs"} sx={{ mt: 6 }}>
        <Stack alignItems="center">
          <Typography variant="h2" color="text.secondary" sx={{ mb: 6 }}>
            <Link to="/" color="inherit" underline="none">
              TwinlyAI
            </Link>
          </Typography>
          <Typography variant="h5" gutterBottom>
            Enter new password
          </Typography>

          <Box component="form" onSubmit={handleSubmit}>

            <PasswordInput
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              label="Password"
              required
            />
            <PasswordInput
              id="confirmPassword"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              label="Confirm Password"
              required
            />
  
            <ErrorAlert error={serverError} />
            <Button type="submit" fullWidth variant="contained" sx={{ my: 2 }}>
              Submit
            </Button>
          </Box>
          <Box sx={{ mt: 3 }}>
            <Copyright />
          </Box>
        </Stack>
      </Container>
    );
  };
  
  export default ResetPasswordView;
  