import { IconButton, Stack, Typography, useTheme } from "@mui/material";
import HorizontalStack from "./util/HorizontalStack";

import React, { useState } from "react";
import { FaRegHeart, FaHeart } from "react-icons/fa";

import { IconContext } from "react-icons/lib";
import { useNavigate } from "react-router-dom";
import { isLoggedIn } from "../helpers/authHelper";
import { modeCol } from "../functions/colors";
import { useGlobalContext } from "../context/context";

const LikeBox = (props) => {
  const { mode } = useGlobalContext();
  const { likeCount, onLike } = props;
  const theme = useTheme();
  const [liked, setLiked] = useState(props.liked);

  const navigate = useNavigate();

  const handleLike = (e) => {
      e.stopPropagation();
    if (isLoggedIn()) {
      const newLikedValue = !liked;
      setLiked(newLikedValue);
      onLike(newLikedValue);
    } else {
      navigate("/login");
    }
  };

  return (
    <HorizontalStack alignItems="center">
      <IconButton sx={{ padding: 0 }} onClick={handleLike}>
        {liked ? (
          <IconContext.Provider value={{ color: "#F88379" }}>
            <FaHeart size={16} />
          </IconContext.Provider>
        ) : (
          <FaRegHeart color={modeCol(mode).textColor} size={16} />
        )}
      </IconButton>
      <Typography 
        variant="subtitle2"
        color={modeCol(mode).textColor}
        sx={{ fontWeight: "bold" }}>
          {likeCount}
      </Typography>
    </HorizontalStack>
  );
};

export default LikeBox;
