import React, { useState, useEffect } from 'react';
import { GoHubot } from "react-icons/go";
import { IoPerson } from "react-icons/io5";
import { modeCol } from '../../functions/colors';
import HorizontalStack from '../util/HorizontalStack';
import { botText, switchBot, switchBox } from './Styles';
import { useGlobalContext } from '../../context/context';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { GCLOUD_PUBLIC_BUCKET_URL } from "../../config";
import { MdOutlineExpandMore, MdExpandLess } from "react-icons/md";
import { Avatar, Box, Button, Stack, Switch, Typography } from '@mui/material';
import {getRecommendedMessages} from "../../api/messages";
import Loading from "../Loading";
import UserAvatar from '../UserAvatar';

// SWITCHING HUMAN AND BOT

const SwitchBotAndHuman = ({ isPerson, handleChangeStatus, open }) => {
    const { mode } = useGlobalContext();
    return (
        <Stack>
            <Box sx={switchBot()}>
                <Switch 
                    onClick={handleChangeStatus} 
                    defaultChecked={isPerson} 
                    sx={switchBox(mode)}
                />
                  {isPerson ? ( 
                    <IoPerson color={modeCol(mode).brandColor} fontSize={30} />
                  ) : (
                    <GoHubot color={modeCol(mode).brandColor} fontSize={30} />
                  )}
            </Box>
            <Typography sx={botText(mode, open)}>
                {isPerson ? "Human" : "Bot"}
            </Typography>
        </Stack>
    )
}

// Main Component
const RecommendedMessages = ({ open, setOpen, showBots, setShowBots, currentConvo, convoIdFromSocket, messages, currentBot, updateInitialVal }) => {
    const { username, profilePicUrl } = currentBot

    // Global
    const { user, mode } = useGlobalContext();

    // Local
    const [isPerson, setIsPerson] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [suggestionsLoading, setSuggestionsLoading] = useState(true);
    const handleChangeStatus = () => {
        setIsPerson(!isPerson)
    }
    const handleOpenAndClose = () => {
        setOpen(!open)
    }


    const setSuggestedMessages = async () => {
        console.log("in setSuggestedMessages");
        console.log(`currentConvo is ${JSON.stringify(currentConvo,null,2)}`);

        setSuggestions([]);
        let suggestorId = currentBot._id;
        if (!suggestorId){
            suggestorId = user.userId;
        }
        
        let convoId = currentConvo._id;

        if (convoIdFromSocket){
            convoId = convoIdFromSocket;
        }

        const response = await getRecommendedMessages(suggestorId, convoId, user);
        const fetched_suggestions = response["suggestions"];
        let formatted_suggestions = [];
        for (let i = 0; i< fetched_suggestions.length; i++){
            formatted_suggestions.push({id: i, text: fetched_suggestions[i]})
        }
        setSuggestions(formatted_suggestions);
    }


    useEffect(() => {
        updateInitialVal("");
        setSuggestedMessages();
        
        
      }, [currentBot, messages, convoIdFromSocket]);


    return (
        <Stack sx={{ bgcolor: modeCol(mode).containerColor }}>
            {/* ================================== OPEN CLOSE BUTTON =========================================== */}

            {/* <Box sx={{ display: 'flex', justifyContent: open ? 'end' : 'space-between', alignItems: 'center', padding: '2px 10px' }}>
                {!open && (
                    // <SwitchBotAndHuman isPerson={isPerson} handleChangeStatus={handleChangeStatus} open={open} />
                    // <Box />
                    <Typography color={modeCol(mode).textColor}>Respond as your twinly </Typography>
                )}
                {open ? (
                    <MdOutlineExpandMore 
                        cursor='pointer' 
                        fontSize={25} 
                        color={modeCol(mode).textColor} 
                        onClick={() => { handleOpenAndClose(); setShowBots(false); }}
                    />
                ) : (
                    <MdExpandLess
                        cursor='pointer'
                        fontSize={25}
                        color={modeCol(mode).textColor}
                        onClick={handleOpenAndClose}
                    />
                )}
            </Box> */}

            {/* ================================= Horizontal Stack (Bot/human + suggestions) ================================== */}

            <HorizontalStack sx={{ display: open ? 'flex' : 'none', justifyContent: 'space-between', flex: 0, p: '10px' }}>

                {/*  ================================= BOT OR HUMAN SWITCH (disabled for now) ====================================== */}

                {/* <SwitchBotAndHuman isPerson={isPerson} handleChangeStatus={handleChangeStatus} open={open} /> */}
                <Box />

                {/* ======================================== Suggestions ===================================== */}

                <Box sx={{ width: '100%', maxHeight: '100px', overflowX: 'auto', textAlign: 'right' }}>

                    {/* TOP */}
                    <Box sx={{ display: 'flex', justifyContent: 'end', padding: '5px 10px' }}>
                        <Typography sx={{ p: '5px 10px', color: modeCol(mode).textColor }}>Suggestions</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                            <RestartAltIcon sx={{ fontSize: '25px' }} color="primary" onClick={setSuggestedMessages}  />
                        </Box>
                        <Avatar
                            sx={{
                              height: 35,
                              width: 35,
                              cursor: 'pointer',
                              margin: '0 8px',
                              backgroundColor: "lightgray",
                              border: "1px solid purple"
                            }}
                            src={profilePicUrl ? GCLOUD_PUBLIC_BUCKET_URL + profilePicUrl : `https://robohash.org/${username}`}
                            onClick={() => setShowBots(!showBots)}
                        />
                    </Box>

                    {/* BOTTOM (Suggested Items) */}

                    { suggestions.length == 0 ? ( <Loading /> )
                        : suggestions.map((item) => {
                            const { id, text } = item
                            return (
                                <Button 
                                    key={id}
                                    sx={{ display: 'inline-block', margin: '5px 10px' }} 
                                    variant="outlined"
                                    onClick={() => updateInitialVal(text)}
                                >
                                    {text}
                                </Button>
                            )
                        })
                    }

                </Box>

            </HorizontalStack>
        </Stack>
    )
}

export default RecommendedMessages
