import Loading from "./Loading";
import './RecommendedTwinlies.css';
import { getRandomBots } from "../api/users";
import { getBotsByCategory } from "../api/bot_behavior";
import { modeCol } from "../functions/colors";
import { useNavigate } from "react-router-dom";
import { cutText } from "../functions/cutText";
import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../context/context";
import { bot_options, default_bot_image } from "../data/data";
import { Button, Card, Stack, Typography, CardActionArea, Box, CardContent, CardMedia } from "@mui/material";
import { GCLOUD_PUBLIC_BUCKET_URL } from "../config";
import { UserNameAndIcon } from "./UserTypeIcon";

// =============================== Twinly Card =========================>

const TwinlyCard = (props) => {
  const { mode } = useGlobalContext();

  const formattedName = cutText(props.name, 20);
  const formattedDescription = cutText(props.description, 65);

  return (
    <Card sx={{ 
      width: '310px',
      height: '120px',
      bgcolor: modeCol(mode).cardColor,
      borderRadius: 5,
      padding: '5px',
      border: `1px solid ${modeCol(mode).borders}`,
    }}>
      <CardActionArea sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <CardMedia
          component="img"
          image={props.media ? GCLOUD_PUBLIC_BUCKET_URL + props.media : default_bot_image}
          alt={props.name}
          sx={{
            height: 100,
            width: 100,
            borderRadius: '50%',
            objectFit: 'cover',
          }}
        />
        <CardContent sx={{ textAlign: 'center', width: '100%' }}>
          <Typography sx={{ display: 'flex', whiteSpace: 'normal' }} color={modeCol(mode).textColor} gutterBottom component="div">
            {formattedName}
            <UserNameAndIcon type={props.type} />
          </Typography>
          <Typography color={modeCol(mode).exploreCard} variant="body4" sx={{ whiteSpace: 'normal' }}>
            {formattedDescription}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

// =============================== Horizontal Scrolls =========================>

const HorizontalScroller = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [bots, setBots] = useState(null);

  const fetchBots = async () => {
    setLoading(true);
    if (!props.activeOption){
      if (props.excludeCategory){
        const data = await getRandomBots({ size: 10 , excludeCategory: props.excludeCategory});
        setLoading(false);
        setBots(data);  
      }
      else{
        const data = await getRandomBots({ size: 10 });
        setLoading(false);
        setBots(data);
      }
      
    }
    else{
      if (props.activeOption == "Games"){
        const data = await getRandomBots({ size: 40 });
        setLoading(false);
        setBots(data); 
      }
      else {
        const botsByCategory = await getBotsByCategory({ category: props.activeOption});
        setLoading(false);
        setBots(botsByCategory);
      }
    }
    
  };

  useEffect(() => {
    console.log(bots)
    fetchBots();
    
  }, [props.activeOption]);


  return (
    <div style={{ backgroundColor: 'transparent' }} className="scrollmenu">
      {loading ? (<Loading />) : ( bots &&
        bots.map((bot) => (
          <a key={bot.username} onClick={() => navigate("/messenger", { state: { user: bot } })}>
            <TwinlyCard 
              id={bot.username} 
              name={bot.username} 
              media={bot.profilePicUrl}
              description={bot.biography} 
              type={bot.type}
            />
          </a>
        ))
      )}
    </div>
  );

};

//  ============================= Main container =================================>

const RecommendedTwinlies = (props) => {
  const { mode } = useGlobalContext();
  const [activeButton, setActiveButton] = useState(0);
  const [activeOption, setActiveOption] = useState("Games");
  const handleButtonClick = (id, value) => {
    setActiveButton(id);
    if (value == "You-Twinlies"){
      setActiveOption("You");
    }
    else{
      setActiveOption(value);
    }
  };


  return (
    <>
      <Stack spacing={2}>

        {/* RECOMMENDED */}

        <Card sx={{ bgcolor: modeCol(mode).cardColor, border: `1px solid ${modeCol(mode).borders}` }}>
          <Typography color={modeCol(mode).textColor}> <b>Recommended for you</b> </Typography>
          <HorizontalScroller excludeCategory={"You"}/> 
        </Card>

        {/* FEATURED */}

        <Box>
          <Typography color={modeCol(mode).textColor}><b>Featured Twinlies</b> </Typography>
          <HorizontalScroller excludeCategory={"You"}/>
        </Box>

        {/* OPTIONAL CHARACTERS */}

        <Box>
          <Typography color={modeCol(mode).textColor} sx={{ padding: '20px 0' }}><b>Choose a category</b></Typography>

          {/* CHARACTER OPTIONS START */}
          
          <Box sx={{ bgcolor: 'transparent' }} className="scrollmenu">
            {bot_options.map((option) => (
              <Button
                key={option.id}
                onClick={() => handleButtonClick(option.id, option.value)}
                sx={{
                  display: 'inline-block',
                  maxWidth: '100%',
                  marginRight: '20px',
                  bgcolor: activeButton === option.id ? '#1B4977' : 'transparent',
                  color: activeButton === option.id ? "#FFFFFF" : "",
                  '&:hover': {
                  bgcolor: '#1B4977',
                  color: '#FFFFFF',
                },
                }}
                variant="outlined"
              >
                {option.value}
              </Button>
            ))}
          </Box>

          {/* CHARACTER OPTIONS END */}

          <HorizontalScroller activeOption={activeOption} /> 
        </Box>

      </Stack>
    </>
  );
};

export default RecommendedTwinlies;
