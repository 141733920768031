import Messages from "./Messages";
import { useLocation } from "react-router-dom";
import { Card, Grid, Box, Modal, Typography, TextField, Button } from "@mui/material";
import { modeCol } from "../../functions/colors";
import { useGlobalContext } from "../../context/context";
import UserMessengerEntries from "./UserMessengerEntries";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { checkIfExists, fetchConversations, getConversation } from "./functions";
import { inviteFriends } from "../../api/users";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  gap: 5,
  p: 4,
};

const MessengerContainer = () => {
  // Global
  const { mode, mobile, user } = useGlobalContext();
  // Local
  // Check if it exists
  const { state } = useLocation();
  let newConservant = useMemo(() => (
    state?.user
  ), [state?.user]);
  
  const [loading, setLoading] = useState(true);
  const [conservant, setConservant] = useState(newConservant);
  const [conversations, setConversations] = useState([]);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [inviteSent, setInviteSent] = useState(false);
  const [emailToInvite, setEmailToInvite] = useState("");


  let profileId = state && state.profileId;
  let profileUsername = state && state.profileUsername;
  let profileDisplayPic = state && state.profileDisplayPic;
  ({ profileId, profileUsername, profileDisplayPic } = checkIfExists(user, profileId, profileUsername, profileDisplayPic))

  useEffect(() => {
    setConservant(newConservant);
  }, [newConservant]);

  const handleCloseInviteModal = () => {
    setShowInviteModal(false);
    setInviteSent(false);
  }


  // Fetch Conversations
  useEffect(() => {
    setConservant(newConservant);
    fetchConversations(user, newConservant, setConservant, setConversations, profileId, setLoading);
  }, [profileId, user, newConservant]);

  const getConversationTest = useCallback( 
    (conversations, conservantId) => (
      getConversation(conversations, conservantId)
    ), [getConversation]
  );

  const sendInviteEmail = async() => {
    inviteFriends(emailToInvite.trim(), user.username);
    // setShowInviteModal(false);
    setInviteSent(true);
  }

  const handleChange = (e) => {
    setEmailToInvite(e.target.value);
  };

  return (
    <>
      <Modal
        open={showInviteModal}
        onClose={handleCloseInviteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} alignItems="center" justifyContent="center">
          { inviteSent ? (
            <Typography id="modal-modal-title" variant="h6" component="h2" textAlign="center">
              Invite sent! Your friend will automatically be added to your conversations when they accept your invitation.
            </Typography>
          ) : (
            <>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Invite your friends!
            </Typography>
            <TextField style={{paddingTop: '10px', paddingBottom: '10px'}} id="email-input" onChange={handleChange} label="Your friend's email address" variant="outlined" fullWidth />
            <Button variant="contained" onClick={sendInviteEmail}>Send invite!</Button>
            </>
          )

          }
          
        </Box>
      </Modal>

      <Box sx={{ height: '100vh' }}>
        <Card sx={{ padding: 0, bgcolor: modeCol(mode).cardColor }}>
          <Grid container>
            <Grid item xs={12} md={3} sx={{ height: '100vh', display: conservant && { xs: 'none', md: 'block' }}}>
              <UserMessengerEntries
                conservant={conservant}
                conversations={conversations}
                setConservant={setConservant}
                setShowInviteModal={setShowInviteModal}
                profileId={profileId}
                profileUsername={profileUsername}
                profileDisplayPic={profileDisplayPic}
                loading={loading}
                setLoading={setLoading}
              />
            </Grid>

            <Grid item xs={12} md={9} sx={{ height: '100vh', display: !conservant && { xs: 'none', md: 'block' } }}>
              <Messages
                conservant={conservant}
                conversations={conversations}
                setConservant={setConservant}
                setConversations={setConversations}
                getConversation={getConversationTest}
                profileId={profileId}
                profileUsername={profileUsername}
                profileDisplayPic={profileDisplayPic}
                mobile={mobile}
              />
            </Grid>
          </Grid>
        </Card>
      </Box>
    </>
  );
};

export default MessengerContainer;
