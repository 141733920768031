import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Avatar, Stack, Typography } from '@mui/material';
import { modeCol } from '../functions/colors';
import { useGlobalContext } from '../context/context';
import { GCLOUD_PUBLIC_BUCKET_URL } from "../config";



const TwinlyType = (props) => {
  const navigate = useNavigate();
  const { mode } = useGlobalContext();

  return (
      <Stack key={props.id} sx={{ margin: '12px auto' }} direction="column" justifyContent="center" alignItems="center" spacing={1}>
          {props.local ? (<Avatar
            sx={{
              height: 120,
              width: 120,
              backgroundColor: "lightgray",
              border: "1px solid purple"
            }}
            src={props.sourceImg}
            onClick={() => navigate("/create/questions", {state: {type: props.text}})}
          />): 
          (<Avatar
            sx={{
              height: 120,
              width: 120,
              backgroundColor: "lightgray",
              border: "1px solid purple"
            }}
            src={props.sourceImg ? GCLOUD_PUBLIC_BUCKET_URL + props.sourceImg : "https://robohash.org/"+props.text}
            onClick={() => navigate("/create/questions", {state: {type: props.text}})}
          />)}
          
          <Typography color={modeCol(mode).textColor} variant="subtitle1" textAlign="center"><b>{props.text}</b></Typography>
      </Stack>
  );
};

export default TwinlyType