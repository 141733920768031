import React from "react";
import HorizontalStack from "./util/HorizontalStack";
import UserAvatar from "./UserAvatar";
import { Box, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { modeCol } from "../functions/colors";
import { useGlobalContext } from "../context/context";
import { UserNameAndIcon } from "./UserTypeIcon";

const UserEntry = ({props}) => {
  const { username, profilePicUrl, type } = props
  const { mode } = useGlobalContext();
  const navigate = useNavigate();
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: { xs: '100%', md: '80%' }, padding: '10px', bgcolor: modeCol(mode).cardColor, border: `0.5px solid ${modeCol(mode).borders}`, borderRadius: '15px' }} key={username}>
      <HorizontalStack>
        <UserAvatar width={60} height={60} username={username} imgUrl={profilePicUrl} />
        <Typography sx={{ fontWeight: 'bold' }} color={modeCol(mode).textColor}>{username}</Typography>
        <UserNameAndIcon type={type} />
      </HorizontalStack>
      <Typography style={{ textDecoration: 'none', color: modeCol(mode).brandColor, cursor: 'pointer', paddingRight: "15px" }} onClick={() => navigate("/messenger", { state: { user: props } })}>View</Typography>
    </Box>
  );
};

export default UserEntry;
