import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../context/context';
import EnterTopTitle from '../EnterTopTitle';
import EnterBottom from '../EnterBottom';
import { Box, Container, Stack, TextField, Typography } from '@mui/material';

const BioView = () => {

    // Global
    const { bioData, setBioData, path, setPath } = useGlobalContext();

    // Local
    const navigate = useNavigate();
    const [specialInfo, setSpecialInfo] = useState(bioData.specialInfo || '');
    const [friendsInfo, setFriendsInfo] = useState(bioData.friendsInfo || '');

    // NEXT
    const handleNext = () => {
        if (!specialInfo.trim() || !friendsInfo.trim()) {
            alert("Please fill in both fields.");
        } else {
            setBioData({ specialInfo, friendsInfo });
            setPath({...path, bioDone: true})
            navigate('/social');
        }
    };

    // PREV
    const handlePrev = () => {
        navigate('/subcategories');
    };

    return (
        <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', minHeight: '100vh' }}>

            {/* TOP */}

            <Box mt={5}>
                <EnterTopTitle title='TwinlyAI' textOne='Tell Us About Yourself' />
            </Box>

            {/* MIDDLE */}

            <Stack spacing={6} sx={{ margin: '20px 0' }}>

                <Box>
                    <Typography fontWeight={600} gutterBottom>
                        About You ( 1-2 sentences )
                    </Typography>
                    <TextField
                        placeholder='What Makes You Special? Unique Experiences? Biggest Fear?'
                        multiline
                        fullWidth
                        rows={4}
                        value={specialInfo}
                        onChange={(e) => setSpecialInfo(e.target.value)}
                    />
                </Box>

                <Box>
                    <Typography fontWeight={600} gutterBottom>
                        About Your People ( 1-2 sentences)
                    </Typography>
                    <TextField
                        placeholder='Tell Us About Your Friends. Who is Your Ideal Friend?'
                        multiline
                        fullWidth
                        rows={4}
                        value={friendsInfo}
                        onChange={(e) => setFriendsInfo(e.target.value)}
                    />
                </Box>
            </Stack>

            {/* BOTTOM */}

            <EnterBottom leftText='prev' rightText='next' leftFunc={handlePrev} rightFunc={handleNext} />

        </Container>
    );
};

export default BioView;
