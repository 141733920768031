import Loading from "../Loading";
import { Box } from "@mui/system";
import { Stack } from "@mui/material";
import SendMessage from "./SendMessage";
import MessagesTop from "./MessagesTop";
import MessagesEmpty from "./MessagesEmpty";
import MessagesMiddle from "./MessagesMiddle";
import { socket } from "../../helpers/socketHelper";
import { loadingStack, messagesBox } from "./Styles";
import { isLoggedIn } from "../../helpers/authHelper";
import RecommendedMessages from "./RecommendedMessages";
import { useGlobalContext } from "../../context/context";
import React, { useEffect, useRef, useState } from "react";
import { getMessages, sendMessage, updateLastSeen, sendNotificationEmail } from "../../api/messages";
import SuggestionBots from "./SuggestionBots";


const Messages = (props) => {

  // Global
  const { mode } = useGlobalContext();

  // Local
  const user = isLoggedIn();
  const messagesEndRef = useRef(null);
  const [messages, setMessages] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sendFieldInitialVal, setSendFieldInitialVal] = useState("");
  const [convoIdFromSocket, setConvoIdFromSocket] = useState(null);

  const [currentBot, setCurrentBot] = useState(user)
  const [showBots, setShowBots] = useState(false);

  // Open Suggested Messages
  const [open, setOpen] = useState(true);


  const conversationsRef = useRef(props.conversations);
  const conservantRef = useRef(props.conservant);
  const messagesRef = useRef(messages);

  useEffect(() => {
    conversationsRef.current = props.conversations;
    conservantRef.current = props.conservant;
    messagesRef.current = messages;
  });

  // ===================== Filtering Directions ======================>

  let conversation =
    props.conversations &&
    props.conservant &&
    props.getConversation(props.conversations, props.conservant._id);

  const setDirection = (messages) => {
    messages.forEach((message) => {
      // if (message.sender._id === user.userId) {
      if (message.sender._id === props.profileId) {
        message.direction = "from";
      } else {
        message.direction = "to";
      }
    });
  };
  

  //  ==================== Fetch All Messages ========================>

  const fetchMessages = async () => {
    if (conversation) {
      if (conversation.new) {
        setLoading(false);
        setMessages(conversation.messages);
        return;
      }

      setLoading(true);

      const data = await getMessages(user, conversation._id);
      setDirection(data);

      if (data && !data.error) {
        const updatelastseen = updateLastSeen(user, conversation._id);
        setMessages(data);
      }

      setLoading(false);
    }
  };


  useEffect(() => {
    socket.on('mongoNotify', (senderId, receiverId, username, content, convo_id) => {
      // if (user.userId == receiverId){
      if (props.profileId == receiverId){
        setConvoIdFromSocket(convo_id);
        handleReceiveMessage(senderId, username, content);
      }
    });

    return () => {
        socket.off('mongoNotify');
    };
  }, []);


  useEffect(() => {
    fetchMessages();
    console.log("Conversations")
    console.log(conversation)
    console.log("Messages")
    console.log(messages)
  }, [props.conservant, props.profileId, conversation?._id]);

  // sendFieldInitialVal
  // { state: { user: null, profileId: id, profileUsername: selected_profile.username, profileDisplayPic: selected_profile.profilePicUrl } })


  // ================== Scroll to bottom Automatically =================>

  useEffect(() => {
    if (messages) {
      scrollToBottom();
    }
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView();
  };

  // ==================== Sending process of message ==================>

  
  const handleSendMessage = async (content) => {
    // Get current time
    const currentDate = new Date();
    const formattedCurrentDate = currentDate.toISOString();

    // User Message
    const newMessage = { profileId: props.profileId, profileUsername: props.profileUsername, direction: "from", content, createdAt: formattedCurrentDate };
    const newMessages = [newMessage, ...messages];

    if (conversation.new) {
      conversation.messages = [...conversation.messages, newMessage];
    }

    let newConversations = props.conversations.filter(
      (conversationCompare) => conversation._id !== conversationCompare._id
    );

    newConversations.unshift(conversation);

    props.setConversations(newConversations);

    setMessages(newMessages);

    await sendMessage(user, newMessage, conversation.recipient._id);

    socket.emit(
      "send-message",
      conversation.recipient._id,
      props.profileUsername,
      content
    );

    if (conversation.recipient.type == "human"){
      const notificationEmail = sendNotificationEmail(user, conversation._id, conversation.recipient._id, conversation.recipient.email)
    }
    

  };


  // ==================== Receiving process of message ==================>

  const handleReceiveMessage = (senderId, username, content) => {
    const newMessage = { profileId: props.profileId, profileUsername: props.profileUsername, direction: "to", content, createdAt: Date.now() };

    const conversation = props.getConversation(
      conversationsRef.current,
      senderId
    );

    console.log(username + " " + content);

    if (conversation) {
      if (conversation.recipient._id == senderId){
        let newMessages = [newMessage];
        if (messagesRef.current) {
          newMessages = [...newMessages, ...messagesRef.current];
        }

        setMessages(newMessages);

        if (conversation.new) {
          conversation.messages = newMessages;
        }
      }
      
      conversation.lastMessageAt = Date.now();

      let newConversations = conversationsRef.current.filter(
        (conversationCompare) => conversation._id !== conversationCompare._id
      );

      newConversations.unshift(conversation);

      props.setConversations(newConversations);
    } else {
      const newConversation = {
        _id: senderId,
        recipient: { _id: senderId, username },
        new: true,
        messages: [newMessage],
        lastMessageAt: Date.now(),
      };
      props.setConversations([newConversation, ...conversationsRef.current]);
    }

    const updatelastseen = updateLastSeen(user, conversation._id);

    scrollToBottom();
  };


  const updateSendFieldInitialVal = (updatedVal) => {
    setSendFieldInitialVal(updatedVal);
  }

  useEffect(() => {
    socket.on("receive-message", handleReceiveMessage);
  }, []);


  return props.conservant ? (
    <Box sx={messagesBox()}>
      {messages && conversation && !loading ? (
        <>
          {/* TOP */}
          <MessagesTop 
            mobile={props.mobile} 
            conservant={props.conservant} 
            setConservant={props.setConservant} 
          />

          {/* MIDDLE */}
          <MessagesMiddle
            messages={messages}
            profileId={props.profileId}
            conservant={props.conservant}
            messagesEndRef={messagesEndRef}
            profileUsername={props.profileUsername}
            profileDisplayPic={props.profileDisplayPic}
          />

          {/* Suggestion bots */}
          <Box sx={{ display: showBots ? "block" : "none" }}>
            <SuggestionBots setShowBots={setShowBots} currentBot={currentBot} setCurrentBot={setCurrentBot} />
          </Box>

          {/* BOTTOM */}
          {/* {props.profileId != user.userId && ( */}
            <RecommendedMessages 
              open={open}
              setOpen={setOpen}
              currentBot={currentBot}
              currentConvo={conversation}
              convoIdFromSocket={convoIdFromSocket}
              messages={messages}
              showBots={showBots}
              setShowBots={setShowBots}
              updateInitialVal={updateSendFieldInitialVal} 
            />
          {/* )} */}
          <SendMessage 
            suggestionsMessage={sendFieldInitialVal} 
            onSendMessage={handleSendMessage} 
          />
        </>

      ) : (
        <Stack sx={loadingStack(mode)} justifyContent="center">
          <Loading />
        </Stack>
      )}
    </Box>
  ) : (
    <MessagesEmpty />
  );
};

export default Messages;
