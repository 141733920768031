import React, { useState, useEffect } from "react";
import { IconButton, InputAdornment, Button, Container, Stack, TextField, Typography, Box, MenuItem } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { isLength, isEmail, contains, isMobilePhone } from "validator";
import { signup } from "../../api/users";
import { loginUser } from "../../helpers/authHelper";
import ErrorAlert from "../ErrorAlert";
import Copyright from "../Copyright";
import { useGlobalContext } from "../../context/context";
import { modeCol } from "../../functions/colors";

const SignupView = () => {

  // GLOBAL ========================================>

  const { mode, formData, setFormData, path, setPath, setHasRegistered } = useGlobalContext();

  // LOCAL =========================================>

  const navigate = useNavigate();
  const [serverError, setServerError] = useState("");
  const [errors, setErrors] = useState({});
  
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Target input values based on names ============>

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Clicking NEXT button =========================>

  const handleSubmit = async (e) => {
    e.preventDefault();

    try { 
      const errors = validate();
      if (Object.keys(errors).length !== 0) return;

      const data = await signup({ 
        username: formData.username, 
        email: formData.email, 
        password: formData.password, 
        // phoneNumber: formData.phoneNumber, 
        // dateofbirth: `${formData.year}-${formData.month}-${formData.day}` 
      });

      loginUser(data);
      console.log(data);
      setPath({ ...path, registerDone: true })
      setHasRegistered("done") // set this to pending after investor presentations
      setPath({ ...path, verifyDone: true })// remove this when re-enabling verification after investor presentations
      // navigate("/verify"); // disabling verification for now. Enable it after investor presentations
      navigate("/explore"); // remove this once you get the verification is working

    } catch (error) {
      setServerError(error.message);
      console.log(error)
    }
  };


  // Validate User input ================================>

  const validate = () => {
    const errors = {};

    if (!isEmail(formData.email)) {
      errors.email = "Must be a valid email address";
    }

    if (!isLength(formData.username, { min: 6, max: 30 })) {
      errors.username = "Username must be between 6 and 30 characters long";
    }

    if (contains(formData.username, " ")) {
      errors.username = "Username must not contain spaces";
    }

    if (!isLength(formData.password, { min: 8 })) {
      errors.password = "Password must be at least 8 characters long";
    }

    // if (!isMobilePhone(formData.phoneNumber)) {
    //   errors.phoneNumber = "Must be a valid Phone Number";
    // }

    setErrors(errors);
    return errors;
  };

  useEffect(() => {
    validate();
  }, [formData]);

  // Working with YEARS, MONTHS AND DAYS logic.
  // We will genarate days based on years and month that user has selected.

  const generateDaysArray = (year, monthName) => {
    const monthIndex = months.findIndex(month => month === monthName); 
    const numDays = new Date(year, monthIndex + 1, 0).getDate();
    return Array.from({ length: numDays }, (_, index) => index + 1);
  };

  // Check and change day when user changes years or months.
  // If day is valid, then keep it otherwise chage it.

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      day: formData.day > generateDaysArray(formData.year, formData.month).length ? "" : formData.day
    }));
  }, [formData.year, formData.month]);

  const years = Array.from({ length: 50 }, (_, index) => new Date().getFullYear() - index);

  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const days = generateDaysArray(formData.year, formData.month);

  // MAIN PART

  return (

    <Container maxWidth={"xs"} sx={{ mt: 6 }}>

      <Stack alignItems="center">

        {/* ================================================= */}
        {/* ================== HEADER LOGIC ================= */}
        {/* ================================================= */}

        <Typography variant="h2" color="text.secondary" sx={{ mb: 6 }}>
          <Link to="/" color="inherit" underline="none">
            TwinlyAI
          </Link>
        </Typography>
        <Typography variant="h5" gutterBottom>
          Sign Up
        </Typography>
        <Typography color="text.secondary">
          Already have an account? <Link to="/login">Login</Link>
        </Typography>

        {/* ==================================================== */}
        {/* ================== USER INFO LOGIC ================= */}
        {/* ==================================================== */}

        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            label="Email"
            type="email"
            fullWidth
            margin="normal"
            required
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            // error={errors.email !== undefined}
            helperText={errors.email}
          />
          <TextField
            label="Username"
            fullWidth
            margin="normal"
            required
            id="username"
            name="username"
            value={formData.username}
            onChange={handleChange}
            // error={errors.username !== undefined}
            helperText={errors.username}
          />

          <TextField
            label="Password"
            type={showPassword ? "text" : "password"}
            fullWidth
            margin="normal"
            required
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            // error={errors.password !== undefined}
            helperText={errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {/* <TextField
            label="Phone Number"
            fullWidth
            margin="normal"
            required
            id="phoneNumber"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            // error={errors.username !== undefined}
            helperText={errors.phoneNumber}
          /> */}

          {/* =============================================== */}
          {/* ================== DATE LOGIC ================= */}
          {/* =============================================== */}
          {/* <Typography color={modeCol(mode).grayText}>Date of birth:</Typography>

          <Box sx={{ display: 'grid', gridTemplateColumns: { xs: 'repeat(1, 1fr)',  sm:'repeat(3, 1fr)', md: 'repeat(3, 1fr)'}, gap: { sm: '15px'}  }}>
            
            <TextField
              select
              label="Year"
              fullWidth
              margin="normal"
              required
              id="year"
              name="year"
              value={formData.year}
              onChange={handleChange}
            >
              {years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              select
              label="Month"
              fullWidth
              margin="normal"
              required
              id="month"
              name="month"
              value={formData.month}
              onChange={handleChange}
            >
              {months.map((month) => (
                <MenuItem key={month} value={month}>
                  {month}
                </MenuItem>
              ))}
            </TextField>
            
            <TextField
              select
              label="Day"
              fullWidth
              margin="normal"
              required
              id="day"
              name="day"
              value={formData.day}
              onChange={handleChange}
            >
              {days.map((day) => (
                <MenuItem key={day} value={day}>
                  {day}
                </MenuItem>
              ))}
            </TextField>
            
          </Box> */}

          
          {/* ================================================= */}
          {/* ================== BOTTOM LOGIC ================= */}
          {/* ================================================= */}

          <ErrorAlert error={serverError} />
          <Button type="submit" fullWidth variant="contained" sx={{ my: 2 }}>
            Next
          </Button>
        </Box>
        <Box sx={{ margin: '30px 0' }}>
          <Copyright />
        </Box>
      </Stack>
    </Container>
  );
};

export default SignupView;

