import { Avatar, Typography, useTheme } from "@mui/material";

import React from "react";
import HorizontalStack from "./util/HorizontalStack";
import { Link } from "react-router-dom";
import { modeCol } from "../functions/colors";
import { useGlobalContext } from "../context/context";
import UserTypeIcon from "./UserTypeIcon";

const ContentDetails = ({ username, userType, createdAt, edited, preview }) => {
  const { mode } = useGlobalContext();
  const theme = useTheme();
  return (
      <Link
        color="inherit"
        style={{ textDecoration: "none" }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        to={"/users/" + username}
      >
        <HorizontalStack alignItems="baseline">
          <Typography variant="subtitle1" color={modeCol(mode).textColor} gutterBottom>
              <b>{username}</b> · 
          </Typography>
          <UserTypeIcon userType={userType}/>
          {/* <Typography color={theme.palette.warning.light}>Follow</Typography> */}
        </HorizontalStack>
      </Link>
    // </HorizontalStack>
  );
};

export default ContentDetails;
