// const dotenv = require("dotenv");

// dotenv.config()

// let BASE_URL = "https://twinlyai-f606850c7919.herokuapp.com/";
// let BASE_URL = "https://twinlyai-webapp.wl.r.appspot.com/";
let BASE_URL = "http://localhost:4000/"
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  //BASE_URL = "http://localhost:4000/";
} else if (process.env.NODE_ENV === "production") {
  BASE_URL = "https://backend-l4ql.onrender.com/"
}
const GCLOUD_PUBLIC_BUCKET_URL = "https://storage.googleapis.com/twinlyai-webapp.appspot.com";

export { BASE_URL, GCLOUD_PUBLIC_BUCKET_URL };


