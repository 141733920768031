import React from "react";
import { selections } from "../data/data";
import { modeCol } from "../functions/colors";
import MainGridLayout from "./MainGridLayout";
import { useNavigate } from "react-router-dom";
import CardContent from '@mui/material/CardContent';
import HorizontalStack from "./util/HorizontalStack";
import { useGlobalContext } from "../context/context";
import { Button, Card, CardActionArea, Typography } from "@mui/material";


export const Section = (props) =>{
  const navigate = useNavigate();
  const { mode } = useGlobalContext();
  const { id, link, title, text1, text2 } = props
  return (
    <Card key={id} sx={{ height: '20%', width: '40%', bgcolor: modeCol(mode).cardColor, border: `1px solid ${modeCol(mode).borders}` }}>
      <CardActionArea onClick={() => navigate(link)} sx={{ height: '20vw'}}>
        <CardContent>
          <Typography sx={{fontWeight: 'bold'}} color={modeCol(mode).textColor} align="center" gutterBottom variant="h5" component="div">{title}</Typography>
          <Typography color={modeCol(mode).textColor} align="center" variant="body2">{text1}</Typography>  
          <Typography color={modeCol(mode).textColor} align="center" variant="body2">{text2}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};


const CreateTab = () =>{
  const navigate = useNavigate();
  const { mode } = useGlobalContext()
  return (
    <Card style={{height: '45vw', backgroundColor: modeCol(mode).cardColor}}>
      <Typography sx={{fontWeight: 'bold'}}  color={modeCol(mode).textColor} style={{padding:30}} variant="h5" textAlign="center">Complete your Profile</Typography>
      <HorizontalStack justifyContent="space-evenly">
        {selections.map((selection) => (<Section key={selection.id} {...selection} />))}
      </HorizontalStack>
      <div style={{ display: "flex", border:2, padding:20 }}>
        <Button style={{ marginLeft: "auto", backgroundColor: modeCol(mode).brandColor, color: modeCol(mode).textColor}} variant="contained" onClick={()=>navigate("/")}>I'll do it later</Button>
      </div>

    </Card>
  );
};


const OnBoardingContainer = () => {
  return (
    <MainGridLayout left={<CreateTab />}/>
  );
};

export default OnBoardingContainer;
