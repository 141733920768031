import React from 'react'
import { Box, Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import { useGlobalContext } from '../context/context';
import { modeCol } from '../functions/colors';
import { Link } from 'react-router-dom';

const NewSpaceCard = (props) => {
  const { mode } = useGlobalContext();
  return (
    <Card key={props.id} m={2} sx={{ width: "250px", margin: '0 auto', backgroundColor:  modeCol(mode).cardColor, border: `1px solid ${modeCol(mode).borders}`}}>
      <CardActionArea component={Link}  to={"/games/" + props.name}>
        <CardMedia
          component="img"
          height="140px"
          image={props.image}
          alt={props.name}
        />
        <CardContent>
          <Typography color={modeCol(mode).textColor} gutterBottom variant="h5" component="div" sx={{textAlign: 'center'}}>
            {props.name}
          </Typography>
          <Box sx={{ textAlign: 'center', fontStyle: 'italic', m: 1, fontSize: 'h8.fontSize', color: modeCol(mode).grayText }}>{props.id}</Box>
          <Typography color={modeCol(mode).grayText} variant="body2">
            {props.description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default NewSpaceCard