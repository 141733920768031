import React from 'react';
import { Box, Grid } from '@mui/material';

const MainLayout = ({ left, right }) => {
  return (
    <Box sx={{ overflow: 'hidden' }}>
      <Grid container spacing={4} justifyContent="center">
        {/* MOBILE TOP POSTS */}
        {right && (
          <Grid item xs={12} sx={{ display: { xs: "block", md: "none", overflow: 'auto' } }}>{right}</Grid>
        )}

        {/* DESKTOP */}
        <Grid item xs={12} md={right ? 8 : 12}>{left}</Grid>
        <Grid item md={3.5} sx={{ display: { xs: 'none', md: 'block' } }}>{right}</Grid>
      </Grid>
    </Box>

  );
};

export default MainLayout;
