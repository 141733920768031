import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { getNavLinks } from '../data/data';
import { modeCol } from '../functions/colors';
import { CgMoreVerticalO } from "react-icons/cg";
import { useGlobalContext } from '../context/context';
import { Box, List, ListItem, ListItemButton } from '@mui/material';


const ItemIcon = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledListItemButton = styled(ListItemButton)(({ active, brandColor, hover }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: active ? brandColor : 'transparent',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: active ? brandColor : hover,
    },
}));

const MainBottomNavbar = () => {

    const navLinks = getNavLinks();
    const { mode, activeLink } = useGlobalContext();

    const activeColor = (active) => {
        return active ? '#fff' : modeCol(mode).brandColor
    }

    return (
        <Box sx={{ position: 'fixed', width: '100%', bottom: 0, height: '60px', marginTop: '10px', display: { xs: 'block', sm: 'none' }, bgcolor: modeCol(mode).cardColor }}>
            <List sx={{ display: 'flex', width: '100%', padding: 0, bgcolor: modeCol(mode).cardColor }}>
                {navLinks.map((item) => {
                    const { id, name, link, icon } = item;
                    const active = activeLink() === name
                    return (
                        <ListItem sx={{ padding: '10px 0', display: 'flex', justifyContent: 'center', alignItems: 'center'  }} key={id}>
                            <Link to={link} style={{ textDecoration: 'none', color: 'inherit' }}>
                                <StyledListItemButton active={active} brandColor={modeCol(mode).brandColor} hover={modeCol(mode).hoverColor} >
                                    <ItemIcon>
                                      {icon(activeColor(active), 25)}
                                    </ItemIcon>
                                </StyledListItemButton>
                            </Link>
                        </ListItem>
                    );
                })}
                <ListItem sx={{ padding: '10px 0', display: 'flex', justifyContent: 'center', alignItems: 'center'  }}>
                    <Link to={'/'} style={{ textDecoration: 'none', color: 'inherit' }}>
                        <StyledListItemButton active={false} brandColor={modeCol(mode).brandColor} hover={modeCol(mode).hoverColor} >
                            <ItemIcon>
                              <CgMoreVerticalO fontSize={25} color={modeCol(mode).brandColor} />
                            </ItemIcon>
                        </StyledListItemButton>
                    </Link>
                </ListItem>
            </List>
        </Box>
  )
}

export default MainBottomNavbar