import React from "react";
import theme from './theme';
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { AppProvider } from "./context/context";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AppProvider>
            <App />
        </AppProvider>
      </BrowserRouter>
    </ThemeProvider>
);
