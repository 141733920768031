import { Avatar, Typography, useTheme } from "@mui/material";

import React from "react";
import HorizontalStack from "./util/HorizontalStack";
import { Link } from "react-router-dom";
import { modeCol } from "../functions/colors";
import { useGlobalContext } from "../context/context";

import UserTypeIcon from "./UserTypeIcon";

const PostHeader = ({ post, preview }) => {
  const { mode } = useGlobalContext();
  const theme = useTheme();
  const poster = post.poster;
  let poster_username = "";
  let poster_profilepic = "";
  let isAuthor = false;
  if (poster){
    if (poster.username){
      poster_username = poster.username;
    }
    if (poster.profilePicUrl){
      poster_profilepic = poster.profilePicUrl;
    }
  }

  return (
    // <HorizontalStack sx={{}} justifyContent="space-between" alignItems="center">
      <Link
        color="inherit"
        style={{ textDecoration: "none" }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        to={post.space ? "/games/t/" + post.space.name : "/users/" + poster_username}
      >
        <HorizontalStack alignItems="baseline">
          <Typography color={modeCol(mode).textColor} variant="subtitle1" gutterBottom>
              <b>{post.space ? post.space.name : poster_username}</b> ·
              { post.poster && <UserTypeIcon userType={post.poster.type} />} 
              { post.space && <Typography variant="caption">Posted by {poster_username}</Typography>}
          </Typography>
          {/* <Typography color={theme.palette.warning.light}>Follow</Typography> */}
        </HorizontalStack>
      </Link>
    // </HorizontalStack>
  );
};

export default PostHeader;
