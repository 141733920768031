import {
  Button,
  Card,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { AiFillCheckCircle, AiFillEdit, AiFillMessage } from "react-icons/ai";
import { FaRegHeart } from "react-icons/fa";
import { FaRegComment } from "react-icons/fa";
import { FaRegShareSquare } from "react-icons/fa";

import { Link, useNavigate } from "react-router-dom";
import { deletePost, likePost, unlikePost, updatePost } from "../api/posts";
import { isLoggedIn } from "../helpers/authHelper";
import PostHeader from "./PostHeader";

import LikeBox from "./LikeBox";
import UserAvatar from "./UserAvatar";
import PostContentBox from "./PostContentBox";
import HorizontalStack from "./util/HorizontalStack";
import Moment from "react-moment";

import {} from "react-icons/ai";
import ContentUpdateEditor from "./ContentUpdateEditor";
import Markdown from "./Markdown";

import "./postCard.css";
import { MdCancel } from "react-icons/md";
import { BiTrash } from "react-icons/bi";
import { BsReplyFill } from "react-icons/bs";
import UserLikePreview from "./UserLikePreview";
import { modeCol } from "../functions/colors";
import { useGlobalContext } from "../context/context";

import TwinlyType from './TwinlyType';
import starwars from "./images/discover-section-images/starwars.jpeg";
import leagueoflegends from "./images/discover-section-images/leagueoflegends.webp";
import overwatch from "./images/discover-section-images/overwatch.webp";



const PostCard = (props) => {

  const { mode } = useGlobalContext();

  const { preview, removePost } = props;
  let postData = props.post;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const user = isLoggedIn();
  const poster = postData.poster;
  let poster_username = "";
  let poster_profilepic = "";
  let isAuthor = false;
  if (poster){
    if (poster.username){
      poster_username = poster.username;
      isAuthor = user && user.username === postData.poster.username;
    }
    if (poster.profilePicUrl){
      poster_profilepic = poster.profilePicUrl;
    }
  }

  const theme = useTheme();
  const iconColor = theme.palette.primary.main;

  const [editing, setEditing] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [post, setPost] = useState(postData);
  const [likeCount, setLikeCount] = useState(post.likeCount);

  let maxHeight = null;




  if (preview === "primary") {
    maxHeight = 250;
  }

  const handleDeletePost = async (e) => {
    e.stopPropagation();

    if (!confirm) {
      setConfirm(true);
    } else {
      setLoading(true);
      await deletePost(post._id, isLoggedIn());
      setLoading(false);
      if (preview) {
        removePost(post);
      } else {
        navigate("/");
      }
    }
  };

  const handleEditPost = async (e) => {
    e.stopPropagation();

    setEditing(!editing);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const content = e.target.content.value;
    await updatePost(post._id, isLoggedIn(), { content });
    setPost({ ...post, content, edited: true });
    setEditing(false);
  };

  const handleLike = async (liked) => {
    
    if (liked) {
      setLikeCount(likeCount + 1);
      await likePost(post._id, user);
    } else {
      setLikeCount(likeCount - 1);
      await unlikePost(post._id, user);
    }
  };

  return (
    <Card sx={{ display: 'inline-block', bgcolor: modeCol(mode).cardColor, padding: { xs: 'none', md: 0 }, margin: { xs: '15px', md: 0 }, borderRadius: {  xs: "15px", md: props.borderRadiusZero ? 0 : "15" }, border: `0.5px solid ${modeCol(mode).borders}` }} className="post-card">
      <Box className={preview}>
        <HorizontalStack spacing={0} alignItems="initial">
          <Link
            color="inherit"
            style={{ textDecoration: "none" }}
            onClick={(e) => {
              e.stopPropagation();
            }}
            to={post.space ? "/games/t/" + post.space.name : "/users/" + poster_username}
          >
            <Stack
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
              sx={{
                width: "50px",
                padding: theme.spacing(2),
                paddingLeft: theme.spacing(4)
              }}
            >
              {
                post.space != null && post.space.name != null && post.space.name == "StarWarsOutlaws" ? (
                  <UserAvatar 
                    width={40} 
                    height={40} 
                    username={post.space ? post.space.name : poster_username}
                    imgUrl={"/users/65ed2ca7c6ad0a1da938a903_1716759002532.jpeg"}
                    sx={{top: "10px"}}/>
                ): post.space != null && post.space.name != null && post.space.name == "LeagueOfLegends" ? (
                  <UserAvatar 
                    width={40} 
                    height={40} 
                    username={post.space ? post.space.name : poster_username}
                    imgUrl={"/users/65ed2ca7c6ad0a1da938a903_1716759322598.jpeg"}
                    sx={{top: "10px"}}/>
                ): post.space != null && post.space.name != null && post.space.name == "Overwatch" ? (
                  <UserAvatar 
                    width={40} 
                    height={40} 
                    username={post.space ? post.space.name : poster_username}
                    imgUrl={"/users/65ed2ca7c6ad0a1da938a903_1716759448034.jpeg"}
                    sx={{top: "10px"}}/>
                ) : (
                  <UserAvatar 
                    width={40} 
                    height={40} 
                    username={post.space ? post.space.name : poster_username}
                    imgUrl={post.space ? post.space.imgUrl : poster_profilepic}
                    sx={{top: "10px"}}/>
                )
              }
              
            </Stack>
          </Link>
          <PostContentBox clickable={preview} post={post} editing={editing}>
            <HorizontalStack justifyContent="space-between" alignItems="start">
              <PostHeader
                post={post}
                preview={preview === "secondary"}
              />

              {/* <Box display="flex" justifyContent="end"> */}
              <HorizontalStack>
                {preview !== "secondary" && (
                    <>
                      {" "}
                      <Typography sx={{ fontSize: { xs: '10px', md: '12px' } }} color={modeCol(mode).textColor}>
                        <Moment fromNow>{post.createdAt}</Moment> {post.edited && <>(Edited)</>}
                      </Typography>
                    </>
                  )}
                {/* TODO: make this its own component or add into ContentDetails*/}
                {user &&
                  (isAuthor || user.isAdmin) &&
                  preview !== "secondary" && (
                    <HorizontalStack>
                      <IconButton
                        disabled={loading}
                        size="small"
                        onClick={handleEditPost}
                      >
                        {editing ? (
                          <MdCancel color={iconColor} />
                        ) : (
                          <AiFillEdit color={iconColor} />
                        )}
                      </IconButton>
                      <IconButton
                        disabled={loading}
                        size="small"
                        onClick={handleDeletePost}
                      >
                        {confirm ? (
                          <AiFillCheckCircle color={theme.palette.error.main} />
                        ) : (
                          <BiTrash color={theme.palette.error.main} />
                        )}
                      </IconButton>
                    </HorizontalStack>
                  )}
              </HorizontalStack>

            </HorizontalStack>

            {preview !== "secondary" &&
              (editing ? (
                <ContentUpdateEditor
                  handleSubmit={handleSubmit}
                  originalContent={post.content}
                />
              ) : (
                <Box
                  maxHeight={maxHeight}
                  overflow="hidden"
                  className="content"
                >
                  <Typography variant="body1" color={modeCol(mode).cardTextColor}>
                    <Markdown content={post.content} />
                  </Typography>
                </Box>
              ))}

            <HorizontalStack sx={{ mt: 2 }} justifyContent="space-between">
              <HorizontalStack spacing={2}>
                <LikeBox
                  likeCount={likeCount}
                  liked={post.liked}
                  onLike={handleLike}
                />

                <HorizontalStack>
                  <FaRegComment color={modeCol(mode).textColor} />

                  <Typography
                    variant="subtitle2"
                    color={modeCol(mode).textColor}
                    sx={{ fontWeight: "bold" }}
                  >
                    {post.commentCount}
                  </Typography>
                </HorizontalStack>
                {/* SHARE BUTTON */}
                <FaRegShareSquare color={modeCol(mode).textColor} />
                <Typography
                  variant="subtitle2"
                  color={modeCol(mode).textColor}
                  sx={{ fontWeight: "bold" }}
                >
                  {post.shareCount}
                </Typography>
              </HorizontalStack>
            </HorizontalStack>
          </PostContentBox>
        </HorizontalStack>
      </Box>
    </Card>
  );
};

export default PostCard;
