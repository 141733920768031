import React, { useEffect, useState } from "react";
import { TextField, Container, Box, Typography, Stack, Button } from '@mui/material';
import { useGlobalContext } from '../../context/context';
import EnterTopTitle from '../EnterTopTitle';
import EnterBottom from '../EnterBottom';
import { useNavigate } from 'react-router-dom';
import { GoHubot } from "react-icons/go";
import { getUser, updateUserDetails } from '../../api/users';
import { isLoggedIn } from '../../helpers/authHelper';
import { categories, subCategories } from '../../data/data';

import UserAvatar from "../UserAvatar";
import { GCLOUD_PUBLIC_BUCKET_URL } from "../../config";
import { uploadProfilePic } from "../../api/users";
import CircularCrop from "../CircularCrop";
import { modeCol } from "../../functions/colors";

const SocialView = () => {

    // Global
    const { image, formData, setImage, socialMedia, setSocialMedia, selectedInterests, selectedSubCategories, bioData, setHasRegistered } = useGlobalContext();

    // Local
    const navigate = useNavigate();
    const [isEditingImage, setEditingImage] = useState(false);
    const user = isLoggedIn();

    const handleSocialMediaChange = (event) => {
      const { name, value } = event.target;
      setSocialMedia({
        ...socialMedia,
        [name]: value
      });
    };

    // Uploading image
    const clickedUpload = () => {
        console.log(user);
        setEditingImage(true);
    }
    const imageEditingCancelled = () => {
        setEditingImage(false);
    };
    
    const imageEditingCompleted = async (imageData) => {
        const imgData = await imageData;
        console.log(`base64 string length:`);
        console.log(imgData.length);
        setEditingImage(false);
    
        let jsonData = { 
          image: imgData, 
          userId: user.userId
        };
    
        try {
          const {profilePicUrl} = await uploadProfilePic(user, jsonData);
          console.log(profilePicUrl);
        //   setUser({...user, profilePicUrl})
          // update user data in session 
          user.profilePicUrl = profilePicUrl;
          localStorage.setItem("user", JSON.stringify(user));

          console.log(user.profilePicUrl);
          setImage(user.profilePicUrl);

    
        } catch (err) {
          console.log(err);
        }
      }



    // Filtering logic

    const getSubCategoryTitlesByIds = (ids) => {
        const subCategoryTitles = [];
        for (const id of ids) {
          const foundCategory = subCategories.find(category => category.subCategory.some(sub => sub.id === id));
          if (foundCategory) {
            const foundSubCategory = foundCategory.subCategory.find(sub => sub.id === id);
            subCategoryTitles.push(foundSubCategory.title);
          }
        }
        return subCategoryTitles;
    };

    const getCategoryTitlesByIds = (ids) => {
        const categoryTitles = [];
        for (const id of ids) {
          const foundCategory = categories.find(category => category.id === id);
          if (foundCategory) {
            categoryTitles.push(foundCategory.title);
          }
        }
        return categoryTitles;
    };


    // NEXT

    const handleNext = async (e) => {
        e.preventDefault()
        try {
            const user = isLoggedIn();  
            // const existingUser = await getUser({ id: user.username })

            const formAllData = {
                interests: getCategoryTitlesByIds(selectedInterests),
                subcategories: getSubCategoryTitlesByIds(selectedSubCategories),
                userInfo: bioData.specialInfo,
                additionalInfo: bioData.friendsInfo,
                userBirthDay: `${formData.year}-${formData.month}-${formData.day}`,
                // TODO: Have to integrate it with real one (Paul made one)
                // profileImg: image,
                media: socialMedia,
            }

            const humanPersonality = await updateUserDetails(user.userId, formAllData);
            console.log(humanPersonality);
            navigate('/')
            setHasRegistered("done")
            
        } catch (error) {
            console.log(error)
        }
    };

    // PREV

    const handlePrev = () => {
        navigate('/bio');
    };

    return (
        <Container maxWidth="md" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', minHeight: '100vh' }}>

            {/* TOP */}

            <Box mt={5}>
                <EnterTopTitle 
                    title='TwinlyAI'
                    textOne="Final Steps!"
                    textTwo="Let us learn about your social life"
                />
            </Box>

            {/* MIDDLE */}

            <Box sx={{ display: 'grid', gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }, margin: '30px 0' }}>

                <Box sx={{ display: 'grid', gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)',  md: 'repeat(1, 1fr)' }, border: '1px solid #1976D2', borderRadius: 4, margin: '8px 8px' }} p={2}>

                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {/* <Box sx={{ width: '200px', height: '200px', borderRadius: '50%', overflow: 'hidden' }} >
                            {!image && (
                                <Box 
                                    sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f0f0f0' }} 
                                >
                                    <Box><GoHubot fontSize={100} color='#1976D2' /></Box>
                                </Box>
                            )}
                            {image && (
                                <img 
                                    src={image} 
                                    alt="Uploaded" 
                                    style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center', borderRadius: '50%' }} 
                                />
                            )}
                        </Box> */}

                        {user.profilePicUrl ? (<UserAvatar 
                                    width={150} 
                                    height={150} 
                                    username={user.username} 
                                    imgUrl={user.profilePicUrl} />)
                        : (
                            <Box sx={{ width: '200px', height: '200px', borderRadius: '50%', overflow: 'hidden' }} >
                                <Box 
                                    sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f0f0f0' }} 
                                >
                                    <Box><GoHubot fontSize={100} color='#1976D2' /></Box>
                                </Box>
                            </Box>
                        )}

                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '20px', border: '2px dashed #1976D2', borderRadius: '10px', backgroundColor: '#f0f0f0', position: 'relative', marginTop: {xs: '15px', md: '0'} }}>
                        {/* <Typography variant="h6" gutterBottom>
                            Upload Profile Image
                        </Typography> */}
                        
                        <Button 
                            type="file" 
                            id="upload-button" 
                            onClick={clickedUpload} 
                            style={{ 
                                position: 'absolute', 
                                top: 0, 
                                left: 0, 
                                opacity: 0, 
                                width: '100%', 
                                height: '100%', 
                                cursor: 'pointer' 
                            }}
                        />
                        <label 
                            htmlFor="upload-button" 
                            style={{ 
                                cursor: 'pointer', 
                                padding: '10px 20px', 
                                backgroundColor: '#1976D2', 
                                color: '#ffffff', 
                                borderRadius: '5px' 
                            }}
                        >
                            Upload Profile Image
                        </label>
                    </Box>

                </Box>

                <Box sx={{ border: '1px solid #1976D2', borderRadius: 4, margin: '8px 8px' }} p={3}>
                    <Typography variant="h6" sx={{ paddingLeft: '20px' }} gutterBottom>
                        Add your socials!
                    </Typography>
                    {Object.keys(socialMedia).map((platform) => (
                        <Box key={platform} p={2}>
                            <div style={{position: "relative", zIndex:0}}>
                                <TextField
                                label={platform.charAt(0).toUpperCase() + platform.slice(1)}
                                fullWidth
                                variant="outlined"
                                name={platform}
                                value={socialMedia[platform]}
                                onChange={handleSocialMediaChange}
                                />
                            </div>
                        </Box>
                    ))}
                </Box>

            </Box>



            {/* BOTTOM */}

            <EnterBottom leftText='prev' rightText='Submit' leftFunc={handlePrev} rightFunc={handleNext} />

            { isEditingImage && 
                <CircularCrop 
                    defaultImage={user.profilePicUrl ? GCLOUD_PUBLIC_BUCKET_URL + user.profilePicUrl : null}
                    cropSize={{width: 200, height: 200}}
                    onCropCancelled={imageEditingCancelled}
                    onCropComplete={imageEditingCompleted}
                />
            }

        </Container>
    );
};

export default SocialView;
