import { Card, Grid, Link, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Copyright from "./Copyright";
import { modeCol } from "../functions/colors";
import { useGlobalContext } from "../context/context";

const Footer = () => {
  const { mode } = useGlobalContext();
  return (
    <Box pb={3} sx={{ display: { xs: "none", md: "block" } }}>
      <Card sx={{ backgroundColor: modeCol(mode).cardColor }}>
        <Typography color={modeCol(mode).textColor} variant="subtitle1">
          TODO Popular posts
        </Typography>
      </Card>
    </Box>
  );
};

export default Footer;
