import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ModalPopUp from '../components/ModalPopUp';
import MainSidebar from '../components/MainSidebar';
import PrivateRoute from '../components/PrivateRoute';
import { useGlobalContext } from '../context/context';
import { CreateBasicTwinlyPage, CreateErrorPage, CreateFantasyTwinlyBasicPage, CreateFantasyTwinlyPage, CreatePage, CreatePostPage, CreateQuestionsPage, CreateSpacePage, CreateSuccessPage, CreateTwinlyPage, ExplorePage, HomePage, MessengerPage, OnboardingPage, PostPage, ProfilePage, SearchPage, SpacePage, SpacesPage } from '../components/views/MainView';

function MainRoutes() {

  const { user } = useGlobalContext();

  // Create Secure Route
  const secure = (children) => {
    return <PrivateRoute>{children}</PrivateRoute>;
  };

  return (
    <>
      <MainSidebar>
        <ModalPopUp />
        <Routes>
          {/* Non-Secure Routes */}
          <Route path="/" element={<ExplorePage />} />
          <Route path="/search" element={<SearchPage />} /> 
          <Route path="/games" element={<SpacesPage />} /> 
          <Route path="/posts/:id" element={<PostPage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/explore" element={<ExplorePage />} />
          <Route path="/users/:userId" element={<ProfilePage />} />
          {/* <Route path="/users" element={<Error404Page />} /> */}
          <Route path="/games/t/:id" element={<SpacePage />} />

          {/* Secure Routes */}
          <Route path="/create" element={secure(<CreatePage />)} />
          <Route path="/messenger" element={secure(<MessengerPage />)} />
          <Route path="/onboarding" element={secure(<OnboardingPage />)} />
          <Route path="/posts/create" element={secure(<CreatePostPage />)} />
          <Route path="/games/create" element={secure(<CreateSpacePage />)} />
          <Route path="/create/error" element={secure(<CreateErrorPage />)} />
          <Route path="/create/success" element={secure(<CreateSuccessPage />)} />
          <Route path="/create/questions" element={secure(<CreateQuestionsPage />)} />
          <Route path="/posts/create/:spaceid" element={secure(<CreatePostPage />)} />
          <Route path="/create/yourtwinly" element={secure(<CreateTwinlyPage />)} />
          <Route path="/create/fantasytwinly" element={secure(<CreateFantasyTwinlyPage />)} />
          <Route path="/create/yourtwinly/superbasic" element={secure(<CreateBasicTwinlyPage />)} />
          <Route path="/create/fantasytwinly/superbasic" element={secure(<CreateFantasyTwinlyBasicPage />)} />

        </Routes>
      </MainSidebar>
    </>
  );
}

export default MainRoutes;
