import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";

const CreatePost = (props) => {
  const navigate = useNavigate();
  if(props.spaceId) {
    return (
      <Button
        variant="outlined"
        size="medium"
        onClick={() => navigate("/posts/create/" + props.spaceId)}
        sx={{
          gap: "0.2rem",
          whiteSpace: "nowrap",
        }}
      >
        <AiOutlinePlus style={{ flexShrink: 0 }} />
        <span>New Post</span>
      </Button>
    );
  }
  else{
    return (
      <Button
        variant="outlined"
        size="medium"
        onClick={() => navigate("/posts/create")}
        sx={{
          gap: "0.2rem",
          whiteSpace: "nowrap",
        }}
      >
        <AiOutlinePlus style={{ flexShrink: 0 }} />
        <span>New Post</span>
      </Button>
    );
  };
  
};

export default CreatePost;
